//components
import Text from "../../../common/Text";
import StyledInstructionText from "../common/StyledInstructionText";

//style
import styled, { useTheme } from "../../../../styling/styled-components";

//constants
import { MANUAL_FINE_COPY } from "../../../../constants/actionItems";

const HeaderWrapper = styled.div`
    padding-right: ${({ theme }) => theme.spacing.small}px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
`;

const DescriptionWrapper = styled.div`
    padding-top: ${({ theme }) => theme.spacing.xsmall}px;
`;

type Props = {
    eligibleRentalsCount: Number;
    mostRecentTimestamp: String;
};

export default function NumberOfEligibleRentals({
    eligibleRentalsCount,
    mostRecentTimestamp,
}: Props) {
    const { colors } = useTheme();

    return (
        <>
            <HeaderWrapper>
                <Text size="large" type="label" color={colors.DEEP_BLUE_SEA}>
                    {MANUAL_FINE_COPY.NUMBER_FINED_RENTALS}
                </Text>
                <Text size="medium" type="header" color={colors.DEEP_BLUE_SEA}>
                    {eligibleRentalsCount || 0}
                </Text>
            </HeaderWrapper>
            <DescriptionWrapper>
                <StyledInstructionText>
                    {MANUAL_FINE_COPY.ELIGIBLE_SUBHEADER(mostRecentTimestamp)}
                </StyledInstructionText>
            </DescriptionWrapper>
        </>
    );
}
