import { ReactNode } from "react";
import styled from "../../../../styling/styled-components";

const ContentWrapper = styled.div`
    padding-left: ${({ theme }) => theme.spacing.xmlarge}px;
    padding-right: ${({ theme }) => theme.spacing.medium}px;
`;

export default function OverlayContentWrapper({ children }: ReactNode) {
    return <ContentWrapper>{children}</ContentWrapper>;
}
