//components
import Text from "../../../common/Text";
import StyledInstructionText from "../common/StyledInstructionText";

//style
import styled, { useTheme } from "../../../../styling/styled-components";

//constants
import { MANUAL_FINE_COPY } from "../../../../constants/actionItems";

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    padding-bottom: ${({ theme }) => theme.spacing.medium}px;
`;

export default function ManualFineDescriptions({}) {
    const { colors } = useTheme();

    return (
        <HeaderWrapper>
            <Text size="large" type="header" color={colors.DEEP_BLUE_SEA}>
                {MANUAL_FINE_COPY.GENERATE_REPORT_HEADER}
            </Text>
            <StyledInstructionText>
                {MANUAL_FINE_COPY.REVIEW_HEADER}
            </StyledInstructionText>
        </HeaderWrapper>
    );
}
