//react
import { useRef, useState } from "react";

// style
import styled from "../../../styling/styled-components";

//components
import Button from "../../common/Button";
import AssetFilterButton from "./AssetFilterButton";
import FlyoutForm from "../../common/dropdown/FlyoutForm";
import OptionsContainer from "../../common/dropdown/OptionsContainer";
import RadioButtons from "../../common/dropdown/RadioButtons";
import Loadable from "../../common/Loadable";
import FormChildrenWrapper from "../../common/dropdown/FormChildrenWrapper";

// utils
import useClickOutside from "../../../utils/useClickOutside";

//constants
import { ASSET_FILTERS_SUMMARY } from "../../../constants/dashboard";

//images
import { ReactComponent as BlueChevronUp } from "../../../assets/vectors/BlueChevronUp.svg";
import { ReactComponent as WhiteChevronDown } from "../../../assets/vectors/WhiteChevronDown.svg";

const ButtonsWrapper = styled.div`
    display: flex;
    padding-right: ${({ theme }) => theme.spacing.xxlarge}px;
    margin-right: ${({ theme }) => theme.spacing.xmlarge}px;
`;

const FilterWrapper = styled.div``;

const FlyoutWrapper = styled.div``;

const RadioForm = styled.div`
    width: 198px;
`;

const IconWrapper = styled.div`
    padding-top: ${({ theme }) => theme.spacing.tiny}px;
`;

export default function AssetDropdown({ onSubmit, assetTypes, isOpen }: Props) {
    const dropdownArray = assetTypes.map(({ assetTypeId, name }) => ({
        value: assetTypeId == null ? "" : assetTypeId,
        label: name,
    }));

    const [selectedValue, setSelectedValue] = useState(dropdownArray[0].value);
    const [disableButton, setDisableButton] = useState(true);
    const [showOptions, setShowOptions] = useState(false);
    const [selectedAsset, setSelectedAsset] = useState(dropdownArray[0]);
    const [buttonLabel, setButtonLabel] = useState(dropdownArray[0].label);

    const buttonStatus = !disableButton ? "default" : "disabled";

    //refs click outside
    const dropdownRef = useRef("asset-filter-dropdown-button");
    const flyoutRef = useRef("asset-filter-flyout");

    useClickOutside(
        flyoutRef,
        () => {
            setShowOptions(false);
        },
        [dropdownRef]
    );

    const changeSelectionAndEnableButton = (event, item) => {
        event.preventDefault();
        const radioResult = event.target.value;
        setDisableButton(false);
        setSelectedValue(radioResult);
        setSelectedAsset(item);
    };

    const submitAssetSelected = (event) => {
        event.preventDefault();
        onSubmit({
            assetTypeId: selectedAsset.value,
            name: selectedAsset.label,
        });
        closeDropdown();
        setButtonLabel(selectedAsset.label);
    };

    const closeDropdown = () => {
        setShowOptions(false);
        setDisableButton(true);
    };

    const IconLabel = () => {
        if (showOptions) {
            return (
                <IconWrapper>
                    <BlueChevronUp />
                </IconWrapper>
            );
        } else {
            return (
                <IconWrapper>
                    <WhiteChevronDown />
                </IconWrapper>
            );
        }
    };

    const DropdownOptions = () => {
        return (
            <>
                {showOptions && (
                    <FlyoutWrapper ref={flyoutRef}>
                        <FlyoutForm
                            onSubmit={submitAssetSelected}
                            alignRight={false}
                        >
                            <FormChildrenWrapper>
                                <RadioForm>
                                    <OptionsContainer>
                                        <RadioButtons
                                            options={dropdownArray}
                                            onChange={
                                                changeSelectionAndEnableButton
                                            }
                                            selectedOption={selectedValue}
                                        />
                                        <ButtonsWrapper>
                                            <Button
                                                status={buttonStatus}
                                                size="small"
                                                type="primary"
                                                onClick={submitAssetSelected}
                                                label={
                                                    ASSET_FILTERS_SUMMARY.APPLY_SELECTION
                                                }
                                            />
                                        </ButtonsWrapper>
                                    </OptionsContainer>
                                </RadioForm>
                            </FormChildrenWrapper>
                        </FlyoutForm>
                    </FlyoutWrapper>
                )}
            </>
        );
    };

    return (
        <Loadable dataLoaded={isOpen}>
            <FilterWrapper>
                <div ref={dropdownRef}>
                    <AssetFilterButton
                        onClick={() => setShowOptions(!showOptions)}
                        isSelected={!showOptions}
                    >
                        {buttonLabel}
                        <IconLabel />
                    </AssetFilterButton>
                </div>
                <DropdownOptions />
            </FilterWrapper>
        </Loadable>
    );
}
