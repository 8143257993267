//components
import Text from "../../../common/Text";
import Checkbox from "@mui/material/Checkbox";

//style
import styled, { useTheme } from "../../../../styling/styled-components";

//constants
import { MANUAL_FINE_COPY } from "../../../../constants/actionItems";

const CheckBoxWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

const DescriptionWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
`;

const TermsWrapper = styled.div`
    padding-left: ${({ theme }) => theme.spacing.xmlarge}px;
    margin-left: ${({ theme }) => theme.spacing.tiny}px;
    padding-bottom: ${({ theme }) => theme.spacing.xxlarge}px;
`;

type Props = {
    onToggleCheckbox: () => void;
};

export default function ConfirmationBlock({ onToggleCheckbox }: Props) {
    const { colors } = useTheme();

    return (
        <DescriptionWrapper>
            <CheckBoxWrapper>
                <Checkbox onClick={onToggleCheckbox} />
                <Text size="medium" type="label" color={colors.DEEP_BLUE_SEA}>
                    {MANUAL_FINE_COPY.CONFIRMATION}
                </Text>
            </CheckBoxWrapper>
            <TermsWrapper>
                <Text size="medium" type="label" color={colors.DEEP_BLUE_SEA}>
                    {MANUAL_FINE_COPY.TERMS}
                </Text>
            </TermsWrapper>
        </DescriptionWrapper>
    );
}
