//components
import LinkText from "../../../common/LinkText";

type Props = {
    onClick: () => null;
    textCopy: string;
    href: string;
};

export default function SmallLinkText({ onClick, textCopy, href = "" }: Props) {
    return (
        <LinkText
            type="new_link"
            size="small"
            href={href}
            onClick={onClick}
            ariaLabel={textCopy}
        >
            {textCopy}
        </LinkText>
    );
}
