//components
import Text from "../../../common/Text";

//style
import { useTheme } from "../../../../styling/styled-components";

//react
import { ReactNode } from "react";

type Props = {
    children: ReactNode;
};

export default function StyledInstructionText({ children }: Props) {
    const { colors } = useTheme();

    return (
        <Text size="medium" type="body" color={colors.DEEP_BLUE_SEA}>
            <>{children}</>
        </Text>
    );
}
