//react
import { useEffect } from "react";

//api
import {
    ObservationTag,
    ObservationAggregation,
    Observation,
} from "../../../API";
import { useQuery } from "../../../graphql/hooks/query";

//styling
import styled, { useTheme } from "../../../styling/styled-components";
import { customStyles } from "../../common/TableStyles";

//components
import ClipLoader from "react-spinners/ClipLoader";
import DataTable from "react-data-table-component";
import Text from "../../common/Text";
import LinkText from "../../common/LinkText";
import ObservationTagPill from "./ObservationTagPill";
import NoTableData from "../../common/table/NoTableData";

//utils
import { parseISO, format } from "date-fns";
import { prettifyUID } from "../../../utils/helpers";
import { localizeWeightObservation } from "../helpers";
import { checkCostCurrencyAndDisplayDollars } from "../../../utils/streamline/checkCostCurrencyAndDisplayDollars";

//constants
import { OBSERVATION_DETAILS } from "../constants";

const STRINGS = {
    OID: (id: string) => `Observation ID: ${prettifyUID(id)}`,
    UNLABELED: "Label pending",
};

const COLUMN_NAMES = {
    DATE: "Date",
    ITEM: "Item wasted",
    LOCATION: "Location",
    WEIGHT: "Weight",
    VALUE: "Value",
    DONATIONS: "Donations",
};

export const OBSERVATIONS_LOG_QUERY = `query Observations(
  $fromTimestamp: String!
  $toTimestamp: String!
  $parentLocationIds: [ID]
  $types: [ObservationType]
  $includeTags: [ObservationTag]
  $excludeTags: [ObservationTag]
  $searchQuery: String
  $aggregationOption: ObservationAggregationOption
  $paginateFrom: Int
  $size: Int
) {
  observations(
    fromTimestamp: $fromTimestamp
    toTimestamp: $toTimestamp
    parentLocationIds: $parentLocationIds
    types: $types
    includeTags: $includeTags
    excludeTags: $excludeTags
    searchQuery: $searchQuery
    aggregationOption: $aggregationOption
    paginateFrom: $paginateFrom
    size: $size
  ) {
      observations {
      id
      observationType
      capturedBy {
        iotThingName
        location {
          fullName
        }
      }
      timestamp
      grossWeight {
        weight
        units
      }
      netWeight {
        weight
        units
      }
      station {
        name
        externalId
      }
      bestLabel {
        id
        name
        externalId
        costCurrency
        cost
        costUOMQty
        costUOM
      }
      container {
        id
        name
      }
      value {
        value
        costCurrency
        byCount
        countingUnit
        count
      }
      tags
    }
    totalHits
 }
}
`;

const HeaderCellText = styled(Text)``;

const CellSubText = styled(Text)``;

const CellText = styled(Text)``;

type Props = {
    fromTimestamp: Date;
    toTimestamp: Date;
    onSelectObservationId?: (id: string) => void;
    onResultCountChange?: (count: number) => void;
    onPageChange?: (page: number) => void;
    searchQuery?: string;
    parentLocationIds?: string[];
    includeTags?: ObservationTag[];
    excludeTags?: ObservationTag[];
    aggregationOption?: ObservationAggregation;
    paginateFrom?: number;
    size?: number;
    className?: string;
};

export default function ObservationsLog({
    fromTimestamp,
    toTimestamp,
    onSelectObservationId = (_) => null,
    onResultCountChange = (_) => null,
    onPageChange = (_) => null,
    searchQuery,
    parentLocationIds,
    includeTags,
    excludeTags,
    aggregationOption,
    paginateFrom = 0,
    size = 50,
    className,
}: Props) {
    const { colors } = useTheme();

    const [searchObservations, data, isLoading, errors] = useQuery(
        OBSERVATIONS_LOG_QUERY
    );

    useEffect(() => {
        if (fromTimestamp !== null && toTimestamp !== null) {
            let defaultExcludeTags = [
                ObservationTag.Ignore,
                ObservationTag.ImageHelp,
            ];

            searchObservations({
                fromTimestamp,
                toTimestamp,
                searchQuery,
                parentLocationIds,
                includeTags,
                excludeTags: [...excludeTags, ...defaultExcludeTags],
                aggregationOption,
                paginateFrom,
                size,
            });
        }
    }, [
        searchObservations,
        fromTimestamp,
        toTimestamp,
        searchQuery,
        parentLocationIds,
        includeTags,
        excludeTags,
        aggregationOption,
        paginateFrom,
        size,
    ]);

    useEffect(() => {
        if (data && data?.totalHits !== null) {
            onResultCountChange(data.totalHits);
        }
    }, [data, onResultCountChange]);

    const columns = [
        {
            name: COLUMN_NAMES.DATE,
            width: "10%",
            selector: (row: Observation) => (
                <>
                    <HeaderCellText
                        type="label"
                        size={"small"}
                        color={colors.EXHAUST}
                    >
                        {format(parseISO(row.timestamp), "M/dd/yy")}
                    </HeaderCellText>
                    <CellText type="body" size={"tiny"} color={colors.EXHAUST}>
                        {format(parseISO(row.timestamp), "h:mm aaaaa'm'")}
                    </CellText>
                </>
            ),
        },
        {
            name: COLUMN_NAMES.ITEM,
            width: "25%",
            selector: (row: Observation) => (
                <>
                    <LinkText
                        type="new_link"
                        size="small"
                        data-tag="allowRowEvents"
                        onClick={() => onSelectObservationId(row.id)}
                    >
                        {row?.bestLabel?.name || STRINGS.UNLABELED}
                    </LinkText>
                    <CellSubText
                        type="body"
                        size={"tiny"}
                        color={colors.EXHAUST}
                    >
                        {STRINGS.OID(row.id)}
                    </CellSubText>
                </>
            ),
        },
        {
            name: COLUMN_NAMES.LOCATION,
            width: "25%",
            selector: (row: Observation) => (
                <>
                    <HeaderCellText
                        type="label"
                        size={"small"}
                        color={colors.EXHAUST}
                    >
                        {row.capturedBy?.location?.fullName}
                    </HeaderCellText>
                    <CellText type="body" size={"tiny"} color={colors.EXHAUST}>
                        {OBSERVATION_DETAILS.STATION_LABEL(row?.station?.name)}
                    </CellText>
                </>
            ),
        },
        {
            name: COLUMN_NAMES.WEIGHT,
            width: "10%",
            selector: (row: Observation) => (
                <>
                    <CellText
                        type="label"
                        size={"small"}
                        color={colors.EXHAUST}
                    >
                        {localizeWeightObservation(
                            row?.netWeight?.weight < 0
                                ? row?.grossWeight
                                : row?.netWeight
                        )}
                    </CellText>
                </>
            ),
        },
        {
            name: COLUMN_NAMES.VALUE,
            width: "15%",
            selector: (row: Observation) => (
                <>
                    <CellText
                        type="label"
                        size={"small"}
                        color={colors.EXHAUST}
                    >
                        {row?.value?.value > 0
                            ? checkCostCurrencyAndDisplayDollars(
                                  row?.value?.value,
                                  row?.value?.costCurrency
                              )
                            : "-"}
                    </CellText>
                </>
            ),
        },
        {
            name: COLUMN_NAMES.DONATIONS,
            width: "15%",
            selector: (row: Observation) => (
                <>
                    {(row?.tags || []).some(
                        (tag) => tag === ObservationTag.Donation
                    ) ? (
                        <ObservationTagPill label={ObservationTag.Donation} />
                    ) : (
                        <CellText
                            type="label"
                            size={"small"}
                            color={colors.EXHAUST}
                        >
                            {"-"}
                        </CellText>
                    )}
                </>
            ),
        },
    ];

    return (
        <DataTable
            noDataComponent={<NoTableData />}
            columns={columns}
            keyField="id"
            data={data?.observations || []}
            pagination
            paginationPerPage={size}
            paginationRowsPerPageOptions={[size]}
            paginationServer
            paginationTotalRows={data?.totalHits}
            onChangePage={onPageChange}
            progressPending={isLoading}
            progressComponent={<ClipLoader loading />}
            customStyles={customStyles}
            highlightOnHover={true}
            data-tag="allowRowEvents"
            onRowClicked={() => null}
            className={className}
        />
    );
}
