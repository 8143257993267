//utils
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";

//constants
import { RentalPhase } from "../API";
import { RentalStatus } from "../API";

export const ACTION_ITEMS_LIST = [
    {
        label: "Generate fine report",
        value: "Generate fine report",
        selected: false,
        status: "default",
    },
    {
        label: "View & download reports",
        value: "View & download reports",
        selected: false,
        status: "default",
    },
];

export const MANUAL_FINE_COPY = {
    GENERATE_REPORT_HEADER: "Generate a fine report",
    REVIEW_HEADER:
        "Review and confirm that you plan to manually execute fines for the following rentals.",
    ELIGIBLE_RENTALS:
        "If your program issues fines, rentals that display the “Failed” status and “Expired” or “Fine error” phase are eligible to be manually fined.",
    FINED_RENTALS:
        "Once the rentals have been confirmed and marked as fined, they will display the “Fined (manual)” phase in the dashboard. These rentals will remain in the Failed section of the ReusePass app, and consumers are instructed to check their campus account for a charge.",
    RENTALS_AVAILABILITY:
        "This list of fined rentals will be available to download as a CSV file once the updates are complete. ",
    QUESTIONS: "Have questions? Learn more at ",
    NUMBER_FINED_RENTALS: "Number of rentals eligible to be fined",
    ELIGIBLE_SUBHEADER: (timestamp: string) =>
        timestamp &&
        `This includes all rentals that became eligible for a fine since the last time rentals were manually marked as fined ${format(
            parseISO(timestamp),
            "M/dd/yy"
        )}.`,
    CONFIRMATION:
        "I confirm that I want to permanently update the phase of these rentals from “Expired” and/or “Fine error” to “Fined (manual).”",
    TERMS: "I understand that my program will execute these fines via a separate platform, and that marking items as fined in the dashboard does not automatically issue fines. ",
    CONFIRM_BUTTON_TEXT: "Confirm and mark as fined",
    TABLE_DESCRIPTION:
        "Example of rentals eligible to be fined in the Rentals table",
    NOT_ELIGIBLE_RENTALS_HEADER: "There are no rentals eligible to be fined",
    NOT_ELIGIBLE_REASONS: "Here are a few reasons why this may be:",
    NOT_ELIGIBLE_REASON_1:
        " 1. Someone recently performed this action and marked all eligible rentals as fined (go to the ",
    NOT_ELIGIBLE_REASON_2:
        " page, accessible through the “Actions” menu, to see when this was last performed) ",
    NOT_ELIGIBLE_REASON_3:
        " 2. Fines have already been issued automatically through another third party platform",
    NOT_ELIGIBLE_REASON_4:
        " 3. Your program has Instant Forgiveness turned on (you can adjust the",
    NOT_ELIGIBLE_REASON_5: " in “Program settings”)",
    NOT_ELIGIBLE_REASON_6: " 4. Your program does not issue fines ",
    ERROR: "An unexpected error occurred",
    TRY_AGAIN: " or try again later.",
    REFRESH: "Refresh this page",
    SUBMISSION_CONFIRMED: "Your submission is confirmed",
    PENDING_UPDATES:
        "The rentals will be updated. This could take several minutes.",
    DOWNLOAD_DETAILS:
        "You’ll be able to download a report of these changes as a CSV file below once the updates are complete.  If you choose to close this page, you can find the report under the “Actions” menu.",
    RENTAL_NUMBER_DISCLAIMER:
        "The total number of fined rentals may have changed from what you saw previously if there was a gap in time between when you started this process and when you confirmed.",
    LOADING: "Loading, one moment...",
};

export const VIEW_REPORTS_COPY = {
    HISTORY_REPORTS:
        "Below is a history of manually fined rentals. Reports may be downloaded up to 90 days from when they are created.",
    NO_REPORTS: "There are no reports yet",
    DOWNLOAD_REPORT: "Download report",
    CREATED_AT: (timestamp) =>
        `On ${format(parseISO(timestamp), "M/dd/yy")} at ${format(
            parseISO(timestamp),
            "h:mm a"
        )}`,
    DATE_RANGE: (fromTimestamp, toTimestamp) =>
        `Date range of included rentals: ${format(
            parseISO(fromTimestamp),
            "MMM. dd"
        )} - ${format(parseISO(toTimestamp), "MMM. dd yyy")}`,
    LOADING: "Downloading...",
};

export const eligiblePhaseFilters = [
    { value: RentalPhase.FineError, selected: true },
    { value: RentalPhase.Expired, selected: true },
];

export const eligibleStatusFilters = [
    { value: RentalStatus.Failed, selected: true },
];
