//style
import styled from "../../../styling/styled-components";

//components
import Close from "../Close";
import Displayable from "../Displayable";

//utils
import useClickOutside from "../../../utils/useClickOutside";

//react
import { useRef } from "react";

const Container = styled.div`
    ${(props) => `width: ${props.width}`}px;
    ${(props) => `overflow-y: ${props.scroll}`};
    height: 100%;
    transition: width 1s;
    top: 0px;
    right: 0px;
    background-color: ${({ theme }) => theme.colors.BLANK_SLATE};
    z-index: 1;
    position: fixed;
    box-shadow: ${({ theme }) => theme.shadowStyle.overlayDropShadow};
    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    &::-webkit-scrollbar:vertical {
        width: 11px;
    }

    &::-webkit-scrollbar:horizontal {
        height: 0px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 2px solid white; /* should match background, can't be transparent */
        background-color: rgba(0, 0, 0, 0.5);
    }
`;

const ChildrenWrapper = styled.div`
    width: 500px;
    margin-bottom: ${({ theme }) => theme.spacing.large}px;
`;

export default function Overlay({
    children,
    onClickCloseOverlay,
    showLeft,
    onClickBack,
    onClickedOutside,
    scroll,
    overlayOpen,
    ...props
}: Props) {
    const overlayRef = useRef(null);
    const width = overlayOpen ? 518 : 0;

    useClickOutside(overlayRef, () => {
        onClickedOutside();
    });

    return (
        <div data-override-outside-click={true}>
            <Displayable displayPrimaryComponent={overlayOpen}>
                <Container width={width} scroll={scroll} ref={overlayRef}>
                    <Close
                        label={"Close"}
                        onClick={onClickCloseOverlay}
                        showLeftLabel={showLeft}
                        leftlabel={"Back"}
                        onClickLeft={onClickBack}
                    />
                    <ChildrenWrapper>{children}</ChildrenWrapper>
                </Container>
            </Displayable>
        </div>
    );
}
