//apis
import { getEligibleRentalCount } from "../../graphql/customQueries/getEligibleRentalCount";

//enum
import { RentalPhase, RentalStatus } from "../../API";

type Props = {
    graphClient: Object;
    fromTimestamp: string;
};

export const getCountOfEligibleManualFiningRentals = async (
    fromTimestamp,
    graphClient
): Props => {
    let totalHits = 0;
    if (fromTimestamp) {
        try {
            const results = await graphClient.graphql({
                query: getEligibleRentalCount,
                variables: {
                    fromTimestamp: fromTimestamp,
                    toTimestamp: new Date().toISOString(),
                    filter: "expiredAt",
                    withCharges: true,
                    rentalPhases: [
                        RentalPhase.Expired,
                        RentalPhase.FinedManual,
                        RentalPhase.FineError,
                    ],
                    rentalStatuses: [RentalStatus.Failed],
                },
            });
            totalHits = results?.data?.searchRentals?.totalHits;
        } catch (error) {
            console.error("Error listing rentals", error);
        }
    }
    return totalHits;
};
