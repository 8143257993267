import React, { useState } from "react";
import styled, { useTheme } from "styled-components/macro";
import { getButtonColor } from "../../styling/utils";
import ClipLoader from "react-spinners/ClipLoader";
import DeprecatedText from "./DeprecatedText";
import { findTextStyles } from "../../styling/styled-components";

interface Props {
    buttonStyle?: Record<string, unknown>;
    isLoading?: boolean;
    label: string | JSX.Element;
    labelStyle?: Record<string, unknown>;
    onClick?: () => void;
    size?: string;
    status?: string;
    style?: Record<string, unknown>;
    type?: string;
    className?: string;
    children?: string | JSX.Element | JSX.Element[];
}

const PrimaryButton = styled.button<Props>`
    display: flex;
    justify-content: center;
    min-width: ${(props) => (props.size === "large" ? "160px" : "72px")};
    box-shadow: 0px 4px 10px rgba(22, 35, 80, 0.27);
    border-radius: 4px;
    background-color: ${(props) => getButtonColor(props)};
    border: 1px solid ${(props) => getButtonColor(props)};
    padding: ${(props) =>
        props.isLoading ? "" : `${props.theme.spacing.small}px`};
    &:hover {
        background-color: ${(props) =>
            props.disabled ? getButtonColor(props) : props.theme.colors.hover};
        border: 1px solid
            ${(props) =>
                props.disabled
                    ? getButtonColor(props)
                    : props.theme.colors.hover};
    }
    &:disabled {
        border: none;
    }
    ${(props) => (props.disabled ? "" : "cursor: pointer;")}
    ${(props) => props.buttonStyle}
`;

const SecondaryDarkButton = styled(PrimaryButton)<Props>`
    background-color: transparent;
    box-shadow: none;
    color: ${(props) => getButtonColor(props)};
    border: 1px solid ${(props) => getButtonColor(props)};
    ${(props) =>
        props.status === "disabled"
            ? `border: 1px solid ${props.theme.colors.MARINE_LAYER} !important`
            : ""};
    &:hover {
        color: ${(props) => props.theme.colors.hover};
        background-color: transparent;
        border: 1px solid
            ${(props) =>
                props.status === "disabled"
                    ? getButtonColor(props)
                    : props.theme.colors.hover};
    }
    ${(props) => props.buttonStyle}
`;

const SecondaryLightButton = styled(PrimaryButton)`
    background-color: transparent;
    box-shadow: none;
    color: ${(props) => props.theme.colors.BLANK_SLATE};
    border: 1px solid ${(props) => props.theme.colors.BLANK_SLATE};
    &:hover {
        color: ${(props) => props.theme.colors.hover};
        background-color: transparent;
        border: 1px solid ${(props) => props.theme.colors.hover};
    }

    ${(props) => props.buttonStyle}
`;

const SmallPrimaryButton = styled(PrimaryButton)`
    min-width: 72px;
`;

const SmallSecondaryDarkButton = styled(SecondaryDarkButton)`
    min-width: 72px;
`;

const SmallSecondaryLightButton = styled(SecondaryLightButton)`
    min-width: 72px;
`;

const LabelText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    text-align: center;
`;

const PrimaryLabelText = styled(LabelText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.BLANK_SLATE};
    ${(props) => props.labelStyle}
`;

const SecondaryLabelText = styled(LabelText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${(props) => getButtonColor(props)};
    ${(props) => props.labelStyle}
`;

export default function Button({
    buttonStyle,
    isLoading,
    label,
    labelStyle,
    onClick,
    size = "large",
    status = "default",
    style,
    type = "primary",
    className,
    children,
}: Props) {
    let buttonType = type ? type : "primary";
    buttonType = isLoading ? "secondary" : buttonType;
    const ButtonComponent =
        type === "secondary" ? SecondaryDarkButton : PrimaryButton;
    const LabelTextComponent =
        type === "secondary" ? SecondaryLabelText : PrimaryLabelText;
    if (isLoading) {
        return (
            <ButtonComponent
                className={className}
                isLoading={isLoading}
                onClick={onClick}
                status="disabled"
                style={style}
                size={size}
            >
                <ClipLoader loading={true} />
            </ButtonComponent>
        );
    } else if (children) {
        return (
            <ButtonComponent
                className={className}
                isLoading={isLoading}
                onClick={onClick}
                disabled={status == "disabled"}
                status={status}
                style={style}
                size={size}
            >
                {children}
            </ButtonComponent>
        );
    } else {
        return (
            <ButtonComponent
                className={className}
                isLoading={isLoading}
                onClick={onClick}
                disabled={status == "disabled"}
                status={status}
                style={style}
                size={size}
            >
                <LabelTextComponent
                    type="body"
                    size={size === "large" ? "large" : "small"}
                    status={status}
                >
                    {label}
                </LabelTextComponent>
            </ButtonComponent>
        );
    }
}
