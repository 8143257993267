//styles
import styled, { useTheme } from "../../../styling/styled-components";

//components
import GreatestNetIncrease from "./GreatestNetIncrease";
import Text from "../../common/Text";
import TextWrapper from "./TextWrapper";
import Loadable from "../../common/Loadable";
import StatsLoadingCard from "../assetusage/StatsLoadingCard";

//react
import { useEffect, useState } from "react";

//images
import { ReactComponent as StatusLine } from "../../../assets/vectors/StatusLine.svg";

//constants
import { ACTIVE_CONSUMERS } from "../../../constants/dashboard";

//utils
import { parseISO, format } from "date-fns";

type Props = {
    data: ConsumerStats;
    dateRange: string;
};

type ConsumerStats = {
    count: number;
    timestamp: string;
    __typename: string;
};

const StatsWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.medium}px;
    align-self: stretch;
`;

const TotalConsumersWrapper = styled.div`
    padding-left: ${({ theme }) => theme.spacing.xmlarge}px;
`;

const TotalConsumers = ({ finalConsumerCount }) => {
    const { colors } = useTheme();

    return (
        <TextWrapper>
            <Text size="smallest" type="header" color={colors.BLACK}>
                {ACTIVE_CONSUMERS.TODAYS_CONSUMERS}
            </Text>
            <Loadable
                dataLoaded={finalConsumerCount}
                LoadingComponent={StatsLoadingCard}
            >
                <Text size="xlarge" type="header" color={colors.BLACK}>
                    {finalConsumerCount.toLocaleString()}
                </Text>
            </Loadable>
        </TextWrapper>
    );
};

const NetChange = ({ dateRange, netChangeColor, netChangeText }) => {
    const { colors } = useTheme();
    return (
        <TextWrapper>
            <Text size="smallest" type="header" color={colors.BLACK}>
                {ACTIVE_CONSUMERS.NET_CHANGE(dateRange)}
            </Text>
            <Loadable
                dataLoaded={netChangeText}
                LoadingComponent={StatsLoadingCard}
            >
                <Text size="xlarge" type="header" color={netChangeColor}>
                    {netChangeText}
                </Text>
            </Loadable>
        </TextWrapper>
    );
};

export default function ConsumerStats({ data, dateRange }: Props) {
    const { colors } = useTheme();

    const [initialConsumerCount, setInitialConsumerCount] = useState(0);
    const [finalConsumerCount, setFinalConsumerCount] = useState(0);

    let netChange = data
        ? data.length > 0
            ? finalConsumerCount - initialConsumerCount
            : "-"
        : "-";
    let netChangePositiveOrNegativeColor =
        netChange < 0 ? colors.RED_2 : colors.GREEN_2;
    let netChangeColor =
        netChange === 0 || netChange == "-"
            ? colors.BLACK
            : netChangePositiveOrNegativeColor;
    let netChangePositiveOrNegativeText =
        netChange > 0 ? `+${netChange}` : `${netChange}`;
    let netChangeText =
        netChange === 0 || netChange == "-"
            ? `${netChange}`
            : netChangePositiveOrNegativeText;
    let finalIndex = data?.length - 1;

    let totalActiveConsumers = data?.length > 0 ? finalConsumerCount : "-";

    useEffect(() => {
        setInitialConsumerCount(0);
        setFinalConsumerCount(0);
        calculateStats();
    }, [data, dateRange]);

    const calculateStats = () => {
        if (data) {
            for (let index = 0; index < data.length; index++) {
                const item = data[index];
                if (index === 0) {
                    setInitialConsumerCount(item.count);
                }
                if (index === finalIndex) {
                    setFinalConsumerCount(item.count);
                    break;
                }
            }
        }
    };

    const netIncreases = data?.slice(1).map((currentObj, index) => {
        const previousObj = data[index];
        return {
            date: currentObj.timestamp,
            netIncrease: currentObj.count - previousObj.count,
        };
    });

    const maxNetIncrease = netIncreases?.reduce(
        (maxObj, obj) => {
            return obj.netIncrease > maxObj.netIncrease ? obj : maxObj;
        },
        { netIncrease: 0 }
    );

    const maxNetDate =
        !maxNetIncrease || !maxNetIncrease.date
            ? "-"
            : format(parseISO(maxNetIncrease.date), "MMM d");

    const maxNetCount =
        !maxNetIncrease || maxNetIncrease?.netIncrease == 0
            ? ""
            : `+${maxNetIncrease.netIncrease}`;

    return (
        <StatsWrapper>
            <TotalConsumersWrapper>
                <TotalConsumers finalConsumerCount={totalActiveConsumers} />
            </TotalConsumersWrapper>
            <StatusLine />
            <NetChange
                dateRange={dateRange}
                netChangeColor={netChangeColor}
                netChangeText={netChangeText}
            />
            <StatusLine />
            <GreatestNetIncrease date={maxNetDate} count={maxNetCount} />
        </StatsWrapper>
    );
}
