//components
import SideLine from "../SideLine";

//style
import styled from "../../../styling/styled-components";

const Wrapper = styled.div`
    padding-top: ${({ theme }) => theme.spacing.xsmall}px;
    padding-bottom: ${({ theme }) => theme.spacing.xsmall}px;
    position: relative;
`;

export default function DividerWrapper({}) {
    return (
        <>
            <Wrapper>
                <SideLine />
            </Wrapper>
        </>
    );
}
