/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const containers = /* GraphQL */ `query Containers {
  containers {
    id
    name
    weight {
      weight
      units
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ContainersQueryVariables,
  APITypes.ContainersQuery
>;
export const hardwareUnit = /* GraphQL */ `query HardwareUnit($iotThingName: String!) {
  hardwareUnit(iotThingName: $iotThingName) {
    iotThingName
    location {
      locationId
      name
      parentId
      fullName
      externalId
      address {
        line1
        line2
        line3
        postalCode
        country
        latitude
        longitude
        googlePlacesId
        __typename
      }
      metadata {
        key
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    stations {
      name
      externalId
      __typename
    }
    installedOn
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.HardwareUnitQueryVariables,
  APITypes.HardwareUnitQuery
>;
export const hardwareUnits = /* GraphQL */ `query HardwareUnits($paginationToken: String) {
  hardwareUnits(paginationToken: $paginationToken) {
    results {
      iotThingName
      location {
        locationId
        name
        parentId
        fullName
        externalId
        address {
          line1
          line2
          line3
          postalCode
          country
          latitude
          longitude
          googlePlacesId
          __typename
        }
        metadata {
          key
          value
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      stations {
        name
        externalId
        __typename
      }
      installedOn
      updatedAt
      createdAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.HardwareUnitsQueryVariables,
  APITypes.HardwareUnitsQuery
>;
export const observation = /* GraphQL */ `query Observation($id: ID!) {
  observation(id: $id) {
    id
    observationType
    capturedBy {
      iotThingName
      location {
        locationId
        name
        parentId
        fullName
        externalId
        address {
          line1
          line2
          line3
          postalCode
          country
          latitude
          longitude
          googlePlacesId
          __typename
        }
        metadata {
          key
          value
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      stations {
        name
        externalId
        __typename
      }
      installedOn
      updatedAt
      createdAt
      __typename
    }
    timestamp
    grossWeight {
      weight
      units
      __typename
    }
    netWeight {
      weight
      units
      __typename
    }
    images {
      bucket
      key
      url
      metadata
      __typename
    }
    audio {
      observationId
      audioPath
      transcription
      transcribedBy
      __typename
    }
    labelSessions {
      labelTimestamp
      menuId
      recognizableItemId
      recognizableItemType
      container {
        id
        name
        weight {
          weight
          units
          __typename
        }
        __typename
      }
      isFlagged
      labelType
      tags

      ... on HumanObservationLabel {
        labeler
        notes
      }
      ... on MachineObservationLabel {
        description
        rationale
        modelVersion
        modelMetadata
      }
    }
    station {
      name
      externalId
      __typename
    }
    statuses {
      uiCanceled
      uiCompleted
      __typename
    }
    bestLabel {
      id
      name
      source
      externalId
      costPerUnit {
        currency
        cost
        uomQty
        uom
        numPortions
        byCount
        __typename
      }
      tags
      updatedAt
      createdAt
      costCurrency
      cost
      costUOMQty
      costUOM

      ... on MenuItem {
        recipe {
          ingredientName
          ingredientId
          qty
          qtyUOM
          perQty
          perUOM
          metadata
          __typename
        }
        metadata
        categories
      }
      ... on Ingredient {
        metadata
        labels
      }
    }
    container {
      id
      name
      weight {
        weight
        units
        __typename
      }
      __typename
    }
    value {
      value
      costCurrency
      byCount
      countingUnit
      count
      __typename
    }
    tags
  }
}
` as GeneratedQuery<
  APITypes.ObservationQueryVariables,
  APITypes.ObservationQuery
>;
export const observations = /* GraphQL */ `query Observations(
  $fromTimestamp: String!
  $toTimestamp: String!
  $parentLocationIds: [ID]
  $types: [ObservationType]
  $includeTags: [ObservationTag]
  $excludeTags: [ObservationTag]
  $searchQuery: String
  $aggregationOption: ObservationAggregationOption
  $paginateFrom: Int
  $size: Int
) {
  observations(
    fromTimestamp: $fromTimestamp
    toTimestamp: $toTimestamp
    parentLocationIds: $parentLocationIds
    types: $types
    includeTags: $includeTags
    excludeTags: $excludeTags
    searchQuery: $searchQuery
    aggregationOption: $aggregationOption
    paginateFrom: $paginateFrom
    size: $size
  ) {
    observations {
      id
      observationType
      capturedBy {
        iotThingName
        location {
          locationId
          name
          parentId
          fullName
          externalId
          address {
            line1
            line2
            line3
            postalCode
            country
            latitude
            longitude
            googlePlacesId
            __typename
          }
          metadata {
            key
            value
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        stations {
          name
          externalId
          __typename
        }
        installedOn
        updatedAt
        createdAt
        __typename
      }
      timestamp
      grossWeight {
        weight
        units
        __typename
      }
      netWeight {
        weight
        units
        __typename
      }
      images {
        bucket
        key
        url
        metadata
        __typename
      }
      audio {
        observationId
        audioPath
        transcription
        transcribedBy
        __typename
      }
      labelSessions {
        labelTimestamp
        menuId
        recognizableItemId
        recognizableItemType
        container {
          id
          name
          weight {
            weight
            units
            __typename
          }
          __typename
        }
        isFlagged
        labelType
        tags

        ... on HumanObservationLabel {
          labeler
          notes
        }
        ... on MachineObservationLabel {
          description
          rationale
          modelVersion
          modelMetadata
        }
      }
      station {
        name
        externalId
        __typename
      }
      statuses {
        uiCanceled
        uiCompleted
        __typename
      }
      bestLabel {
        id
        name
        source
        externalId
        costPerUnit {
          currency
          cost
          uomQty
          uom
          numPortions
          byCount
          __typename
        }
        tags
        updatedAt
        createdAt
        costCurrency
        cost
        costUOMQty
        costUOM

        ... on MenuItem {
          recipe {
            ingredientName
            ingredientId
            qty
            qtyUOM
            perQty
            perUOM
            metadata
            __typename
          }
          metadata
          categories
        }
        ... on Ingredient {
          metadata
          labels
        }
      }
      container {
        id
        name
        weight {
          weight
          units
          __typename
        }
        __typename
      }
      value {
        value
        costCurrency
        byCount
        countingUnit
        count
        __typename
      }
      tags
    }
    totalHits
    aggregations {
      ... on NetWeightByDay {
        day
        grams
      }
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ObservationsQueryVariables,
  APITypes.ObservationsQuery
>;
export const menu = /* GraphQL */ `query Menu($id: ID, $externalId: ID) {
  menu(id: $id, externalId: $externalId) {
    id
    menuDate
    source
    externalId
    locationId
    stationId
    name
    items {
      name
      id
      __typename
    }
    ings {
      name
      id
      __typename
    }
    updatedAt
    createdAt
    location {
      locationId
      name
      parentId
      fullName
      externalId
      address {
        line1
        line2
        line3
        postalCode
        country
        latitude
        longitude
        googlePlacesId
        __typename
      }
      metadata {
        key
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    mealPeriod
    menuItems {
      id
      name
      source
      externalId
      recipe {
        ingredientName
        ingredientId
        qty
        qtyUOM
        perQty
        perUOM
        metadata
        __typename
      }
      costPerUnit {
        currency
        cost
        uomQty
        uom
        numPortions
        byCount
        __typename
      }
      tags
      metadata
      updatedAt
      createdAt
      costCurrency
      cost
      costUOMQty
      costUOM
      categories
      __typename
    }
    ingredients {
      id
      name
      source
      externalId
      costPerUnit {
        currency
        cost
        uomQty
        uom
        numPortions
        byCount
        __typename
      }
      tags
      metadata
      updatedAt
      createdAt
      labels
      costCurrency
      cost
      costUOMQty
      costUOM
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.MenuQueryVariables, APITypes.MenuQuery>;
export const menus = /* GraphQL */ `query Menus(
  $locationId: ID!
  $stationId: ID
  $fromDate: String!
  $toDate: String
  $paginationToken: String
) {
  menus(
    locationId: $locationId
    stationId: $stationId
    fromDate: $fromDate
    toDate: $toDate
    paginationToken: $paginationToken
  ) {
    results {
      id
      menuDate
      source
      externalId
      locationId
      stationId
      name
      items {
        name
        id
        __typename
      }
      ings {
        name
        id
        __typename
      }
      updatedAt
      createdAt
      location {
        locationId
        name
        parentId
        fullName
        externalId
        address {
          line1
          line2
          line3
          postalCode
          country
          latitude
          longitude
          googlePlacesId
          __typename
        }
        metadata {
          key
          value
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      mealPeriod
      menuItems {
        id
        name
        source
        externalId
        recipe {
          ingredientName
          ingredientId
          qty
          qtyUOM
          perQty
          perUOM
          metadata
          __typename
        }
        costPerUnit {
          currency
          cost
          uomQty
          uom
          numPortions
          byCount
          __typename
        }
        tags
        metadata
        updatedAt
        createdAt
        costCurrency
        cost
        costUOMQty
        costUOM
        categories
        __typename
      }
      ingredients {
        id
        name
        source
        externalId
        costPerUnit {
          currency
          cost
          uomQty
          uom
          numPortions
          byCount
          __typename
        }
        tags
        metadata
        updatedAt
        createdAt
        labels
        costCurrency
        cost
        costUOMQty
        costUOM
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.MenusQueryVariables, APITypes.MenusQuery>;
export const menuItem = /* GraphQL */ `query MenuItem($id: ID, $externalId: ID) {
  menuItem(id: $id, externalId: $externalId) {
    id
    name
    source
    externalId
    recipe {
      ingredientName
      ingredientId
      qty
      qtyUOM
      perQty
      perUOM
      metadata
      __typename
    }
    costPerUnit {
      currency
      cost
      uomQty
      uom
      numPortions
      byCount
      __typename
    }
    tags
    metadata
    updatedAt
    createdAt
    costCurrency
    cost
    costUOMQty
    costUOM
    categories
    __typename
  }
}
` as GeneratedQuery<APITypes.MenuItemQueryVariables, APITypes.MenuItemQuery>;
export const ingredient = /* GraphQL */ `query Ingredient($id: ID, $externalId: ID) {
  ingredient(id: $id, externalId: $externalId) {
    id
    name
    source
    externalId
    costPerUnit {
      currency
      cost
      uomQty
      uom
      numPortions
      byCount
      __typename
    }
    tags
    metadata
    updatedAt
    createdAt
    labels
    costCurrency
    cost
    costUOMQty
    costUOM
    __typename
  }
}
` as GeneratedQuery<
  APITypes.IngredientQueryVariables,
  APITypes.IngredientQuery
>;
export const getOrganization = /* GraphQL */ `query GetOrganization {
  getOrganization {
    organizationId
    name
    slug
    externalId
    metadata {
      key
      value
      __typename
    }
    scanAppUsers
    scanAppUserLimit
    instantForgivenessEnabled
    returnReceivedForgivenessEnabled
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrganizationQueryVariables,
  APITypes.GetOrganizationQuery
>;
export const getParentLocation = /* GraphQL */ `query GetParentLocation($locationId: ID!) {
  getParentLocation(locationId: $locationId) {
    locationId
    name
    address {
      line1
      line2
      line3
      postalCode
      country
      latitude
      longitude
      googlePlacesId
      __typename
    }
    externalId
    locations {
      locationId
      name
      parentId
      fullName
      externalId
      address {
        line1
        line2
        line3
        postalCode
        country
        latitude
        longitude
        googlePlacesId
        __typename
      }
      metadata {
        key
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    metadata {
      key
      value
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetParentLocationQueryVariables,
  APITypes.GetParentLocationQuery
>;
export const listParentLocations = /* GraphQL */ `query ListParentLocations($paginationToken: String) {
  listParentLocations(paginationToken: $paginationToken) {
    results {
      locationId
      name
      address {
        line1
        line2
        line3
        postalCode
        country
        latitude
        longitude
        googlePlacesId
        __typename
      }
      externalId
      locations {
        locationId
        name
        parentId
        fullName
        externalId
        address {
          line1
          line2
          line3
          postalCode
          country
          latitude
          longitude
          googlePlacesId
          __typename
        }
        metadata {
          key
          value
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      metadata {
        key
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListParentLocationsQueryVariables,
  APITypes.ListParentLocationsQuery
>;
export const getLocation = /* GraphQL */ `query GetLocation($locationId: ID!) {
  getLocation(locationId: $locationId) {
    locationId
    name
    parentId
    fullName
    externalId
    address {
      line1
      line2
      line3
      postalCode
      country
      latitude
      longitude
      googlePlacesId
      __typename
    }
    metadata {
      key
      value
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLocationQueryVariables,
  APITypes.GetLocationQuery
>;
export const listLocations = /* GraphQL */ `query ListLocations($paginationToken: String) {
  listLocations(paginationToken: $paginationToken) {
    results {
      locationId
      name
      parentId
      fullName
      externalId
      address {
        line1
        line2
        line3
        postalCode
        country
        latitude
        longitude
        googlePlacesId
        __typename
      }
      metadata {
        key
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLocationsQueryVariables,
  APITypes.ListLocationsQuery
>;
export const getAsset = /* GraphQL */ `query GetAsset($assetId: ID!) {
  getAsset(assetId: $assetId) {
    assetId
    externalId
    value
    assetType {
      assetTypeId
      name
      description
      externalId
      value
      metadata {
        key
        value
        __typename
      }
      __typename
    }
    metadata {
      key
      value
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetAssetQueryVariables, APITypes.GetAssetQuery>;
export const listAssets = /* GraphQL */ `query ListAssets($paginationToken: String) {
  listAssets(paginationToken: $paginationToken) {
    results {
      assetId
      externalId
      value
      assetType {
        assetTypeId
        name
        description
        externalId
        value
        metadata {
          key
          value
          __typename
        }
        __typename
      }
      metadata {
        key
        value
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAssetsQueryVariables,
  APITypes.ListAssetsQuery
>;
export const getAssetType = /* GraphQL */ `query GetAssetType($assetTypeId: ID!) {
  getAssetType(assetTypeId: $assetTypeId) {
    assetTypeId
    name
    description
    externalId
    value
    metadata {
      key
      value
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAssetTypeQueryVariables,
  APITypes.GetAssetTypeQuery
>;
export const listAssetTypes = /* GraphQL */ `query ListAssetTypes($paginationToken: String) {
  listAssetTypes(paginationToken: $paginationToken) {
    results {
      assetTypeId
      name
      description
      externalId
      value
      metadata {
        key
        value
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAssetTypesQueryVariables,
  APITypes.ListAssetTypesQuery
>;
export const getResolution = /* GraphQL */ `query GetResolution($resolutionId: ID!) {
  getResolution(resolutionId: $resolutionId) {
    resolutionId
    type {
      likeAssetTypeResolution {
        likeAssetTypeIds
        resolutionTiming {
          timestamp
          duration
          roundToTime
          roundToTimezone
          __typename
        }
        __typename
      }
      sameAssetResolution {
        resolutionTiming {
          timestamp
          duration
          roundToTime
          roundToTimezone
          __typename
        }
        __typename
      }
      sameAssetTypeResolution {
        resolutionTiming {
          timestamp
          duration
          roundToTime
          roundToTimezone
          __typename
        }
        __typename
      }
      __typename
    }
    label
    useAgain
    name
    description
    metadata {
      key
      value
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetResolutionQueryVariables,
  APITypes.GetResolutionQuery
>;
export const listResolutions = /* GraphQL */ `query ListResolutions($paginationToken: String) {
  listResolutions(paginationToken: $paginationToken) {
    results {
      resolutionId
      type {
        likeAssetTypeResolution {
          likeAssetTypeIds
          resolutionTiming {
            timestamp
            duration
            roundToTime
            roundToTimezone
            __typename
          }
          __typename
        }
        sameAssetResolution {
          resolutionTiming {
            timestamp
            duration
            roundToTime
            roundToTimezone
            __typename
          }
          __typename
        }
        sameAssetTypeResolution {
          resolutionTiming {
            timestamp
            duration
            roundToTime
            roundToTimezone
            __typename
          }
          __typename
        }
        __typename
      }
      label
      useAgain
      name
      description
      metadata {
        key
        value
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListResolutionsQueryVariables,
  APITypes.ListResolutionsQuery
>;
export const getConsumer = /* GraphQL */ `query GetConsumer($consumerId: ID, $externalId: ID, $reuserId: ID) {
  getConsumer(
    consumerId: $consumerId
    externalId: $externalId
    reuserId: $reuserId
  ) {
    consumerId
    reuserId
    name
    phoneNumber
    email
    externalId
    metadata {
      key
      value
      __typename
    }
    marketingOptIn {
      email
      phoneNumber
      __typename
    }
    createdAt
    chargeables {
      chargeableId
      vendor
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetConsumerQueryVariables,
  APITypes.GetConsumerQuery
>;
export const listConsumers = /* GraphQL */ `query ListConsumers($paginationToken: String) {
  listConsumers(paginationToken: $paginationToken) {
    results {
      consumerId
      reuserId
      name
      phoneNumber
      email
      externalId
      metadata {
        key
        value
        __typename
      }
      marketingOptIn {
        email
        phoneNumber
        __typename
      }
      createdAt
      chargeables {
        chargeableId
        vendor
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListConsumersQueryVariables,
  APITypes.ListConsumersQuery
>;
export const getConsumerStats = /* GraphQL */ `query GetConsumerStats($consumerId: ID!) {
  getConsumerStats(consumerId: $consumerId) {
    consumerId
    openLoops
    totalClosedLoops
    totalOpenLoops
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetConsumerStatsQueryVariables,
  APITypes.GetConsumerStatsQuery
>;
export const getLoop = /* GraphQL */ `query GetLoop($loopId: ID!) {
  getLoop(loopId: $loopId) {
    loopId
    asset {
      assetId
      externalId
      value
      assetType {
        assetTypeId
        name
        description
        externalId
        value
        metadata {
          key
          value
          __typename
        }
        __typename
      }
      metadata {
        key
        value
        __typename
      }
      __typename
    }
    consumer {
      consumerId
      reuserId
      name
      phoneNumber
      email
      externalId
      metadata {
        key
        value
        __typename
      }
      marketingOptIn {
        email
        phoneNumber
        __typename
      }
      createdAt
      chargeables {
        chargeableId
        vendor
        __typename
      }
      __typename
    }
    locations {
      openedAt {
        locationId
        name
        parentId
        fullName
        externalId
        address {
          line1
          line2
          line3
          postalCode
          country
          latitude
          longitude
          googlePlacesId
          __typename
        }
        metadata {
          key
          value
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      closedAt {
        locationId
        name
        parentId
        fullName
        externalId
        address {
          line1
          line2
          line3
          postalCode
          country
          latitude
          longitude
          googlePlacesId
          __typename
        }
        metadata {
          key
          value
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    externalId
    openedAt
    closedAt
    loopStatus
    resolutions {
      loopId
      resolution {
        resolutionId
        type {
          likeAssetTypeResolution {
            likeAssetTypeIds
            resolutionTiming {
              timestamp
              duration
              roundToTime
              roundToTimezone
              __typename
            }
            __typename
          }
          sameAssetResolution {
            resolutionTiming {
              timestamp
              duration
              roundToTime
              roundToTimezone
              __typename
            }
            __typename
          }
          sameAssetTypeResolution {
            resolutionTiming {
              timestamp
              duration
              roundToTime
              roundToTimezone
              __typename
            }
            __typename
          }
          __typename
        }
        label
        useAgain
        name
        description
        metadata {
          key
          value
          __typename
        }
        __typename
      }
      resolutionStatus
      triggerResolutionAt
      __typename
    }
    updatedAt
    metadata {
      key
      value
      __typename
    }
    rentalConfig {
      rentalConfigId
      version
      name
      description
      resolutionOption
      likeAssetTypeIds
      rentalStepConfigIds
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetLoopQueryVariables, APITypes.GetLoopQuery>;
export const queryLoops = /* GraphQL */ `query QueryLoops(
  $fromTimestamp: String
  $toTimestamp: String
  $consumerId: ID
  $loopStatus: LoopStatus
  $paginationToken: String
) {
  queryLoops(
    fromTimestamp: $fromTimestamp
    toTimestamp: $toTimestamp
    consumerId: $consumerId
    loopStatus: $loopStatus
    paginationToken: $paginationToken
  ) {
    results {
      loopId
      asset {
        assetId
        externalId
        value
        assetType {
          assetTypeId
          name
          description
          externalId
          value
          metadata {
            key
            value
            __typename
          }
          __typename
        }
        metadata {
          key
          value
          __typename
        }
        __typename
      }
      consumer {
        consumerId
        reuserId
        name
        phoneNumber
        email
        externalId
        metadata {
          key
          value
          __typename
        }
        marketingOptIn {
          email
          phoneNumber
          __typename
        }
        createdAt
        chargeables {
          chargeableId
          vendor
          __typename
        }
        __typename
      }
      locations {
        openedAt {
          locationId
          name
          parentId
          fullName
          externalId
          address {
            line1
            line2
            line3
            postalCode
            country
            latitude
            longitude
            googlePlacesId
            __typename
          }
          metadata {
            key
            value
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        closedAt {
          locationId
          name
          parentId
          fullName
          externalId
          address {
            line1
            line2
            line3
            postalCode
            country
            latitude
            longitude
            googlePlacesId
            __typename
          }
          metadata {
            key
            value
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      externalId
      openedAt
      closedAt
      loopStatus
      resolutions {
        loopId
        resolution {
          resolutionId
          type {
            likeAssetTypeResolution {
              likeAssetTypeIds
              resolutionTiming {
                timestamp
                duration
                roundToTime
                roundToTimezone
                __typename
              }
              __typename
            }
            sameAssetResolution {
              resolutionTiming {
                timestamp
                duration
                roundToTime
                roundToTimezone
                __typename
              }
              __typename
            }
            sameAssetTypeResolution {
              resolutionTiming {
                timestamp
                duration
                roundToTime
                roundToTimezone
                __typename
              }
              __typename
            }
            __typename
          }
          label
          useAgain
          name
          description
          metadata {
            key
            value
            __typename
          }
          __typename
        }
        resolutionStatus
        triggerResolutionAt
        __typename
      }
      updatedAt
      metadata {
        key
        value
        __typename
      }
      rentalConfig {
        rentalConfigId
        version
        name
        description
        resolutionOption
        likeAssetTypeIds
        rentalStepConfigIds
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.QueryLoopsQueryVariables,
  APITypes.QueryLoopsQuery
>;
export const consumerQRData = /* GraphQL */ `query ConsumerQRData($consumerId: ID, $externalId: ID) {
  consumerQRData(consumerId: $consumerId, externalId: $externalId) {
    data
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ConsumerQRDataQueryVariables,
  APITypes.ConsumerQRDataQuery
>;
export const assetQRData = /* GraphQL */ `query AssetQRData($assetId: ID!) {
  assetQRData(assetId: $assetId) {
    data
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AssetQRDataQueryVariables,
  APITypes.AssetQRDataQuery
>;
export const getWebhook = /* GraphQL */ `query GetWebhook($webhookId: ID!) {
  getWebhook(webhookId: $webhookId) {
    webhookId
    name
    event
    endpoint
    status
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetWebhookQueryVariables,
  APITypes.GetWebhookQuery
>;
export const listWebhooks = /* GraphQL */ `query ListWebhooks {
  listWebhooks {
    webhookId
    name
    event
    endpoint
    status
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListWebhooksQueryVariables,
  APITypes.ListWebhooksQuery
>;
export const getAssetOperation = /* GraphQL */ `query GetAssetOperation($assetOperationId: ID!) {
  getAssetOperation(assetOperationId: $assetOperationId) {
    assetOperationId
    isVisible
    name
    operationType
    description
    externalId
    metadata {
      key
      value
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAssetOperationQueryVariables,
  APITypes.GetAssetOperationQuery
>;
export const listAssetOperations = /* GraphQL */ `query ListAssetOperations($paginationToken: String) {
  listAssetOperations(paginationToken: $paginationToken) {
    results {
      assetOperationId
      isVisible
      name
      operationType
      description
      externalId
      metadata {
        key
        value
        __typename
      }
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAssetOperationsQueryVariables,
  APITypes.ListAssetOperationsQuery
>;
export const queryAssetOperations = /* GraphQL */ `query QueryAssetOperations(
  $operationType: AssetOperationType!
  $paginationToken: String
) {
  queryAssetOperations(
    operationType: $operationType
    paginationToken: $paginationToken
  ) {
    results {
      assetOperationId
      isVisible
      name
      operationType
      description
      externalId
      metadata {
        key
        value
        __typename
      }
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.QueryAssetOperationsQueryVariables,
  APITypes.QueryAssetOperationsQuery
>;
export const getAssetEvent = /* GraphQL */ `query GetAssetEvent($assetEventId: ID!) {
  getAssetEvent(assetEventId: $assetEventId) {
    assetEventId
    assetId
    assetOperation {
      assetOperationId
      isVisible
      name
      operationType
      description
      externalId
      metadata {
        key
        value
        __typename
      }
      updatedAt
      __typename
    }
    eventTimestamp
    locationId
    metadata {
      key
      value
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAssetEventQueryVariables,
  APITypes.GetAssetEventQuery
>;
export const queryAssetEvents = /* GraphQL */ `query QueryAssetEvents(
  $assetId: ID!
  $fromTimestamp: String!
  $toTimestamp: String!
  $paginationToken: String
) {
  queryAssetEvents(
    assetId: $assetId
    fromTimestamp: $fromTimestamp
    toTimestamp: $toTimestamp
    paginationToken: $paginationToken
  ) {
    results {
      assetEventId
      assetId
      assetOperation {
        assetOperationId
        isVisible
        name
        operationType
        description
        externalId
        metadata {
          key
          value
          __typename
        }
        updatedAt
        __typename
      }
      eventTimestamp
      locationId
      metadata {
        key
        value
        __typename
      }
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.QueryAssetEventsQueryVariables,
  APITypes.QueryAssetEventsQuery
>;
export const queryLoopCounts = /* GraphQL */ `query QueryLoopCounts(
  $fromTimestamp: String!
  $toTimestamp: String!
  $timezone: String!
  $timeGranularity: TimeGranularity!
  $locationId: ID
  $assetTypeId: ID
  $consumerId: ID
) {
  queryLoopCounts(
    fromTimestamp: $fromTimestamp
    toTimestamp: $toTimestamp
    timezone: $timezone
    timeGranularity: $timeGranularity
    locationId: $locationId
    assetTypeId: $assetTypeId
    consumerId: $consumerId
  ) {
    results {
      timestamp
      loopStatus
      count
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.QueryLoopCountsQueryVariables,
  APITypes.QueryLoopCountsQuery
>;
export const queryActiveConsumerCounts = /* GraphQL */ `query QueryActiveConsumerCounts(
  $fromTimestamp: String!
  $toTimestamp: String!
  $locationId: ID
) {
  queryActiveConsumerCounts(
    fromTimestamp: $fromTimestamp
    toTimestamp: $toTimestamp
    locationId: $locationId
  ) {
    results {
      timestamp
      count
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.QueryActiveConsumerCountsQueryVariables,
  APITypes.QueryActiveConsumerCountsQuery
>;
export const queryLoopDurations = /* GraphQL */ `query QueryLoopDurations(
  $fromCohortMonth: String!
  $toCohortMonth: String!
  $fromLoopDurationDays: Int!
  $toLoopDurationDays: Int!
  $assetTypeId: ID
) {
  queryLoopDurations(
    fromCohortMonth: $fromCohortMonth
    toCohortMonth: $toCohortMonth
    fromLoopDurationDays: $fromLoopDurationDays
    toLoopDurationDays: $toLoopDurationDays
    assetTypeId: $assetTypeId
  ) {
    results {
      loopDurationDays
      cumulativeLoopsOpened
      cumulativeLoopsClosed
      percentReturned
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.QueryLoopDurationsQueryVariables,
  APITypes.QueryLoopDurationsQuery
>;
export const getAssetTypeUtilization = /* GraphQL */ `query GetAssetTypeUtilization($assetTypeId: ID!, $utilizationDate: String) {
  getAssetTypeUtilization(
    assetTypeId: $assetTypeId
    utilizationDate: $utilizationDate
  ) {
    activeAssets
    retiredAssets
    inactiveAssets
    activeWindowDays
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAssetTypeUtilizationQueryVariables,
  APITypes.GetAssetTypeUtilizationQuery
>;
export const getReturnRate = /* GraphQL */ `query GetReturnRate($assetTypeId: ID) {
  getReturnRate(assetTypeId: $assetTypeId) {
    allTimeLoopsClosed
    allTimeLoopsOpened
    allTimeReturnRate
    d30LoopsClosed
    d30LoopsOpened
    d30ReturnRate
    d60LoopsClosed
    d60LoopsOpened
    d60ReturnRate
    d90LoopsClosed
    d90LoopsOpened
    d90ReturnRate
    lastUpdated
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReturnRateQueryVariables,
  APITypes.GetReturnRateQuery
>;
export const getAssetLastKnownLocation = /* GraphQL */ `query GetAssetLastKnownLocation($assetId: ID!) {
  getAssetLastKnownLocation(assetId: $assetId) {
    assetId
    locationId
    lastAssetEventId
    lastEventTimestamp
    previousLocationId
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAssetLastKnownLocationQueryVariables,
  APITypes.GetAssetLastKnownLocationQuery
>;
export const queryInventoryChanges = /* GraphQL */ `query QueryInventoryChanges(
  $locationId: ID!
  $assetTypeId: ID!
  $fromTimestamp: String!
  $toTimestamp: String!
  $paginationToken: String
) {
  queryInventoryChanges(
    locationId: $locationId
    assetTypeId: $assetTypeId
    fromTimestamp: $fromTimestamp
    toTimestamp: $toTimestamp
    paginationToken: $paginationToken
  ) {
    results {
      inventoryChangeId
      assetTypeId
      locationId
      description
      eventId
      eventTimestamp
      eventType
      adjustByAmount
      adjustToAmount
      createdAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.QueryInventoryChangesQueryVariables,
  APITypes.QueryInventoryChangesQuery
>;
export const getCurrentInventory = /* GraphQL */ `query GetCurrentInventory($locationId: ID!, $assetTypeId: ID!) {
  getCurrentInventory(locationId: $locationId, assetTypeId: $assetTypeId) {
    assetTypeId
    locationId
    onHand
    timestamp
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCurrentInventoryQueryVariables,
  APITypes.GetCurrentInventoryQuery
>;
export const queryHistoricalInventory = /* GraphQL */ `query QueryHistoricalInventory(
  $locationId: ID!
  $assetTypeId: ID!
  $fromDate: String!
  $toDate: String!
  $timezone: String!
  $paginationToken: String
) {
  queryHistoricalInventory(
    locationId: $locationId
    assetTypeId: $assetTypeId
    fromDate: $fromDate
    toDate: $toDate
    timezone: $timezone
    paginationToken: $paginationToken
  ) {
    results {
      assetTypeId
      locationId
      onHand
      timestamp
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.QueryHistoricalInventoryQueryVariables,
  APITypes.QueryHistoricalInventoryQuery
>;
export const getScanAppUser = /* GraphQL */ `query GetScanAppUser($userId: ID!) {
  getScanAppUser(userId: $userId) {
    userId
    username
    accountType
    allLocationsEnabled
    enabledLocationIds
    selectedLocationId
    canChangeResolutions
    selectedResolutionIds
    lastActivity
    createdAt
    status
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetScanAppUserQueryVariables,
  APITypes.GetScanAppUserQuery
>;
export const listScanAppUsers = /* GraphQL */ `query ListScanAppUsers($isActive: Boolean!, $paginationToken: String) {
  listScanAppUsers(isActive: $isActive, paginationToken: $paginationToken) {
    results {
      userId
      username
      accountType
      allLocationsEnabled
      enabledLocationIds
      selectedLocationId
      canChangeResolutions
      selectedResolutionIds
      lastActivity
      createdAt
      status
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListScanAppUsersQueryVariables,
  APITypes.ListScanAppUsersQuery
>;
export const searchAssetEvents = /* GraphQL */ `query SearchAssetEvents(
  $fromTimestamp: String!
  $toTimestamp: String!
  $timezone: String!
  $calendarInterval: String!
  $operationTypes: [String!]
  $locationIds: [String!]
  $loopStatuses: [LoopStatus!]
  $searchQuery: String
  $size: Int
  $paginateFrom: Int
) {
  searchAssetEvents(
    fromTimestamp: $fromTimestamp
    toTimestamp: $toTimestamp
    timezone: $timezone
    calendarInterval: $calendarInterval
    operationTypes: $operationTypes
    locationIds: $locationIds
    loopStatuses: $loopStatuses
    searchQuery: $searchQuery
    size: $size
    paginateFrom: $paginateFrom
  ) {
    totalHits
    assetEvents {
      assetEventId
      asset {
        assetId
        externalId
        value
        assetType {
          assetTypeId
          name
          description
          externalId
          value
          metadata {
            key
            value
            __typename
          }
          __typename
        }
        metadata {
          key
          value
          __typename
        }
        __typename
      }
      assetOperation {
        assetOperationId
        isVisible
        name
        operationType
        description
        externalId
        metadata {
          key
          value
          __typename
        }
        updatedAt
        __typename
      }
      assetOperationType
      consumer {
        consumerId
        reuserId
        name
        phoneNumber
        email
        externalId
        metadata {
          key
          value
          __typename
        }
        marketingOptIn {
          email
          phoneNumber
          __typename
        }
        createdAt
        chargeables {
          chargeableId
          vendor
          __typename
        }
        __typename
      }
      eventTimestamp
      location {
        locationId
        name
        parentId
        fullName
        externalId
        address {
          line1
          line2
          line3
          postalCode
          country
          latitude
          longitude
          googlePlacesId
          __typename
        }
        metadata {
          key
          value
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      loopStatus
      metadata {
        key
        value
        __typename
      }
      updatedAt
      __typename
    }
    hitAggregations {
      key
      docCount
      subtotals {
        key
        docCount
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchAssetEventsQueryVariables,
  APITypes.SearchAssetEventsQuery
>;
export const getCharge = /* GraphQL */ `query GetCharge($chargeId: ID!) {
  getCharge(chargeId: $chargeId) {
    chargeId
    consumerId
    externalId
    chargeAmount
    chargeInfo
    chargeStatus
    failedReason {
      code
      message
      __typename
    }
    loopId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetChargeQueryVariables, APITypes.GetChargeQuery>;
export const getChargeByLoop = /* GraphQL */ `query GetChargeByLoop($loopId: ID!) {
  getChargeByLoop(loopId: $loopId) {
    chargeId
    consumerId
    externalId
    chargeAmount
    chargeInfo
    chargeStatus
    failedReason {
      code
      message
      __typename
    }
    loopId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetChargeByLoopQueryVariables,
  APITypes.GetChargeByLoopQuery
>;
export const getRentalConfig = /* GraphQL */ `query GetRentalConfig($rentalConfigId: ID!, $version: String) {
  getRentalConfig(rentalConfigId: $rentalConfigId, version: $version) {
    rentalConfigId
    version
    name
    description
    resolutionOption
    likeAssetTypeIds
    rentalStepConfigIds
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRentalConfigQueryVariables,
  APITypes.GetRentalConfigQuery
>;
export const listRentalConfigs = /* GraphQL */ `query ListRentalConfigs($latestOnly: Boolean) {
  listRentalConfigs(latestOnly: $latestOnly) {
    rentalConfigId
    version
    name
    description
    resolutionOption
    likeAssetTypeIds
    rentalStepConfigIds
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRentalConfigsQueryVariables,
  APITypes.ListRentalConfigsQuery
>;
export const getRentalStepConfig = /* GraphQL */ `query GetRentalStepConfig($id: ID!) {
  getRentalStepConfig(id: $id) {
    id
    name
    description
    rentalPhase
    timing {
      timestamp
      duration
      roundToTime
      roundToTimezone
      __typename
    }
    createdAt
    updatedAt

    ... on NotificationRentalStepConfig {
      notificationType
      notificationData
    }
  }
}
` as GeneratedQuery<
  APITypes.GetRentalStepConfigQueryVariables,
  APITypes.GetRentalStepConfigQuery
>;
export const listPhaseChangeRentalStepConfigs = /* GraphQL */ `query ListPhaseChangeRentalStepConfigs($paginationToken: String) {
  listPhaseChangeRentalStepConfigs(paginationToken: $paginationToken) {
    rentalStepConfigs {
      id
      name
      description
      rentalPhase
      timing {
        timestamp
        duration
        roundToTime
        roundToTimezone
        __typename
      }
      createdAt
      updatedAt

      ... on NotificationRentalStepConfig {
        notificationType
        notificationData
      }
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPhaseChangeRentalStepConfigsQueryVariables,
  APITypes.ListPhaseChangeRentalStepConfigsQuery
>;
export const listNotificationRentalStepConfigs = /* GraphQL */ `query ListNotificationRentalStepConfigs($paginationToken: String) {
  listNotificationRentalStepConfigs(paginationToken: $paginationToken) {
    rentalStepConfigs {
      id
      name
      description
      rentalPhase
      timing {
        timestamp
        duration
        roundToTime
        roundToTimezone
        __typename
      }
      createdAt
      updatedAt

      ... on NotificationRentalStepConfig {
        notificationType
        notificationData
      }
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNotificationRentalStepConfigsQueryVariables,
  APITypes.ListNotificationRentalStepConfigsQuery
>;
export const getRental = /* GraphQL */ `query GetRental($rentalId: ID, $loopId: ID) {
  getRental(rentalId: $rentalId, loopId: $loopId) {
    rentalId
    rentalConfigId
    rentalConfigVersion
    loopId
    consumerId
    lastPhase
    status
    rentalStepIds
    rentedAssetId
    rentedAssetTypeName
    resolvingLoop {
      loopId
      asset {
        assetId
        externalId
        value
        assetType {
          assetTypeId
          name
          description
          externalId
          value
          metadata {
            key
            value
            __typename
          }
          __typename
        }
        metadata {
          key
          value
          __typename
        }
        __typename
      }
      consumer {
        consumerId
        reuserId
        name
        phoneNumber
        email
        externalId
        metadata {
          key
          value
          __typename
        }
        marketingOptIn {
          email
          phoneNumber
          __typename
        }
        createdAt
        chargeables {
          chargeableId
          vendor
          __typename
        }
        __typename
      }
      locations {
        openedAt {
          locationId
          name
          parentId
          fullName
          externalId
          address {
            line1
            line2
            line3
            postalCode
            country
            latitude
            longitude
            googlePlacesId
            __typename
          }
          metadata {
            key
            value
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        closedAt {
          locationId
          name
          parentId
          fullName
          externalId
          address {
            line1
            line2
            line3
            postalCode
            country
            latitude
            longitude
            googlePlacesId
            __typename
          }
          metadata {
            key
            value
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      externalId
      openedAt
      closedAt
      loopStatus
      resolutions {
        loopId
        resolution {
          resolutionId
          type {
            likeAssetTypeResolution {
              likeAssetTypeIds
              resolutionTiming {
                timestamp
                duration
                roundToTime
                roundToTimezone
                __typename
              }
              __typename
            }
            sameAssetResolution {
              resolutionTiming {
                timestamp
                duration
                roundToTime
                roundToTimezone
                __typename
              }
              __typename
            }
            sameAssetTypeResolution {
              resolutionTiming {
                timestamp
                duration
                roundToTime
                roundToTimezone
                __typename
              }
              __typename
            }
            __typename
          }
          label
          useAgain
          name
          description
          metadata {
            key
            value
            __typename
          }
          __typename
        }
        resolutionStatus
        triggerResolutionAt
        __typename
      }
      updatedAt
      metadata {
        key
        value
        __typename
      }
      rentalConfig {
        rentalConfigId
        version
        name
        description
        resolutionOption
        likeAssetTypeIds
        rentalStepConfigIds
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    resolvingLoopId
    receivedAssetId
    receivedAssetTypeName
    createdAt
    updatedAt
    metadata {
      key
      value
      __typename
    }
    forgivenessDetails {
      timestamp
      reason
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetRentalQueryVariables, APITypes.GetRentalQuery>;
export const listRentals = /* GraphQL */ `query ListRentals(
  $consumerId: ID!
  $rentalStatus: RentalStatus
  $paginationToken: String
) {
  listRentals(
    consumerId: $consumerId
    rentalStatus: $rentalStatus
    paginationToken: $paginationToken
  ) {
    rentals {
      rentalId
      rentalConfigId
      rentalConfigVersion
      loopId
      consumerId
      lastPhase
      status
      rentalStepIds
      rentedAssetId
      rentedAssetTypeName
      resolvingLoop {
        loopId
        asset {
          assetId
          externalId
          value
          assetType {
            assetTypeId
            name
            description
            externalId
            value
            metadata {
              key
              value
              __typename
            }
            __typename
          }
          metadata {
            key
            value
            __typename
          }
          __typename
        }
        consumer {
          consumerId
          reuserId
          name
          phoneNumber
          email
          externalId
          metadata {
            key
            value
            __typename
          }
          marketingOptIn {
            email
            phoneNumber
            __typename
          }
          createdAt
          chargeables {
            chargeableId
            vendor
            __typename
          }
          __typename
        }
        locations {
          openedAt {
            locationId
            name
            parentId
            fullName
            externalId
            address {
              line1
              line2
              line3
              postalCode
              country
              latitude
              longitude
              googlePlacesId
              __typename
            }
            metadata {
              key
              value
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          closedAt {
            locationId
            name
            parentId
            fullName
            externalId
            address {
              line1
              line2
              line3
              postalCode
              country
              latitude
              longitude
              googlePlacesId
              __typename
            }
            metadata {
              key
              value
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        externalId
        openedAt
        closedAt
        loopStatus
        resolutions {
          loopId
          resolution {
            resolutionId
            type {
              likeAssetTypeResolution {
                likeAssetTypeIds
                resolutionTiming {
                  timestamp
                  duration
                  roundToTime
                  roundToTimezone
                  __typename
                }
                __typename
              }
              sameAssetResolution {
                resolutionTiming {
                  timestamp
                  duration
                  roundToTime
                  roundToTimezone
                  __typename
                }
                __typename
              }
              sameAssetTypeResolution {
                resolutionTiming {
                  timestamp
                  duration
                  roundToTime
                  roundToTimezone
                  __typename
                }
                __typename
              }
              __typename
            }
            label
            useAgain
            name
            description
            metadata {
              key
              value
              __typename
            }
            __typename
          }
          resolutionStatus
          triggerResolutionAt
          __typename
        }
        updatedAt
        metadata {
          key
          value
          __typename
        }
        rentalConfig {
          rentalConfigId
          version
          name
          description
          resolutionOption
          likeAssetTypeIds
          rentalStepConfigIds
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      resolvingLoopId
      receivedAssetId
      receivedAssetTypeName
      createdAt
      updatedAt
      metadata {
        key
        value
        __typename
      }
      forgivenessDetails {
        timestamp
        reason
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRentalsQueryVariables,
  APITypes.ListRentalsQuery
>;
export const searchRentals = /* GraphQL */ `query SearchRentals(
  $fromTimestamp: String!
  $toTimestamp: String!
  $rentalPhases: [RentalPhase!]
  $rentalStatuses: [RentalStatus!]
  $assetTypeIds: [String!]
  $consumerId: ID
  $searchQuery: String
  $size: Int
  $paginateFrom: Int
  $filter: SearchRentalsFilter
  $withCharges: Boolean
) {
  searchRentals(
    fromTimestamp: $fromTimestamp
    toTimestamp: $toTimestamp
    rentalPhases: $rentalPhases
    rentalStatuses: $rentalStatuses
    assetTypeIds: $assetTypeIds
    consumerId: $consumerId
    searchQuery: $searchQuery
    size: $size
    paginateFrom: $paginateFrom
    filter: $filter
    withCharges: $withCharges
  ) {
    totalHits
    rentals {
      organizationId
      rentalId
      rentalConfig {
        rentalConfigId
        version
        name
        description
        resolutionOption
        likeAssetTypeIds
        rentalStepConfigIds
        createdAt
        updatedAt
        __typename
      }
      loopId
      lastPhase
      status
      consumer {
        consumerId
        reuserId
        name
        phoneNumber
        email
        externalId
        metadata {
          key
          value
          __typename
        }
        marketingOptIn {
          email
          phoneNumber
          __typename
        }
        createdAt
        chargeables {
          chargeableId
          vendor
          __typename
        }
        __typename
      }
      rentalStepIds
      rentedAsset {
        assetId
        externalId
        value
        assetType {
          assetTypeId
          name
          description
          externalId
          value
          metadata {
            key
            value
            __typename
          }
          __typename
        }
        metadata {
          key
          value
          __typename
        }
        __typename
      }
      receivedAsset {
        assetId
        externalId
        value
        assetType {
          assetTypeId
          name
          description
          externalId
          value
          metadata {
            key
            value
            __typename
          }
          __typename
        }
        metadata {
          key
          value
          __typename
        }
        __typename
      }
      resolvingLoop {
        loopId
        closedAt
        __typename
      }
      resolvingLoopId
      dueAt
      createdAt
      updatedAt
      metadata {
        key
        value
        __typename
      }
      charge {
        chargeId
        consumerId
        externalId
        chargeAmount
        chargeInfo
        chargeStatus
        failedReason {
          code
          message
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      forgivenessDetails {
        timestamp
        reason
        __typename
      }
      __typename
    }
    hitAggregations {
      key
      docCount
      subtotals {
        key
        docCount
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchRentalsQueryVariables,
  APITypes.SearchRentalsQuery
>;
export const getRentalStep = /* GraphQL */ `query GetRentalStep($id: ID!) {
  getRentalStep(id: $id) {
    id
    rentalId
    rentalStepConfigId
    rentalPhase
    triggerAt
    status
    statusUpdatedAt
    createdAt
    rentalStepConfigType
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRentalStepQueryVariables,
  APITypes.GetRentalStepQuery
>;
export const listRentalStepsForRental = /* GraphQL */ `query ListRentalStepsForRental(
  $rentalId: ID!
  $rentalStepStatus: RentalStepStatus
) {
  listRentalStepsForRental(
    rentalId: $rentalId
    rentalStepStatus: $rentalStepStatus
  ) {
    id
    rentalId
    rentalStepConfigId
    rentalPhase
    triggerAt
    status
    statusUpdatedAt
    createdAt
    rentalStepConfigType
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRentalStepsForRentalQueryVariables,
  APITypes.ListRentalStepsForRentalQuery
>;
export const listOrganizationRentalPhases = /* GraphQL */ `query ListOrganizationRentalPhases {
  listOrganizationRentalPhases
}
` as GeneratedQuery<
  APITypes.ListOrganizationRentalPhasesQueryVariables,
  APITypes.ListOrganizationRentalPhasesQuery
>;
export const getStatReturnRate = /* GraphQL */ `query GetStatReturnRate($assetTypeId: ID) {
  getStatReturnRate(assetTypeId: $assetTypeId) {
    returnRates {
      window
      loopsClosed
      loopsOpened
      returnRate
      __typename
    }
    isAvailable
    assetTypeId
    lastUpdated
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStatReturnRateQueryVariables,
  APITypes.GetStatReturnRateQuery
>;
export const getStatLoopDurations = /* GraphQL */ `query GetStatLoopDurations($assetTypeId: ID) {
  getStatLoopDurations(assetTypeId: $assetTypeId) {
    loopDurations {
      days
      percentReturned
      __typename
    }
    isAvailable
    assetTypeId
    lastUpdated
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStatLoopDurationsQueryVariables,
  APITypes.GetStatLoopDurationsQuery
>;
export const getStatCycleRate = /* GraphQL */ `query GetStatCycleRate($assetTypeId: ID) {
  getStatCycleRate(assetTypeId: $assetTypeId) {
    isAvailable
    assetTypeId
    loopDuration
    loopDurationUnit
    shelfTime
    shelfTimeUnit
    cycleRateTotal
    cycleRateUnit
    lastUpdated
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStatCycleRateQueryVariables,
  APITypes.GetStatCycleRateQuery
>;
export const getStatCheckoutsReturns = /* GraphQL */ `query GetStatCheckoutsReturns(
  $window: StatWindow
  $assetTypeId: ID
  $parentLocationIds: [ID]
) {
  getStatCheckoutsReturns(
    window: $window
    assetTypeId: $assetTypeId
    parentLocationIds: $parentLocationIds
  ) {
    isAvailable
    statWindow
    assetTypeId
    parentLocationIds
    avgByDay {
      dayOfWeek
      avgLoopsOpened
      totalOpened
      avgLoopsClosed
      totalClosed
      daysCount
      __typename
    }
    avgOpenedForWindow
    totalOpenedForWindow
    avgClosedForWindow
    totalClosedForWindow
    lastUpdated
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStatCheckoutsReturnsQueryVariables,
  APITypes.GetStatCheckoutsReturnsQuery
>;
export const getStatMissedScans = /* GraphQL */ `query GetStatMissedScans($assetTypeId: ID) {
  getStatMissedScans(assetTypeId: $assetTypeId) {
    isAvailable
    assetTypeId
    percentMissed
    previousPercentMissed
    previousComparisonWindow
    lastUpdated
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStatMissedScansQueryVariables,
  APITypes.GetStatMissedScansQuery
>;
export const getStatAssetUsage = /* GraphQL */ `query GetStatAssetUsage($assetTypeId: ID) {
  getStatAssetUsage(assetTypeId: $assetTypeId) {
    usageBuckets {
      label
      bucketCount
      __typename
    }
    isAvailable
    assetTypeId
    lastUpdated
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStatAssetUsageQueryVariables,
  APITypes.GetStatAssetUsageQuery
>;
export const getStatReuseRate = /* GraphQL */ `query GetStatReuseRate($assetTypeId: ID) {
  getStatReuseRate(assetTypeId: $assetTypeId) {
    reuseRateBuckets {
      rank
      label
      quantity
      __typename
    }
    avgUsesPerAsset
    mostUsedAssetCount
    mostUsedAssetId
    isAvailable
    assetTypeId
    lastUpdated
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStatReuseRateQueryVariables,
  APITypes.GetStatReuseRateQuery
>;
export const getExport = /* GraphQL */ `query GetExport($exportId: ID!) {
  getExport(exportId: $exportId) {
    url
    expiration
    __typename
  }
}
` as GeneratedQuery<APITypes.GetExportQueryVariables, APITypes.GetExportQuery>;
export const listExports = /* GraphQL */ `query ListExports {
  listExports {
    exportId
    completedFileKey
    status
    createdAt
    fromTimestamp
    toTimestamp
    size
    email
    initialRentalsCount
    finalRentalsCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListExportsQueryVariables,
  APITypes.ListExportsQuery
>;
