/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const addObservationTags = /* GraphQL */ `mutation AddObservationTags($input: ObservationTagInput) {
  addObservationTags(input: $input)
}
` as GeneratedMutation<
  APITypes.AddObservationTagsMutationVariables,
  APITypes.AddObservationTagsMutation
>;
export const removeObservationTags = /* GraphQL */ `mutation RemoveObservationTags($input: ObservationTagInput) {
  removeObservationTags(input: $input)
}
` as GeneratedMutation<
  APITypes.RemoveObservationTagsMutationVariables,
  APITypes.RemoveObservationTagsMutation
>;
export const addHumanLabel = /* GraphQL */ `mutation AddHumanLabel($input: AddHumanLabelSessionInput) {
  addHumanLabel(input: $input)
}
` as GeneratedMutation<
  APITypes.AddHumanLabelMutationVariables,
  APITypes.AddHumanLabelMutation
>;
export const updateObservationItemCount = /* GraphQL */ `mutation UpdateObservationItemCount($input: ObservationItemCountInput) {
  updateObservationItemCount(input: $input)
}
` as GeneratedMutation<
  APITypes.UpdateObservationItemCountMutationVariables,
  APITypes.UpdateObservationItemCountMutation
>;
export const createIngredient = /* GraphQL */ `mutation CreateIngredient($input: CreateIngredientInput) {
  createIngredient(input: $input) {
    id
    name
    source
    externalId
    costPerUnit {
      currency
      cost
      uomQty
      uom
      numPortions
      byCount
      __typename
    }
    tags
    metadata
    updatedAt
    createdAt
    labels
    costCurrency
    cost
    costUOMQty
    costUOM
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateIngredientMutationVariables,
  APITypes.CreateIngredientMutation
>;
export const updateIngredient = /* GraphQL */ `mutation UpdateIngredient($input: UpdateIngredientInput) {
  updateIngredient(input: $input)
}
` as GeneratedMutation<
  APITypes.UpdateIngredientMutationVariables,
  APITypes.UpdateIngredientMutation
>;
export const createMenuItem = /* GraphQL */ `mutation CreateMenuItem($input: CreateMenuItemInput) {
  createMenuItem(input: $input) {
    id
    name
    source
    externalId
    recipe {
      ingredientName
      ingredientId
      qty
      qtyUOM
      perQty
      perUOM
      metadata
      __typename
    }
    costPerUnit {
      currency
      cost
      uomQty
      uom
      numPortions
      byCount
      __typename
    }
    tags
    metadata
    updatedAt
    createdAt
    costCurrency
    cost
    costUOMQty
    costUOM
    categories
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMenuItemMutationVariables,
  APITypes.CreateMenuItemMutation
>;
export const updateMenuItem = /* GraphQL */ `mutation UpdateMenuItem($input: UpdateMenuItemInput) {
  updateMenuItem(input: $input)
}
` as GeneratedMutation<
  APITypes.UpdateMenuItemMutationVariables,
  APITypes.UpdateMenuItemMutation
>;
export const createMenu = /* GraphQL */ `mutation CreateMenu($input: CreateMenuInput) {
  createMenu(input: $input) {
    id
    menuDate
    source
    externalId
    locationId
    stationId
    name
    items {
      name
      id
      __typename
    }
    ings {
      name
      id
      __typename
    }
    updatedAt
    createdAt
    location {
      locationId
      name
      parentId
      fullName
      externalId
      address {
        line1
        line2
        line3
        postalCode
        country
        latitude
        longitude
        googlePlacesId
        __typename
      }
      metadata {
        key
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    mealPeriod
    menuItems {
      id
      name
      source
      externalId
      recipe {
        ingredientName
        ingredientId
        qty
        qtyUOM
        perQty
        perUOM
        metadata
        __typename
      }
      costPerUnit {
        currency
        cost
        uomQty
        uom
        numPortions
        byCount
        __typename
      }
      tags
      metadata
      updatedAt
      createdAt
      costCurrency
      cost
      costUOMQty
      costUOM
      categories
      __typename
    }
    ingredients {
      id
      name
      source
      externalId
      costPerUnit {
        currency
        cost
        uomQty
        uom
        numPortions
        byCount
        __typename
      }
      tags
      metadata
      updatedAt
      createdAt
      labels
      costCurrency
      cost
      costUOMQty
      costUOM
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMenuMutationVariables,
  APITypes.CreateMenuMutation
>;
export const updateMenu = /* GraphQL */ `mutation UpdateMenu($input: UpdateMenuInput) {
  updateMenu(input: $input)
}
` as GeneratedMutation<
  APITypes.UpdateMenuMutationVariables,
  APITypes.UpdateMenuMutation
>;
export const addIngredientToMenu = /* GraphQL */ `mutation AddIngredientToMenu($input: ModifyMenuWithIngredientInput) {
  addIngredientToMenu(input: $input)
}
` as GeneratedMutation<
  APITypes.AddIngredientToMenuMutationVariables,
  APITypes.AddIngredientToMenuMutation
>;
export const removeIngredientFromMenu = /* GraphQL */ `mutation RemoveIngredientFromMenu($input: ModifyMenuWithIngredientInput) {
  removeIngredientFromMenu(input: $input)
}
` as GeneratedMutation<
  APITypes.RemoveIngredientFromMenuMutationVariables,
  APITypes.RemoveIngredientFromMenuMutation
>;
export const addMenuItemToMenu = /* GraphQL */ `mutation AddMenuItemToMenu($input: ModifyMenuWithMenuItemInput) {
  addMenuItemToMenu(input: $input)
}
` as GeneratedMutation<
  APITypes.AddMenuItemToMenuMutationVariables,
  APITypes.AddMenuItemToMenuMutation
>;
export const removeMenuItemToMenu = /* GraphQL */ `mutation RemoveMenuItemToMenu($input: ModifyMenuWithMenuItemInput) {
  removeMenuItemToMenu(input: $input)
}
` as GeneratedMutation<
  APITypes.RemoveMenuItemToMenuMutationVariables,
  APITypes.RemoveMenuItemToMenuMutation
>;
export const updateOrganizationInstantForgivenessEnabled = /* GraphQL */ `mutation UpdateOrganizationInstantForgivenessEnabled(
  $input: UpdateOrganizationInstantForgivenessEnabledInput!
) {
  updateOrganizationInstantForgivenessEnabled(input: $input) {
    organizationId
    name
    slug
    externalId
    metadata {
      key
      value
      __typename
    }
    scanAppUsers
    scanAppUserLimit
    instantForgivenessEnabled
    returnReceivedForgivenessEnabled
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrganizationInstantForgivenessEnabledMutationVariables,
  APITypes.UpdateOrganizationInstantForgivenessEnabledMutation
>;
export const updateOrganizationReturnReceivedForgivenessEnabled = /* GraphQL */ `mutation UpdateOrganizationReturnReceivedForgivenessEnabled(
  $input: UpdateOrganizationReturnReceivedForgivenessEnabledInput!
) {
  updateOrganizationReturnReceivedForgivenessEnabled(input: $input) {
    organizationId
    name
    slug
    externalId
    metadata {
      key
      value
      __typename
    }
    scanAppUsers
    scanAppUserLimit
    instantForgivenessEnabled
    returnReceivedForgivenessEnabled
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrganizationReturnReceivedForgivenessEnabledMutationVariables,
  APITypes.UpdateOrganizationReturnReceivedForgivenessEnabledMutation
>;
export const createParentLocation = /* GraphQL */ `mutation CreateParentLocation($input: CreateParentLocationInput!) {
  createParentLocation(input: $input) {
    locationId
    name
    address {
      line1
      line2
      line3
      postalCode
      country
      latitude
      longitude
      googlePlacesId
      __typename
    }
    externalId
    locations {
      locationId
      name
      parentId
      fullName
      externalId
      address {
        line1
        line2
        line3
        postalCode
        country
        latitude
        longitude
        googlePlacesId
        __typename
      }
      metadata {
        key
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    metadata {
      key
      value
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateParentLocationMutationVariables,
  APITypes.CreateParentLocationMutation
>;
export const updateParentLocation = /* GraphQL */ `mutation UpdateParentLocation($input: UpdateParentLocationInput!) {
  updateParentLocation(input: $input) {
    locationId
    name
    address {
      line1
      line2
      line3
      postalCode
      country
      latitude
      longitude
      googlePlacesId
      __typename
    }
    externalId
    locations {
      locationId
      name
      parentId
      fullName
      externalId
      address {
        line1
        line2
        line3
        postalCode
        country
        latitude
        longitude
        googlePlacesId
        __typename
      }
      metadata {
        key
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    metadata {
      key
      value
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateParentLocationMutationVariables,
  APITypes.UpdateParentLocationMutation
>;
export const archiveParentLocation = /* GraphQL */ `mutation ArchiveParentLocation($input: ArchiveParentLocationInput!) {
  archiveParentLocation(input: $input) {
    locationId
    name
    address {
      line1
      line2
      line3
      postalCode
      country
      latitude
      longitude
      googlePlacesId
      __typename
    }
    externalId
    locations {
      locationId
      name
      parentId
      fullName
      externalId
      address {
        line1
        line2
        line3
        postalCode
        country
        latitude
        longitude
        googlePlacesId
        __typename
      }
      metadata {
        key
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    metadata {
      key
      value
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ArchiveParentLocationMutationVariables,
  APITypes.ArchiveParentLocationMutation
>;
export const createLocation = /* GraphQL */ `mutation CreateLocation($input: CreateLocationInput!) {
  createLocation(input: $input) {
    locationId
    name
    parentId
    fullName
    externalId
    address {
      line1
      line2
      line3
      postalCode
      country
      latitude
      longitude
      googlePlacesId
      __typename
    }
    metadata {
      key
      value
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLocationMutationVariables,
  APITypes.CreateLocationMutation
>;
export const updateLocation = /* GraphQL */ `mutation UpdateLocation($input: UpdateLocationInput!) {
  updateLocation(input: $input) {
    locationId
    name
    parentId
    fullName
    externalId
    address {
      line1
      line2
      line3
      postalCode
      country
      latitude
      longitude
      googlePlacesId
      __typename
    }
    metadata {
      key
      value
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLocationMutationVariables,
  APITypes.UpdateLocationMutation
>;
export const deleteLocation = /* GraphQL */ `mutation DeleteLocation($input: DeleteLocationInput!) {
  deleteLocation(input: $input) {
    locationId
    name
    parentId
    fullName
    externalId
    address {
      line1
      line2
      line3
      postalCode
      country
      latitude
      longitude
      googlePlacesId
      __typename
    }
    metadata {
      key
      value
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteLocationMutationVariables,
  APITypes.DeleteLocationMutation
>;
export const archiveLocation = /* GraphQL */ `mutation ArchiveLocation($input: ArchiveLocationInput!) {
  archiveLocation(input: $input) {
    locationId
    name
    parentId
    fullName
    externalId
    address {
      line1
      line2
      line3
      postalCode
      country
      latitude
      longitude
      googlePlacesId
      __typename
    }
    metadata {
      key
      value
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ArchiveLocationMutationVariables,
  APITypes.ArchiveLocationMutation
>;
export const createConsumer = /* GraphQL */ `mutation CreateConsumer($input: CreateConsumerInput!) {
  createConsumer(input: $input) {
    consumerId
    reuserId
    name
    phoneNumber
    email
    externalId
    metadata {
      key
      value
      __typename
    }
    marketingOptIn {
      email
      phoneNumber
      __typename
    }
    createdAt
    chargeables {
      chargeableId
      vendor
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateConsumerMutationVariables,
  APITypes.CreateConsumerMutation
>;
export const updateConsumer = /* GraphQL */ `mutation UpdateConsumer($input: UpdateConsumerInput!) {
  updateConsumer(input: $input) {
    consumerId
    reuserId
    name
    phoneNumber
    email
    externalId
    metadata {
      key
      value
      __typename
    }
    marketingOptIn {
      email
      phoneNumber
      __typename
    }
    createdAt
    chargeables {
      chargeableId
      vendor
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateConsumerMutationVariables,
  APITypes.UpdateConsumerMutation
>;
export const deleteConsumer = /* GraphQL */ `mutation DeleteConsumer($input: DeleteConsumerInput!) {
  deleteConsumer(input: $input) {
    consumerId
    reuserId
    name
    phoneNumber
    email
    externalId
    metadata {
      key
      value
      __typename
    }
    marketingOptIn {
      email
      phoneNumber
      __typename
    }
    createdAt
    chargeables {
      chargeableId
      vendor
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteConsumerMutationVariables,
  APITypes.DeleteConsumerMutation
>;
export const createAsset = /* GraphQL */ `mutation CreateAsset($input: CreateAssetInput!) {
  createAsset(input: $input) {
    assetId
    externalId
    value
    assetType {
      assetTypeId
      name
      description
      externalId
      value
      metadata {
        key
        value
        __typename
      }
      __typename
    }
    metadata {
      key
      value
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAssetMutationVariables,
  APITypes.CreateAssetMutation
>;
export const updateAsset = /* GraphQL */ `mutation UpdateAsset($input: UpdateAssetInput!) {
  updateAsset(input: $input) {
    assetId
    externalId
    value
    assetType {
      assetTypeId
      name
      description
      externalId
      value
      metadata {
        key
        value
        __typename
      }
      __typename
    }
    metadata {
      key
      value
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAssetMutationVariables,
  APITypes.UpdateAssetMutation
>;
export const deleteAsset = /* GraphQL */ `mutation DeleteAsset($input: DeleteAssetInput!) {
  deleteAsset(input: $input) {
    assetId
    externalId
    value
    assetType {
      assetTypeId
      name
      description
      externalId
      value
      metadata {
        key
        value
        __typename
      }
      __typename
    }
    metadata {
      key
      value
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAssetMutationVariables,
  APITypes.DeleteAssetMutation
>;
export const createAssetType = /* GraphQL */ `mutation CreateAssetType($input: CreateAssetTypeInput!) {
  createAssetType(input: $input) {
    assetTypeId
    name
    description
    externalId
    value
    metadata {
      key
      value
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAssetTypeMutationVariables,
  APITypes.CreateAssetTypeMutation
>;
export const updateAssetType = /* GraphQL */ `mutation UpdateAssetType($input: UpdateAssetTypeInput!) {
  updateAssetType(input: $input) {
    assetTypeId
    name
    description
    externalId
    value
    metadata {
      key
      value
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAssetTypeMutationVariables,
  APITypes.UpdateAssetTypeMutation
>;
export const deleteAssetType = /* GraphQL */ `mutation DeleteAssetType($input: DeleteAssetTypeInput!) {
  deleteAssetType(input: $input) {
    assetTypeId
    name
    description
    externalId
    value
    metadata {
      key
      value
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAssetTypeMutationVariables,
  APITypes.DeleteAssetTypeMutation
>;
export const createResolution = /* GraphQL */ `mutation CreateResolution($input: CreateResolutionInput!) {
  createResolution(input: $input) {
    resolutionId
    type {
      likeAssetTypeResolution {
        likeAssetTypeIds
        resolutionTiming {
          timestamp
          duration
          roundToTime
          roundToTimezone
          __typename
        }
        __typename
      }
      sameAssetResolution {
        resolutionTiming {
          timestamp
          duration
          roundToTime
          roundToTimezone
          __typename
        }
        __typename
      }
      sameAssetTypeResolution {
        resolutionTiming {
          timestamp
          duration
          roundToTime
          roundToTimezone
          __typename
        }
        __typename
      }
      __typename
    }
    label
    useAgain
    name
    description
    metadata {
      key
      value
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateResolutionMutationVariables,
  APITypes.CreateResolutionMutation
>;
export const updateResolution = /* GraphQL */ `mutation UpdateResolution($input: UpdateResolutionInput!) {
  updateResolution(input: $input) {
    resolutionId
    type {
      likeAssetTypeResolution {
        likeAssetTypeIds
        resolutionTiming {
          timestamp
          duration
          roundToTime
          roundToTimezone
          __typename
        }
        __typename
      }
      sameAssetResolution {
        resolutionTiming {
          timestamp
          duration
          roundToTime
          roundToTimezone
          __typename
        }
        __typename
      }
      sameAssetTypeResolution {
        resolutionTiming {
          timestamp
          duration
          roundToTime
          roundToTimezone
          __typename
        }
        __typename
      }
      __typename
    }
    label
    useAgain
    name
    description
    metadata {
      key
      value
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateResolutionMutationVariables,
  APITypes.UpdateResolutionMutation
>;
export const deleteResolution = /* GraphQL */ `mutation DeleteResolution($input: DeleteResolutionInput!) {
  deleteResolution(input: $input) {
    resolutionId
    type {
      likeAssetTypeResolution {
        likeAssetTypeIds
        resolutionTiming {
          timestamp
          duration
          roundToTime
          roundToTimezone
          __typename
        }
        __typename
      }
      sameAssetResolution {
        resolutionTiming {
          timestamp
          duration
          roundToTime
          roundToTimezone
          __typename
        }
        __typename
      }
      sameAssetTypeResolution {
        resolutionTiming {
          timestamp
          duration
          roundToTime
          roundToTimezone
          __typename
        }
        __typename
      }
      __typename
    }
    label
    useAgain
    name
    description
    metadata {
      key
      value
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteResolutionMutationVariables,
  APITypes.DeleteResolutionMutation
>;
export const createLoop = /* GraphQL */ `mutation CreateLoop($input: CreateLoopInput!) {
  createLoop(input: $input) {
    loopId
    asset {
      assetId
      externalId
      value
      assetType {
        assetTypeId
        name
        description
        externalId
        value
        metadata {
          key
          value
          __typename
        }
        __typename
      }
      metadata {
        key
        value
        __typename
      }
      __typename
    }
    consumer {
      consumerId
      reuserId
      name
      phoneNumber
      email
      externalId
      metadata {
        key
        value
        __typename
      }
      marketingOptIn {
        email
        phoneNumber
        __typename
      }
      createdAt
      chargeables {
        chargeableId
        vendor
        __typename
      }
      __typename
    }
    locations {
      openedAt {
        locationId
        name
        parentId
        fullName
        externalId
        address {
          line1
          line2
          line3
          postalCode
          country
          latitude
          longitude
          googlePlacesId
          __typename
        }
        metadata {
          key
          value
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      closedAt {
        locationId
        name
        parentId
        fullName
        externalId
        address {
          line1
          line2
          line3
          postalCode
          country
          latitude
          longitude
          googlePlacesId
          __typename
        }
        metadata {
          key
          value
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    externalId
    openedAt
    closedAt
    loopStatus
    resolutions {
      loopId
      resolution {
        resolutionId
        type {
          likeAssetTypeResolution {
            likeAssetTypeIds
            resolutionTiming {
              timestamp
              duration
              roundToTime
              roundToTimezone
              __typename
            }
            __typename
          }
          sameAssetResolution {
            resolutionTiming {
              timestamp
              duration
              roundToTime
              roundToTimezone
              __typename
            }
            __typename
          }
          sameAssetTypeResolution {
            resolutionTiming {
              timestamp
              duration
              roundToTime
              roundToTimezone
              __typename
            }
            __typename
          }
          __typename
        }
        label
        useAgain
        name
        description
        metadata {
          key
          value
          __typename
        }
        __typename
      }
      resolutionStatus
      triggerResolutionAt
      __typename
    }
    updatedAt
    metadata {
      key
      value
      __typename
    }
    rentalConfig {
      rentalConfigId
      version
      name
      description
      resolutionOption
      likeAssetTypeIds
      rentalStepConfigIds
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLoopMutationVariables,
  APITypes.CreateLoopMutation
>;
export const closeLoop = /* GraphQL */ `mutation CloseLoop($input: CloseLoopInput!) {
  closeLoop(input: $input) {
    consumerId
    assetId
    loopId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CloseLoopMutationVariables,
  APITypes.CloseLoopMutation
>;
export const createWebhook = /* GraphQL */ `mutation CreateWebhook($input: CreateWebhookInput!) {
  createWebhook(input: $input) {
    webhookId
    name
    event
    endpoint
    status
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateWebhookMutationVariables,
  APITypes.CreateWebhookMutation
>;
export const updateWebhook = /* GraphQL */ `mutation UpdateWebhook($input: UpdateWebhookInput!) {
  updateWebhook(input: $input) {
    webhookId
    name
    event
    endpoint
    status
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateWebhookMutationVariables,
  APITypes.UpdateWebhookMutation
>;
export const deleteWebhook = /* GraphQL */ `mutation DeleteWebhook($input: DeleteWebhookInput!) {
  deleteWebhook(input: $input) {
    webhookId
    name
    event
    endpoint
    status
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteWebhookMutationVariables,
  APITypes.DeleteWebhookMutation
>;
export const createAssetOperation = /* GraphQL */ `mutation CreateAssetOperation($input: CreateAssetOperationInput!) {
  createAssetOperation(input: $input) {
    assetOperationId
    isVisible
    name
    operationType
    description
    externalId
    metadata {
      key
      value
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAssetOperationMutationVariables,
  APITypes.CreateAssetOperationMutation
>;
export const updateAssetOperation = /* GraphQL */ `mutation UpdateAssetOperation($input: UpdateAssetOperationInput!) {
  updateAssetOperation(input: $input) {
    assetOperationId
    isVisible
    name
    operationType
    description
    externalId
    metadata {
      key
      value
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAssetOperationMutationVariables,
  APITypes.UpdateAssetOperationMutation
>;
export const createAssetEvent = /* GraphQL */ `mutation CreateAssetEvent($input: CreateAssetEventInput!) {
  createAssetEvent(input: $input) {
    assetEventId
    assetId
    assetOperation {
      assetOperationId
      isVisible
      name
      operationType
      description
      externalId
      metadata {
        key
        value
        __typename
      }
      updatedAt
      __typename
    }
    eventTimestamp
    locationId
    metadata {
      key
      value
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAssetEventMutationVariables,
  APITypes.CreateAssetEventMutation
>;
export const updateAssetEvent = /* GraphQL */ `mutation UpdateAssetEvent($input: UpdateAssetEventInput!) {
  updateAssetEvent(input: $input) {
    assetEventId
    assetId
    assetOperation {
      assetOperationId
      isVisible
      name
      operationType
      description
      externalId
      metadata {
        key
        value
        __typename
      }
      updatedAt
      __typename
    }
    eventTimestamp
    locationId
    metadata {
      key
      value
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAssetEventMutationVariables,
  APITypes.UpdateAssetEventMutation
>;
export const deleteAssetEvent = /* GraphQL */ `mutation DeleteAssetEvent($input: DeleteAssetEventInput!) {
  deleteAssetEvent(input: $input) {
    assetEventId
    assetId
    assetOperation {
      assetOperationId
      isVisible
      name
      operationType
      description
      externalId
      metadata {
        key
        value
        __typename
      }
      updatedAt
      __typename
    }
    eventTimestamp
    locationId
    metadata {
      key
      value
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAssetEventMutationVariables,
  APITypes.DeleteAssetEventMutation
>;
export const createManualInventoryAdjustment = /* GraphQL */ `mutation CreateManualInventoryAdjustment(
  $input: ManualInventoryAdjustmentInput!
) {
  createManualInventoryAdjustment(input: $input) {
    adjustmentId
    assetTypeId
    locationId
    reason
    adjustByAmount
    adjustToAmount
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateManualInventoryAdjustmentMutationVariables,
  APITypes.CreateManualInventoryAdjustmentMutation
>;
export const createScanAppGroupUser = /* GraphQL */ `mutation CreateScanAppGroupUser($input: CreateScanAppGroupUserInput!) {
  createScanAppGroupUser(input: $input) {
    userId
    username
    accountType
    allLocationsEnabled
    enabledLocationIds
    selectedLocationId
    canChangeResolutions
    selectedResolutionIds
    lastActivity
    createdAt
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateScanAppGroupUserMutationVariables,
  APITypes.CreateScanAppGroupUserMutation
>;
export const createScanAppIndividualUser = /* GraphQL */ `mutation CreateScanAppIndividualUser(
  $input: CreateScanAppIndividualUserInput!
) {
  createScanAppIndividualUser(input: $input) {
    userId
    username
    accountType
    allLocationsEnabled
    enabledLocationIds
    selectedLocationId
    canChangeResolutions
    selectedResolutionIds
    lastActivity
    createdAt
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateScanAppIndividualUserMutationVariables,
  APITypes.CreateScanAppIndividualUserMutation
>;
export const updateScanAppGroupUserPassword = /* GraphQL */ `mutation UpdateScanAppGroupUserPassword(
  $input: UpdateScanAppGroupUserPasswordInput!
) {
  updateScanAppGroupUserPassword(input: $input) {
    userId
    username
    accountType
    allLocationsEnabled
    enabledLocationIds
    selectedLocationId
    canChangeResolutions
    selectedResolutionIds
    lastActivity
    createdAt
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateScanAppGroupUserPasswordMutationVariables,
  APITypes.UpdateScanAppGroupUserPasswordMutation
>;
export const updateScanAppUserSettings = /* GraphQL */ `mutation UpdateScanAppUserSettings($input: UpdateScanAppUserSettingsInput!) {
  updateScanAppUserSettings(input: $input) {
    userId
    username
    accountType
    allLocationsEnabled
    enabledLocationIds
    selectedLocationId
    canChangeResolutions
    selectedResolutionIds
    lastActivity
    createdAt
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateScanAppUserSettingsMutationVariables,
  APITypes.UpdateScanAppUserSettingsMutation
>;
export const deleteScanAppUser = /* GraphQL */ `mutation DeleteScanAppUser($input: DeleteScanAppUserInput!) {
  deleteScanAppUser(input: $input) {
    userId
    username
    accountType
    allLocationsEnabled
    enabledLocationIds
    selectedLocationId
    canChangeResolutions
    selectedResolutionIds
    lastActivity
    createdAt
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteScanAppUserMutationVariables,
  APITypes.DeleteScanAppUserMutation
>;
export const createCharge = /* GraphQL */ `mutation CreateCharge($input: CreateChargeInput!) {
  createCharge(input: $input) {
    chargeId
    consumerId
    externalId
    chargeAmount
    chargeInfo
    chargeStatus
    failedReason {
      code
      message
      __typename
    }
    loopId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateChargeMutationVariables,
  APITypes.CreateChargeMutation
>;
export const updateChargeOutcome = /* GraphQL */ `mutation UpdateChargeOutcome($input: UpdateChargeOutcomeInput!) {
  updateChargeOutcome(input: $input) {
    chargeId
    consumerId
    externalId
    chargeAmount
    chargeInfo
    chargeStatus
    failedReason {
      code
      message
      __typename
    }
    loopId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateChargeOutcomeMutationVariables,
  APITypes.UpdateChargeOutcomeMutation
>;
export const createPhaseChangeRentalStepConfig = /* GraphQL */ `mutation CreatePhaseChangeRentalStepConfig(
  $input: CreatePhaseChangeRentalStepConfigInput!
) {
  createPhaseChangeRentalStepConfig(input: $input) {
    id
    name
    description
    rentalPhase
    timing {
      timestamp
      duration
      roundToTime
      roundToTimezone
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePhaseChangeRentalStepConfigMutationVariables,
  APITypes.CreatePhaseChangeRentalStepConfigMutation
>;
export const createNotificationRentalStepConfig = /* GraphQL */ `mutation CreateNotificationRentalStepConfig(
  $input: CreateNotificationRentalStepConfigInput!
) {
  createNotificationRentalStepConfig(input: $input) {
    id
    name
    description
    rentalPhase
    timing {
      timestamp
      duration
      roundToTime
      roundToTimezone
      __typename
    }
    createdAt
    updatedAt
    notificationType
    notificationData
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNotificationRentalStepConfigMutationVariables,
  APITypes.CreateNotificationRentalStepConfigMutation
>;
export const createChargeRentalStepConfig = /* GraphQL */ `mutation CreateChargeRentalStepConfig(
  $input: CreateChargeRentalStepConfigInput!
) {
  createChargeRentalStepConfig(input: $input) {
    id
    name
    description
    rentalPhase
    timing {
      timestamp
      duration
      roundToTime
      roundToTimezone
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateChargeRentalStepConfigMutationVariables,
  APITypes.CreateChargeRentalStepConfigMutation
>;
export const updatePhaseChangeRentalStepConfig = /* GraphQL */ `mutation UpdatePhaseChangeRentalStepConfig(
  $input: UpdatePhaseChangeRentalStepConfigInput!
) {
  updatePhaseChangeRentalStepConfig(input: $input) {
    id
    name
    description
    rentalPhase
    timing {
      timestamp
      duration
      roundToTime
      roundToTimezone
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePhaseChangeRentalStepConfigMutationVariables,
  APITypes.UpdatePhaseChangeRentalStepConfigMutation
>;
export const updateNotificationRentalStepConfig = /* GraphQL */ `mutation UpdateNotificationRentalStepConfig(
  $input: UpdateNotificationRentalStepConfigInput!
) {
  updateNotificationRentalStepConfig(input: $input) {
    id
    name
    description
    rentalPhase
    timing {
      timestamp
      duration
      roundToTime
      roundToTimezone
      __typename
    }
    createdAt
    updatedAt
    notificationType
    notificationData
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateNotificationRentalStepConfigMutationVariables,
  APITypes.UpdateNotificationRentalStepConfigMutation
>;
export const updateChargeRentalStepConfig = /* GraphQL */ `mutation UpdateChargeRentalStepConfig(
  $input: UpdateChargeRentalStepConfigInput!
) {
  updateChargeRentalStepConfig(input: $input) {
    id
    name
    description
    rentalPhase
    timing {
      timestamp
      duration
      roundToTime
      roundToTimezone
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateChargeRentalStepConfigMutationVariables,
  APITypes.UpdateChargeRentalStepConfigMutation
>;
export const deleteRentalStepConfig = /* GraphQL */ `mutation DeleteRentalStepConfig($input: DeleteRentalStepConfigInput!) {
  deleteRentalStepConfig(input: $input) {
    id
    name
    description
    rentalPhase
    timing {
      timestamp
      duration
      roundToTime
      roundToTimezone
      __typename
    }
    createdAt
    updatedAt

    ... on NotificationRentalStepConfig {
      notificationType
      notificationData
    }
  }
}
` as GeneratedMutation<
  APITypes.DeleteRentalStepConfigMutationVariables,
  APITypes.DeleteRentalStepConfigMutation
>;
export const createRentalConfig = /* GraphQL */ `mutation CreateRentalConfig($input: CreateRentalConfigInput!) {
  createRentalConfig(input: $input) {
    rentalConfigId
    version
    name
    description
    resolutionOption
    likeAssetTypeIds
    rentalStepConfigIds
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRentalConfigMutationVariables,
  APITypes.CreateRentalConfigMutation
>;
export const updateRentalConfig = /* GraphQL */ `mutation UpdateRentalConfig($input: UpdateRentalConfigInput!) {
  updateRentalConfig(input: $input) {
    rentalConfigId
    version
    name
    description
    resolutionOption
    likeAssetTypeIds
    rentalStepConfigIds
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRentalConfigMutationVariables,
  APITypes.UpdateRentalConfigMutation
>;
export const deleteRentalConfig = /* GraphQL */ `mutation DeleteRentalConfig($input: DeleteRentalConfigInput!) {
  deleteRentalConfig(input: $input) {
    rentalConfigId
    version
    name
    description
    resolutionOption
    likeAssetTypeIds
    rentalStepConfigIds
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRentalConfigMutationVariables,
  APITypes.DeleteRentalConfigMutation
>;
export const createRental = /* GraphQL */ `mutation CreateRental($input: CreateRentalInput!) {
  createRental(input: $input) {
    rentalId
    rentalConfigId
    rentalConfigVersion
    loopId
    consumerId
    lastPhase
    status
    rentalStepIds
    rentedAssetId
    rentedAssetTypeName
    resolvingLoop {
      loopId
      asset {
        assetId
        externalId
        value
        assetType {
          assetTypeId
          name
          description
          externalId
          value
          metadata {
            key
            value
            __typename
          }
          __typename
        }
        metadata {
          key
          value
          __typename
        }
        __typename
      }
      consumer {
        consumerId
        reuserId
        name
        phoneNumber
        email
        externalId
        metadata {
          key
          value
          __typename
        }
        marketingOptIn {
          email
          phoneNumber
          __typename
        }
        createdAt
        chargeables {
          chargeableId
          vendor
          __typename
        }
        __typename
      }
      locations {
        openedAt {
          locationId
          name
          parentId
          fullName
          externalId
          address {
            line1
            line2
            line3
            postalCode
            country
            latitude
            longitude
            googlePlacesId
            __typename
          }
          metadata {
            key
            value
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        closedAt {
          locationId
          name
          parentId
          fullName
          externalId
          address {
            line1
            line2
            line3
            postalCode
            country
            latitude
            longitude
            googlePlacesId
            __typename
          }
          metadata {
            key
            value
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      externalId
      openedAt
      closedAt
      loopStatus
      resolutions {
        loopId
        resolution {
          resolutionId
          type {
            likeAssetTypeResolution {
              likeAssetTypeIds
              resolutionTiming {
                timestamp
                duration
                roundToTime
                roundToTimezone
                __typename
              }
              __typename
            }
            sameAssetResolution {
              resolutionTiming {
                timestamp
                duration
                roundToTime
                roundToTimezone
                __typename
              }
              __typename
            }
            sameAssetTypeResolution {
              resolutionTiming {
                timestamp
                duration
                roundToTime
                roundToTimezone
                __typename
              }
              __typename
            }
            __typename
          }
          label
          useAgain
          name
          description
          metadata {
            key
            value
            __typename
          }
          __typename
        }
        resolutionStatus
        triggerResolutionAt
        __typename
      }
      updatedAt
      metadata {
        key
        value
        __typename
      }
      rentalConfig {
        rentalConfigId
        version
        name
        description
        resolutionOption
        likeAssetTypeIds
        rentalStepConfigIds
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    resolvingLoopId
    receivedAssetId
    receivedAssetTypeName
    createdAt
    updatedAt
    metadata {
      key
      value
      __typename
    }
    forgivenessDetails {
      timestamp
      reason
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRentalMutationVariables,
  APITypes.CreateRentalMutation
>;
export const updateRentalPhase = /* GraphQL */ `mutation UpdateRentalPhase($input: UpdateRentalPhaseInput!) {
  updateRentalPhase(input: $input) {
    rentalId
    rentalConfigId
    rentalConfigVersion
    loopId
    consumerId
    lastPhase
    status
    rentalStepIds
    rentedAssetId
    rentedAssetTypeName
    resolvingLoop {
      loopId
      asset {
        assetId
        externalId
        value
        assetType {
          assetTypeId
          name
          description
          externalId
          value
          metadata {
            key
            value
            __typename
          }
          __typename
        }
        metadata {
          key
          value
          __typename
        }
        __typename
      }
      consumer {
        consumerId
        reuserId
        name
        phoneNumber
        email
        externalId
        metadata {
          key
          value
          __typename
        }
        marketingOptIn {
          email
          phoneNumber
          __typename
        }
        createdAt
        chargeables {
          chargeableId
          vendor
          __typename
        }
        __typename
      }
      locations {
        openedAt {
          locationId
          name
          parentId
          fullName
          externalId
          address {
            line1
            line2
            line3
            postalCode
            country
            latitude
            longitude
            googlePlacesId
            __typename
          }
          metadata {
            key
            value
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        closedAt {
          locationId
          name
          parentId
          fullName
          externalId
          address {
            line1
            line2
            line3
            postalCode
            country
            latitude
            longitude
            googlePlacesId
            __typename
          }
          metadata {
            key
            value
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      externalId
      openedAt
      closedAt
      loopStatus
      resolutions {
        loopId
        resolution {
          resolutionId
          type {
            likeAssetTypeResolution {
              likeAssetTypeIds
              resolutionTiming {
                timestamp
                duration
                roundToTime
                roundToTimezone
                __typename
              }
              __typename
            }
            sameAssetResolution {
              resolutionTiming {
                timestamp
                duration
                roundToTime
                roundToTimezone
                __typename
              }
              __typename
            }
            sameAssetTypeResolution {
              resolutionTiming {
                timestamp
                duration
                roundToTime
                roundToTimezone
                __typename
              }
              __typename
            }
            __typename
          }
          label
          useAgain
          name
          description
          metadata {
            key
            value
            __typename
          }
          __typename
        }
        resolutionStatus
        triggerResolutionAt
        __typename
      }
      updatedAt
      metadata {
        key
        value
        __typename
      }
      rentalConfig {
        rentalConfigId
        version
        name
        description
        resolutionOption
        likeAssetTypeIds
        rentalStepConfigIds
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    resolvingLoopId
    receivedAssetId
    receivedAssetTypeName
    createdAt
    updatedAt
    metadata {
      key
      value
      __typename
    }
    forgivenessDetails {
      timestamp
      reason
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRentalPhaseMutationVariables,
  APITypes.UpdateRentalPhaseMutation
>;
export const updateRentalStatus = /* GraphQL */ `mutation UpdateRentalStatus($input: UpdateRentalStatusInput!) {
  updateRentalStatus(input: $input) {
    rentalId
    rentalConfigId
    rentalConfigVersion
    loopId
    consumerId
    lastPhase
    status
    rentalStepIds
    rentedAssetId
    rentedAssetTypeName
    resolvingLoop {
      loopId
      asset {
        assetId
        externalId
        value
        assetType {
          assetTypeId
          name
          description
          externalId
          value
          metadata {
            key
            value
            __typename
          }
          __typename
        }
        metadata {
          key
          value
          __typename
        }
        __typename
      }
      consumer {
        consumerId
        reuserId
        name
        phoneNumber
        email
        externalId
        metadata {
          key
          value
          __typename
        }
        marketingOptIn {
          email
          phoneNumber
          __typename
        }
        createdAt
        chargeables {
          chargeableId
          vendor
          __typename
        }
        __typename
      }
      locations {
        openedAt {
          locationId
          name
          parentId
          fullName
          externalId
          address {
            line1
            line2
            line3
            postalCode
            country
            latitude
            longitude
            googlePlacesId
            __typename
          }
          metadata {
            key
            value
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        closedAt {
          locationId
          name
          parentId
          fullName
          externalId
          address {
            line1
            line2
            line3
            postalCode
            country
            latitude
            longitude
            googlePlacesId
            __typename
          }
          metadata {
            key
            value
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      externalId
      openedAt
      closedAt
      loopStatus
      resolutions {
        loopId
        resolution {
          resolutionId
          type {
            likeAssetTypeResolution {
              likeAssetTypeIds
              resolutionTiming {
                timestamp
                duration
                roundToTime
                roundToTimezone
                __typename
              }
              __typename
            }
            sameAssetResolution {
              resolutionTiming {
                timestamp
                duration
                roundToTime
                roundToTimezone
                __typename
              }
              __typename
            }
            sameAssetTypeResolution {
              resolutionTiming {
                timestamp
                duration
                roundToTime
                roundToTimezone
                __typename
              }
              __typename
            }
            __typename
          }
          label
          useAgain
          name
          description
          metadata {
            key
            value
            __typename
          }
          __typename
        }
        resolutionStatus
        triggerResolutionAt
        __typename
      }
      updatedAt
      metadata {
        key
        value
        __typename
      }
      rentalConfig {
        rentalConfigId
        version
        name
        description
        resolutionOption
        likeAssetTypeIds
        rentalStepConfigIds
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    resolvingLoopId
    receivedAssetId
    receivedAssetTypeName
    createdAt
    updatedAt
    metadata {
      key
      value
      __typename
    }
    forgivenessDetails {
      timestamp
      reason
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRentalStatusMutationVariables,
  APITypes.UpdateRentalStatusMutation
>;
export const completeRental = /* GraphQL */ `mutation CompleteRental($input: CompleteRentalInput!) {
  completeRental(input: $input) {
    rentalId
    rentalConfigId
    rentalConfigVersion
    loopId
    consumerId
    lastPhase
    status
    rentalStepIds
    rentedAssetId
    rentedAssetTypeName
    resolvingLoop {
      loopId
      asset {
        assetId
        externalId
        value
        assetType {
          assetTypeId
          name
          description
          externalId
          value
          metadata {
            key
            value
            __typename
          }
          __typename
        }
        metadata {
          key
          value
          __typename
        }
        __typename
      }
      consumer {
        consumerId
        reuserId
        name
        phoneNumber
        email
        externalId
        metadata {
          key
          value
          __typename
        }
        marketingOptIn {
          email
          phoneNumber
          __typename
        }
        createdAt
        chargeables {
          chargeableId
          vendor
          __typename
        }
        __typename
      }
      locations {
        openedAt {
          locationId
          name
          parentId
          fullName
          externalId
          address {
            line1
            line2
            line3
            postalCode
            country
            latitude
            longitude
            googlePlacesId
            __typename
          }
          metadata {
            key
            value
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        closedAt {
          locationId
          name
          parentId
          fullName
          externalId
          address {
            line1
            line2
            line3
            postalCode
            country
            latitude
            longitude
            googlePlacesId
            __typename
          }
          metadata {
            key
            value
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      externalId
      openedAt
      closedAt
      loopStatus
      resolutions {
        loopId
        resolution {
          resolutionId
          type {
            likeAssetTypeResolution {
              likeAssetTypeIds
              resolutionTiming {
                timestamp
                duration
                roundToTime
                roundToTimezone
                __typename
              }
              __typename
            }
            sameAssetResolution {
              resolutionTiming {
                timestamp
                duration
                roundToTime
                roundToTimezone
                __typename
              }
              __typename
            }
            sameAssetTypeResolution {
              resolutionTiming {
                timestamp
                duration
                roundToTime
                roundToTimezone
                __typename
              }
              __typename
            }
            __typename
          }
          label
          useAgain
          name
          description
          metadata {
            key
            value
            __typename
          }
          __typename
        }
        resolutionStatus
        triggerResolutionAt
        __typename
      }
      updatedAt
      metadata {
        key
        value
        __typename
      }
      rentalConfig {
        rentalConfigId
        version
        name
        description
        resolutionOption
        likeAssetTypeIds
        rentalStepConfigIds
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    resolvingLoopId
    receivedAssetId
    receivedAssetTypeName
    createdAt
    updatedAt
    metadata {
      key
      value
      __typename
    }
    forgivenessDetails {
      timestamp
      reason
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CompleteRentalMutationVariables,
  APITypes.CompleteRentalMutation
>;
export const adminCancelRental = /* GraphQL */ `mutation AdminCancelRental($input: AdminCancelRentalInput!) {
  adminCancelRental(input: $input) {
    rentalId
    rentalConfigId
    rentalConfigVersion
    loopId
    consumerId
    lastPhase
    status
    rentalStepIds
    rentedAssetId
    rentedAssetTypeName
    resolvingLoop {
      loopId
      asset {
        assetId
        externalId
        value
        assetType {
          assetTypeId
          name
          description
          externalId
          value
          metadata {
            key
            value
            __typename
          }
          __typename
        }
        metadata {
          key
          value
          __typename
        }
        __typename
      }
      consumer {
        consumerId
        reuserId
        name
        phoneNumber
        email
        externalId
        metadata {
          key
          value
          __typename
        }
        marketingOptIn {
          email
          phoneNumber
          __typename
        }
        createdAt
        chargeables {
          chargeableId
          vendor
          __typename
        }
        __typename
      }
      locations {
        openedAt {
          locationId
          name
          parentId
          fullName
          externalId
          address {
            line1
            line2
            line3
            postalCode
            country
            latitude
            longitude
            googlePlacesId
            __typename
          }
          metadata {
            key
            value
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        closedAt {
          locationId
          name
          parentId
          fullName
          externalId
          address {
            line1
            line2
            line3
            postalCode
            country
            latitude
            longitude
            googlePlacesId
            __typename
          }
          metadata {
            key
            value
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      externalId
      openedAt
      closedAt
      loopStatus
      resolutions {
        loopId
        resolution {
          resolutionId
          type {
            likeAssetTypeResolution {
              likeAssetTypeIds
              resolutionTiming {
                timestamp
                duration
                roundToTime
                roundToTimezone
                __typename
              }
              __typename
            }
            sameAssetResolution {
              resolutionTiming {
                timestamp
                duration
                roundToTime
                roundToTimezone
                __typename
              }
              __typename
            }
            sameAssetTypeResolution {
              resolutionTiming {
                timestamp
                duration
                roundToTime
                roundToTimezone
                __typename
              }
              __typename
            }
            __typename
          }
          label
          useAgain
          name
          description
          metadata {
            key
            value
            __typename
          }
          __typename
        }
        resolutionStatus
        triggerResolutionAt
        __typename
      }
      updatedAt
      metadata {
        key
        value
        __typename
      }
      rentalConfig {
        rentalConfigId
        version
        name
        description
        resolutionOption
        likeAssetTypeIds
        rentalStepConfigIds
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    resolvingLoopId
    receivedAssetId
    receivedAssetTypeName
    createdAt
    updatedAt
    metadata {
      key
      value
      __typename
    }
    forgivenessDetails {
      timestamp
      reason
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AdminCancelRentalMutationVariables,
  APITypes.AdminCancelRentalMutation
>;
export const createRentalStep = /* GraphQL */ `mutation CreateRentalStep($input: CreateRentalStepInput!) {
  createRentalStep(input: $input) {
    id
    rentalId
    rentalStepConfigId
    rentalPhase
    triggerAt
    status
    statusUpdatedAt
    createdAt
    rentalStepConfigType
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRentalStepMutationVariables,
  APITypes.CreateRentalStepMutation
>;
export const createExport = /* GraphQL */ `mutation CreateExport(
  $dryRun: Boolean
  $email: String
  $fromTimestamp: String
  $toTimestamp: String
  $size: Int
) {
  createExport(
    dryRun: $dryRun
    email: $email
    fromTimestamp: $fromTimestamp
    toTimestamp: $toTimestamp
    size: $size
  ) {
    message
    exportId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateExportMutationVariables,
  APITypes.CreateExportMutation
>;
export const deleteExport = /* GraphQL */ `mutation DeleteExport($exportId: ID!) {
  deleteExport(exportId: $exportId) {
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteExportMutationVariables,
  APITypes.DeleteExportMutation
>;
