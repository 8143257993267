//react
import { useRef, useState } from "react";

// style
import styled from "../../../styling/styled-components";

//components
import Button from "../../common/Button";
import FilterButton from "../../common/FilterButton";
import FlyoutForm from "../../common/dropdown/FlyoutForm";
import OptionsContainer from "../../common/dropdown/OptionsContainer";
import RadioButtons from "../../common/dropdown/RadioButtons";
import FormChildrenWrapper from "../../common/dropdown/FormChildrenWrapper";

// utils
import useClickOutside from "../../../utils/useClickOutside";

const ASSET_RECEIVED_OPTIONS = [
    { value: "true", label: "Return received", selected: false },
    { value: "false", label: "Not yet received", selected: false },
];

const STRINGS = {
    BUTTON_LABEL: "Return received?",
};

const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const FilterWrapper = styled.div``;

const FlyoutWrapper = styled.div`
    margin-top: ${({ theme }) => theme.spacing.small}px;
    position: absolute;
    z-index: 10000;
`;

const RadioForm = styled.div`
    width: 198px;
`;

// TODO: this should use the common Filter component
export default function AssetReceivedFilter({ value, onSubmit }: Props) {
    const [selectedValue, setSelectedValue] = useState("");
    const [disableButton, setDisableButton] = useState(true);
    const [showOptions, setShowOptions] = useState(false);
    const confirmButtonStatus = !disableButton ? "default" : "disabled";

    //refs click outside
    const dropdownRef = useRef("asset-received-dropdown-button");
    const flyoutRef = useRef("asset-received-flyout");

    useClickOutside(
        flyoutRef,
        () => {
            setShowOptions(false);
        },
        [dropdownRef]
    );

    const changeSelectionAndEnableButton = (event) => {
        event.preventDefault();
        const radioResult = event.target.value;
        setDisableButton(false);
        setSelectedValue(radioResult);
    };

    const handleClear = () => {
        onSubmit("");
        setSelectedValue("");
        closeDropdown();
    };

    const submitAssetReceivedFilter = (event) => {
        event.preventDefault();
        onSubmit(selectedValue);
        closeDropdown();
    };

    const closeDropdown = () => {
        setShowOptions(false);
        setDisableButton(true);
    };

    const toggleDisplay = () => {
        setShowOptions(!showOptions);
        setSelectedValue(value);
    };

    const DropdownOptions = () => {
        return (
            <>
                {showOptions && (
                    <FlyoutWrapper ref={flyoutRef}>
                        <FlyoutForm onSubmit={submitAssetReceivedFilter}>
                            <FormChildrenWrapper>
                                <RadioForm>
                                    <OptionsContainer>
                                        <RadioButtons
                                            options={ASSET_RECEIVED_OPTIONS}
                                            onChange={
                                                changeSelectionAndEnableButton
                                            }
                                            selectedOption={selectedValue}
                                        />
                                        <ButtonsWrapper>
                                            <Button
                                                status="default"
                                                size="small"
                                                type="secondary"
                                                onClick={handleClear}
                                                label="Clear"
                                            />
                                            <Button
                                                status={confirmButtonStatus}
                                                size="small"
                                                type="primary"
                                                onClick={
                                                    submitAssetReceivedFilter
                                                }
                                                label="Save"
                                            />
                                        </ButtonsWrapper>
                                    </OptionsContainer>
                                </RadioForm>
                            </FormChildrenWrapper>
                        </FlyoutForm>
                    </FlyoutWrapper>
                )}
            </>
        );
    };

    return (
        <FilterWrapper>
            <div ref={dropdownRef}>
                <FilterButton onClick={toggleDisplay} isOpen={showOptions}>
                    {STRINGS.BUTTON_LABEL}
                </FilterButton>
            </div>
            <DropdownOptions />
        </FilterWrapper>
    );
}
