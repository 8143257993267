export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type ActiveConsumerCount = {
  __typename?: 'ActiveConsumerCount';
  count?: Maybe<Scalars['Int']>;
  timestamp?: Maybe<Scalars['String']>;
};

export type ActiveConsumerCountResults = {
  __typename?: 'ActiveConsumerCountResults';
  results?: Maybe<Array<Maybe<ActiveConsumerCount>>>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type AddAudioInput = {
  audioPath: Scalars['String'];
  transcribedBy?: InputMaybe<Scalars['String']>;
  transcription?: InputMaybe<Scalars['String']>;
};

export type AddHumanLabelSessionInput = {
  containerId?: InputMaybe<Scalars['ID']>;
  isFlagged: Scalars['Boolean'];
  labeler?: InputMaybe<Scalars['String']>;
  menuId?: InputMaybe<Scalars['ID']>;
  notes?: InputMaybe<Scalars['String']>;
  observationId: Scalars['ID'];
  recognizableItemId?: InputMaybe<Scalars['ID']>;
  recognizableItemType?: InputMaybe<RecognizableItemType>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AddImageInput = {
  imgPath: Scalars['String'];
  /**   TODO: update this for client upload. */
  metadata?: InputMaybe<Scalars['String']>;
  observationId: Scalars['ID'];
};

export type AddMachineLabelSessionInput = {
  description?: InputMaybe<Scalars['String']>;
  menuId?: InputMaybe<Scalars['ID']>;
  modelMetadata?: InputMaybe<Scalars['String']>;
  modelVersion?: InputMaybe<Scalars['String']>;
  observationId: Scalars['ID'];
  rationale?: InputMaybe<Scalars['String']>;
  recognizableItemId?: InputMaybe<Scalars['ID']>;
  recognizableItemType?: InputMaybe<RecognizableItemType>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Address = {
  __typename?: 'Address';
  country?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  line3?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  country?: InputMaybe<Scalars['String']>;
  googlePlacesId?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['String']>;
  line1?: InputMaybe<Scalars['String']>;
  line2?: InputMaybe<Scalars['String']>;
  line3?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
};

export type AdminCancelRentalInput = {
  canceledBy?: InputMaybe<Scalars['String']>;
  canceledReason?: InputMaybe<Scalars['String']>;
  rentalId: Scalars['ID'];
};

export type ArchiveLocationInput = {
  locationId: Scalars['ID'];
};

export type ArchiveParentLocationInput = {
  locationId: Scalars['ID'];
};

export type Asset = {
  __typename?: 'Asset';
  assetId: Scalars['String'];
  assetType?: Maybe<AssetType>;
  externalId?: Maybe<Scalars['ID']>;
  metadata?: Maybe<Array<Maybe<KeyValue>>>;
  value?: Maybe<Scalars['Int']>;
};

export type AssetEvent = {
  __typename?: 'AssetEvent';
  assetEventId: Scalars['ID'];
  assetId: Scalars['ID'];
  assetOperation?: Maybe<AssetOperation>;
  eventTimestamp: Scalars['String'];
  locationId: Scalars['ID'];
  metadata?: Maybe<Array<Maybe<KeyValue>>>;
  updatedAt: Scalars['String'];
};

export type AssetEventSearchDocument = {
  __typename?: 'AssetEventSearchDocument';
  asset: Asset;
  assetEventId: Scalars['ID'];
  assetOperation: AssetOperation;
  assetOperationType: AssetOperationType;
  consumer?: Maybe<Consumer>;
  eventTimestamp: Scalars['String'];
  location: Location;
  loopStatus?: Maybe<LoopStatus>;
  metadata?: Maybe<KeyValue>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type AssetEventSearchResults = {
  __typename?: 'AssetEventSearchResults';
  assetEvents?: Maybe<Array<AssetEventSearchDocument>>;
  hitAggregations?: Maybe<Array<SearchHitTotals>>;
  totalHits: Scalars['Int'];
};

export type AssetEventsPaginated = {
  __typename?: 'AssetEventsPaginated';
  nextToken?: Maybe<Scalars['String']>;
  results?: Maybe<Array<Maybe<AssetEvent>>>;
};

export type AssetInput = {
  asset?: InputMaybe<CreateAssetInput>;
  assetId?: InputMaybe<Scalars['String']>;
};

export type AssetLocation = {
  __typename?: 'AssetLocation';
  assetId: Scalars['ID'];
  lastAssetEventId: Scalars['ID'];
  lastEventTimestamp: Scalars['String'];
  locationId: Scalars['ID'];
  previousLocationId?: Maybe<Scalars['ID']>;
  updatedAt: Scalars['String'];
};

export type AssetOperation = {
  __typename?: 'AssetOperation';
  assetOperationId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<Array<Maybe<KeyValue>>>;
  name: Scalars['String'];
  operationType: AssetOperationType;
  updatedAt?: Maybe<Scalars['String']>;
};

export enum AssetOperationType {
  Clean = 'clean',
  CloseLoop = 'closeLoop',
  CreateLoop = 'createLoop',
  Fill = 'fill',
  Repair = 'repair',
  Retire = 'retire',
  Special = 'special',
  Stock = 'stock'
}

export type AssetOperationsPaginated = {
  __typename?: 'AssetOperationsPaginated';
  nextToken?: Maybe<Scalars['String']>;
  results?: Maybe<Array<Maybe<AssetOperation>>>;
};

export type AssetType = {
  __typename?: 'AssetType';
  assetTypeId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['ID']>;
  metadata?: Maybe<Array<Maybe<KeyValue>>>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};

export type AssetTypeInput = {
  assetType?: InputMaybe<CreateAssetTypeInput>;
  assetTypeId?: InputMaybe<Scalars['ID']>;
};

export type AssetTypeUtilization = {
  __typename?: 'AssetTypeUtilization';
  activeAssets?: Maybe<Scalars['Int']>;
  activeWindowDays?: Maybe<Scalars['Int']>;
  inactiveAssets?: Maybe<Scalars['Int']>;
  retiredAssets?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type AssetTypesPaginated = {
  __typename?: 'AssetTypesPaginated';
  nextToken?: Maybe<Scalars['String']>;
  results?: Maybe<Array<Maybe<AssetType>>>;
};

export type AssetUsageBucket = {
  __typename?: 'AssetUsageBucket';
  bucketCount: Scalars['Int'];
  label: AssetUsageLabel;
};

export enum AssetUsageLabel {
  Active = 'ACTIVE',
  Lost = 'LOST',
  NeverUsed = 'NEVER_USED',
  Retired = 'RETIRED'
}

export type AssetUsageStats = {
  __typename?: 'AssetUsageStats';
  assetTypeId?: Maybe<Scalars['ID']>;
  isAvailable: Scalars['Boolean'];
  lastUpdated?: Maybe<Scalars['String']>;
  usageBuckets?: Maybe<Array<Maybe<AssetUsageBucket>>>;
};

export type AssetsPaginated = {
  __typename?: 'AssetsPaginated';
  nextToken?: Maybe<Scalars['String']>;
  results?: Maybe<Array<Maybe<Asset>>>;
};

export type AudioObservation = {
  __typename?: 'AudioObservation';
  audioPath: Scalars['String'];
  observationId: Scalars['ID'];
  transcribedBy?: Maybe<Scalars['String']>;
  transcription?: Maybe<Scalars['String']>;
};

export type Charge = {
  __typename?: 'Charge';
  chargeAmount: Scalars['Int'];
  chargeId: Scalars['ID'];
  chargeInfo?: Maybe<Scalars['String']>;
  chargeStatus: ChargeStatus;
  consumerId: Scalars['ID'];
  createdAt?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['ID']>;
  failedReason?: Maybe<ChargeOutcomeError>;
  loopId?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export enum ChargeFailureCode {
  GeneralError = 'GENERAL_ERROR',
  InsufficientBalanceError = 'INSUFFICIENT_BALANCE_ERROR',
  UserCannotBeChargedError = 'USER_CANNOT_BE_CHARGED_ERROR',
  ValidationError = 'VALIDATION_ERROR'
}

export type ChargeOutcomeError = {
  __typename?: 'ChargeOutcomeError';
  code: ChargeFailureCode;
  message?: Maybe<Scalars['String']>;
};

export type ChargeOutcomeErrorInput = {
  code: ChargeFailureCode;
  message?: InputMaybe<Scalars['String']>;
};

export type ChargeRentalStepConfig = RentalStepConfig & {
  __typename?: 'ChargeRentalStepConfig';
  createdAt: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  rentalPhase: RentalPhase;
  timing: ResolutionTiming;
  updatedAt: Scalars['String'];
};

export enum ChargeStatus {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Refunded = 'REFUNDED',
  Success = 'SUCCESS'
}

export type Chargeable = {
  __typename?: 'Chargeable';
  chargeableId: Scalars['ID'];
  vendor: ChargeableVendor;
};

export type ChargeableInput = {
  chargeableId: Scalars['ID'];
  vendor: ChargeableVendor;
};

export enum ChargeableVendor {
  Transact = 'transact'
}

export type CheckoutsReturnsByDay = {
  __typename?: 'CheckoutsReturnsByDay';
  avgLoopsClosed?: Maybe<Scalars['Int']>;
  avgLoopsOpened?: Maybe<Scalars['Int']>;
  dayOfWeek: DayOfWeek;
  daysCount?: Maybe<Scalars['Int']>;
  totalClosed?: Maybe<Scalars['Int']>;
  totalOpened?: Maybe<Scalars['Int']>;
};

export type CheckoutsReturnsStats = {
  __typename?: 'CheckoutsReturnsStats';
  assetTypeId?: Maybe<Scalars['ID']>;
  avgByDay?: Maybe<Array<Maybe<CheckoutsReturnsByDay>>>;
  avgClosedForWindow?: Maybe<Scalars['Int']>;
  avgOpenedForWindow?: Maybe<Scalars['Int']>;
  isAvailable: Scalars['Boolean'];
  lastUpdated?: Maybe<Scalars['String']>;
  parentLocationIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  statWindow: StatWindow;
  totalClosedForWindow?: Maybe<Scalars['Int']>;
  totalOpenedForWindow?: Maybe<Scalars['Int']>;
};

export type CloseLoopInput = {
  assetId: Scalars['String'];
  closedAt?: InputMaybe<Scalars['String']>;
  closedAtLocationId: Scalars['ID'];
};

export type CompleteRentalInput = {
  rentalId: Scalars['ID'];
  resolvingLoopId: Scalars['ID'];
};

export type Consumer = {
  __typename?: 'Consumer';
  chargeables?: Maybe<Array<Maybe<Chargeable>>>;
  consumerId: Scalars['ID'];
  createdAt?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['ID']>;
  marketingOptIn?: Maybe<MarketingOptions>;
  metadata?: Maybe<Array<Maybe<KeyValue>>>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  reuserId?: Maybe<Scalars['ID']>;
};

export type ConsumerInput = {
  consumer?: InputMaybe<CreateConsumerInput>;
  consumerId?: InputMaybe<Scalars['ID']>;
};

export type ConsumerStats = {
  __typename?: 'ConsumerStats';
  consumerId: Scalars['ID'];
  openLoops: Scalars['Int'];
  totalClosedLoops: Scalars['Int'];
  totalOpenLoops: Scalars['Int'];
  updatedAt: Scalars['String'];
};

export type ConsumersPaginated = {
  __typename?: 'ConsumersPaginated';
  nextToken?: Maybe<Scalars['String']>;
  results?: Maybe<Array<Maybe<Consumer>>>;
};

export type Container = {
  __typename?: 'Container';
  id: Scalars['ID'];
  name: Scalars['String'];
  weight: WeightObservation;
};

export type CostPerUom = {
  __typename?: 'CostPerUOM';
  byCount?: Maybe<Scalars['Boolean']>;
  cost?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  numPortions?: Maybe<Scalars['Float']>;
  uom?: Maybe<Scalars['String']>;
  uomQty?: Maybe<Scalars['Float']>;
};

export type CostPerUomInput = {
  byCount?: InputMaybe<Scalars['Boolean']>;
  cost?: InputMaybe<Scalars['Int']>;
  currency?: InputMaybe<Currency>;
  numPortions?: InputMaybe<Scalars['Float']>;
  uom?: InputMaybe<Scalars['String']>;
  uomQty?: InputMaybe<Scalars['Float']>;
};

export type CreateAssetEventInput = {
  assetId: Scalars['ID'];
  assetOperationId: Scalars['ID'];
  eventTimestamp: Scalars['String'];
  locationId: Scalars['ID'];
  metadata?: InputMaybe<Array<InputMaybe<KeyValueInput>>>;
};

export type CreateAssetInput = {
  assetId: Scalars['String'];
  assetType?: InputMaybe<AssetTypeInput>;
  externalId?: InputMaybe<Scalars['ID']>;
  metadata?: InputMaybe<Array<InputMaybe<KeyValueInput>>>;
  value?: InputMaybe<Scalars['Int']>;
};

export type CreateAssetOperationInput = {
  description?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  isVisible?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Array<InputMaybe<KeyValueInput>>>;
  name: Scalars['String'];
  operationType: AssetOperationType;
};

export type CreateAssetTypeInput = {
  description?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['ID']>;
  metadata?: InputMaybe<Array<InputMaybe<KeyValueInput>>>;
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Int']>;
};

export type CreateChargeInput = {
  chargeAmount?: InputMaybe<Scalars['Int']>;
  loopId: Scalars['ID'];
};

export type CreateChargeRentalStepConfigInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  rentalPhase: RentalPhase;
  timing: ResolutionTimingInput;
};

export type CreateConsumerInput = {
  chargeables?: InputMaybe<Array<InputMaybe<ChargeableInput>>>;
  email?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['ID']>;
  marketingOptIn?: InputMaybe<MarketingOptionsInput>;
  metadata?: InputMaybe<Array<InputMaybe<KeyValueInput>>>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  reuserId?: InputMaybe<Scalars['String']>;
};

export type CreateHardwareUnitInput = {
  installedOn?: InputMaybe<Scalars['String']>;
  iotThingName: Scalars['String'];
  locationId: Scalars['ID'];
  organizationId: Scalars['ID'];
};

export type CreateIngredientInput = {
  costPerUnit?: InputMaybe<CostPerUomInput>;
  externalId?: InputMaybe<Scalars['ID']>;
  metadata?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  source?: InputMaybe<MenuDataSource>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CreateLocationInput = {
  address?: InputMaybe<AddressInput>;
  externalId?: InputMaybe<Scalars['ID']>;
  metadata?: InputMaybe<Array<InputMaybe<KeyValueInput>>>;
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['ID']>;
};

export type CreateLoopInput = {
  asset: AssetInput;
  consumer: ConsumerInput;
  externalId?: InputMaybe<Scalars['ID']>;
  metadata?: InputMaybe<Array<InputMaybe<KeyValueInput>>>;
  openedAt?: InputMaybe<Scalars['String']>;
  openedAtLocationId: Scalars['ID'];
  rentalConfigId?: InputMaybe<Scalars['ID']>;
  rentalConfigVersion?: InputMaybe<Scalars['String']>;
  resolutions?: InputMaybe<Array<InputMaybe<ResolutionInput>>>;
};

export type CreateLoopResolutionInput = {
  loopId: Scalars['ID'];
  resolution: ResolutionInput;
};

export type CreateMenuInput = {
  externalId?: InputMaybe<Scalars['ID']>;
  ings?: InputMaybe<Array<MenuOptionInput>>;
  items?: InputMaybe<Array<MenuOptionInput>>;
  locationId: Scalars['ID'];
  menuDate: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  source: MenuDataSource;
  stationId?: InputMaybe<Scalars['ID']>;
};

export type CreateMenuItemInput = {
  costPerUnit?: InputMaybe<CostPerUomInput>;
  externalId?: InputMaybe<Scalars['ID']>;
  metadata?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  recipe?: InputMaybe<Array<InputMaybe<RecipeItemInput>>>;
  source?: InputMaybe<MenuDataSource>;
  tags?: InputMaybe<Array<Scalars['String']>>;
};

export type CreateNotificationRentalStepConfigInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  notificationData?: InputMaybe<Scalars['String']>;
  notificationType: RentalNotificationType;
  rentalPhase: RentalPhase;
  timing: ResolutionTimingInput;
};

export type CreateParentLocationInput = {
  address?: InputMaybe<AddressInput>;
  externalId?: InputMaybe<Scalars['ID']>;
  metadata?: InputMaybe<Array<InputMaybe<KeyValueInput>>>;
  name: Scalars['String'];
};

export type CreatePhaseChangeRentalStepConfigInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  rentalPhase: RentalPhase;
  timing: ResolutionTimingInput;
};

export type CreateRentalConfigInput = {
  description?: InputMaybe<Scalars['String']>;
  likeAssetTypeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name: Scalars['String'];
  rentalStepConfigIds?: InputMaybe<Array<Scalars['ID']>>;
  resolutionOption: ResolutionOption;
};

export type CreateRentalInput = {
  loopId: Scalars['ID'];
  rentalConfigId: Scalars['ID'];
  rentalConfigVersion?: InputMaybe<Scalars['String']>;
};

export type CreateRentalStepInput = {
  rentalId: Scalars['ID'];
  rentalStepConfigId: Scalars['ID'];
};

export type CreateResolutionInput = {
  description?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<ResolutionLabel>;
  metadata?: InputMaybe<Array<InputMaybe<KeyValueInput>>>;
  name?: InputMaybe<Scalars['String']>;
  type: ResolutionOptionsInput;
  useAgain?: InputMaybe<Scalars['Boolean']>;
};

export type CreateScanAppGroupUserInput = {
  password: Scalars['String'];
  settings: CreateScanAppUserSettingsInput;
  username: Scalars['ID'];
};

export type CreateScanAppIndividualUserInput = {
  email: Scalars['String'];
  settings: CreateScanAppUserSettingsInput;
};

export type CreateScanAppUserSettingsInput = {
  allLocationsEnabled?: InputMaybe<Scalars['Boolean']>;
  canChangeResolutions?: InputMaybe<Scalars['Boolean']>;
  enabledLocationIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  selectedLocationId?: InputMaybe<Scalars['ID']>;
  selectedResolutionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type CreateWebhookInput = {
  endpoint: Scalars['String'];
  event: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  secretToken: Scalars['String'];
};

/**
 *   Values should always be smallest unit of currency
 *  e.g. for USD, cents. 100 = $1
 */
export enum Currency {
  Usd = 'USD'
}

export type CycleRateStats = {
  __typename?: 'CycleRateStats';
  assetTypeId?: Maybe<Scalars['ID']>;
  cycleRateTotal?: Maybe<Scalars['Float']>;
  cycleRateUnit?: Maybe<Scalars['String']>;
  isAvailable: Scalars['Boolean'];
  lastUpdated?: Maybe<Scalars['String']>;
  loopDuration?: Maybe<Scalars['Float']>;
  loopDurationUnit?: Maybe<Scalars['String']>;
  shelfTime?: Maybe<Scalars['Float']>;
  shelfTimeUnit?: Maybe<Scalars['String']>;
};

export enum DayOfWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export type DeleteAssetEventInput = {
  assetEventId: Scalars['ID'];
};

export type DeleteAssetInput = {
  assetId: Scalars['ID'];
};

export type DeleteAssetTypeInput = {
  assetTypeId: Scalars['ID'];
};

export type DeleteConsumerInput = {
  consumerId: Scalars['ID'];
};

export type DeleteExportReceipt = {
  __typename?: 'DeleteExportReceipt';
  message: Scalars['String'];
};

export type DeleteLocationInput = {
  locationId: Scalars['ID'];
};

export type DeleteRentalConfigInput = {
  rentalConfigId: Scalars['ID'];
};

export type DeleteRentalStepConfigInput = {
  id: Scalars['ID'];
};

export type DeleteResolutionInput = {
  resolutionId: Scalars['ID'];
};

export type DeleteScanAppUserInput = {
  userId: Scalars['ID'];
};

export type DeleteWebhookInput = {
  webhookId: Scalars['ID'];
};

export type Export = {
  __typename?: 'Export';
  completedFileKey?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  exportId: Scalars['String'];
  finalRentalsCount?: Maybe<Scalars['Int']>;
  fromTimestamp: Scalars['String'];
  initialRentalsCount?: Maybe<Scalars['Int']>;
  size: Scalars['Int'];
  status: Scalars['String'];
  toTimestamp: Scalars['String'];
};

export type ExportDownload = {
  __typename?: 'ExportDownload';
  expiration: Scalars['String'];
  url: Scalars['String'];
};

export type ExportReceipt = {
  __typename?: 'ExportReceipt';
  exportId: Scalars['ID'];
  message: Scalars['String'];
};

export type ForgivenessDetails = {
  __typename?: 'ForgivenessDetails';
  reason: ForgivenessReason;
  timestamp: Scalars['String'];
};

export enum ForgivenessReason {
  InstantForgiveness = 'INSTANT_FORGIVENESS',
  ManualForgiveness = 'MANUAL_FORGIVENESS',
  ReturnReceivedForgiveness = 'RETURN_RECEIVED_FORGIVENESS'
}

export type HardwareUnit = {
  __typename?: 'HardwareUnit';
  createdAt?: Maybe<Scalars['String']>;
  installedOn?: Maybe<Scalars['String']>;
  iotThingName: Scalars['String'];
  location: Location;
  /**   resolves to Location type using AppSync field resolver */
  stations?: Maybe<Array<Maybe<Station>>>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type HumanObservationLabel = ObservationLabel & {
  __typename?: 'HumanObservationLabel';
  container?: Maybe<Container>;
  isFlagged: Scalars['Boolean'];
  labelTimestamp: Scalars['String'];
  labelType: LabelType;
  /**   Interface +: */
  labeler?: Maybe<Scalars['String']>;
  menuId?: Maybe<Scalars['ID']>;
  notes?: Maybe<Scalars['String']>;
  recognizableItemId?: Maybe<Scalars['ID']>;
  recognizableItemType?: Maybe<RecognizableItemType>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ImageObservation = {
  __typename?: 'ImageObservation';
  bucket: Scalars['String'];
  /**   S3 bucket name */
  key: Scalars['String'];
  /**   Presigned URL for object */
  metadata?: Maybe<Scalars['String']>;
  /**   S3 object key */
  url?: Maybe<Scalars['String']>;
};

export type Ingredient = RecognizableItem & {
  __typename?: 'Ingredient';
  cost?: Maybe<Scalars['Int']>;
  costCurrency?: Maybe<Currency>;
  costPerUnit?: Maybe<CostPerUom>;
  costUOM?: Maybe<Scalars['String']>;
  costUOMQty?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  /**
   *   ---
   *  deprecated: don't use
   *  ---
   */
  labels?: Maybe<Array<Maybe<Scalars['String']>>>;
  metadata?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  source?: Maybe<MenuDataSource>;
  tags?: Maybe<Array<Scalars['String']>>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type InitObservationInput = {
  iotThingName: Scalars['String'];
  observationId: Scalars['ID'];
  observationType: ObservationType;
  timestamp: Scalars['String'];
  weight: WeightObservationInput;
};

export type InventoryChange = {
  __typename?: 'InventoryChange';
  adjustByAmount?: Maybe<Scalars['Int']>;
  adjustToAmount?: Maybe<Scalars['Int']>;
  assetTypeId: Scalars['ID'];
  createdAt: Scalars['String'];
  description: Scalars['String'];
  eventId: Scalars['ID'];
  eventTimestamp: Scalars['String'];
  eventType: InventoryChangeEventType;
  inventoryChangeId: Scalars['ID'];
  locationId: Scalars['ID'];
};

export enum InventoryChangeEventType {
  AssetEvent = 'asset_event',
  AssetTypeChange = 'asset_type_change',
  Manual = 'manual'
}

export type InventoryChangesPaginated = {
  __typename?: 'InventoryChangesPaginated';
  nextToken?: Maybe<Scalars['String']>;
  results?: Maybe<Array<Maybe<InventoryChange>>>;
};

export type InventoryLevel = {
  __typename?: 'InventoryLevel';
  assetTypeId: Scalars['ID'];
  locationId: Scalars['ID'];
  onHand: Scalars['Int'];
  timestamp?: Maybe<Scalars['String']>;
};

export type InventoryLevelsPaginated = {
  __typename?: 'InventoryLevelsPaginated';
  nextToken?: Maybe<Scalars['String']>;
  results?: Maybe<Array<Maybe<InventoryLevel>>>;
};

export type KeyValue = {
  __typename?: 'KeyValue';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type KeyValueInput = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export enum LabelType {
  Human = 'HUMAN',
  Machine = 'MACHINE'
}

export type LikeAssetTypeResolution = {
  __typename?: 'LikeAssetTypeResolution';
  likeAssetTypeIds?: Maybe<Array<Scalars['ID']>>;
  resolutionTiming: ResolutionTiming;
};

export type LikeAssetTypeResolutionInput = {
  likeAssetTypeIds?: InputMaybe<Array<Scalars['ID']>>;
  resolutionTiming: ResolutionTimingInput;
};

export type Location = {
  __typename?: 'Location';
  address?: Maybe<Address>;
  createdAt?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['ID']>;
  fullName?: Maybe<Scalars['String']>;
  locationId: Scalars['ID'];
  metadata?: Maybe<Array<Maybe<KeyValue>>>;
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type LocationsPaginated = {
  __typename?: 'LocationsPaginated';
  nextToken?: Maybe<Scalars['String']>;
  results?: Maybe<Array<Maybe<Location>>>;
};

export type Loop = {
  __typename?: 'Loop';
  asset: Asset;
  closedAt?: Maybe<Scalars['String']>;
  consumer: Consumer;
  externalId?: Maybe<Scalars['ID']>;
  locations?: Maybe<LoopLocations>;
  loopId: Scalars['ID'];
  loopStatus: LoopStatus;
  metadata?: Maybe<Array<Maybe<KeyValue>>>;
  openedAt?: Maybe<Scalars['String']>;
  rentalConfig?: Maybe<RentalConfig>;
  resolutions?: Maybe<Array<Maybe<LoopResolution>>>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type LoopCount = {
  __typename?: 'LoopCount';
  count?: Maybe<Scalars['Int']>;
  loopStatus?: Maybe<LoopStatus>;
  timestamp?: Maybe<Scalars['String']>;
};

export type LoopCountResults = {
  __typename?: 'LoopCountResults';
  results?: Maybe<Array<Maybe<LoopCount>>>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type LoopDuration = {
  __typename?: 'LoopDuration';
  cumulativeLoopsClosed?: Maybe<Scalars['Int']>;
  cumulativeLoopsOpened?: Maybe<Scalars['Int']>;
  loopDurationDays?: Maybe<Scalars['Int']>;
  percentReturned?: Maybe<Scalars['Float']>;
};

export type LoopDurationResults = {
  __typename?: 'LoopDurationResults';
  results?: Maybe<Array<Maybe<LoopDuration>>>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type LoopDurationStat = {
  __typename?: 'LoopDurationStat';
  days: Scalars['Int'];
  percentReturned: Scalars['Float'];
};

export type LoopDurationStats = {
  __typename?: 'LoopDurationStats';
  assetTypeId?: Maybe<Scalars['ID']>;
  isAvailable: Scalars['Boolean'];
  lastUpdated?: Maybe<Scalars['String']>;
  loopDurations?: Maybe<Array<Maybe<LoopDurationStat>>>;
};

export type LoopKeys = {
  __typename?: 'LoopKeys';
  assetId?: Maybe<Scalars['String']>;
  consumerId?: Maybe<Scalars['ID']>;
  loopId?: Maybe<Scalars['ID']>;
};

export type LoopLocations = {
  __typename?: 'LoopLocations';
  closedAt?: Maybe<Location>;
  openedAt: Location;
};

export type LoopResolution = {
  __typename?: 'LoopResolution';
  loopId: Scalars['ID'];
  resolution: Resolution;
  resolutionStatus: ResolutionStatus;
  triggerResolutionAt: Scalars['String'];
};

export enum LoopStatus {
  Closed = 'closed',
  Open = 'open'
}

export type LoopsPaginated = {
  __typename?: 'LoopsPaginated';
  nextToken?: Maybe<Scalars['String']>;
  results?: Maybe<Array<Maybe<Loop>>>;
};

export type MachineObservationLabel = ObservationLabel & {
  __typename?: 'MachineObservationLabel';
  container?: Maybe<Container>;
  /**   Interface +: */
  description?: Maybe<Scalars['String']>;
  isFlagged: Scalars['Boolean'];
  labelTimestamp: Scalars['String'];
  labelType: LabelType;
  menuId?: Maybe<Scalars['ID']>;
  modelMetadata?: Maybe<Scalars['String']>;
  modelVersion?: Maybe<Scalars['String']>;
  rationale?: Maybe<Scalars['String']>;
  recognizableItemId?: Maybe<Scalars['ID']>;
  recognizableItemType?: Maybe<RecognizableItemType>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ManualInventoryAdjustment = {
  __typename?: 'ManualInventoryAdjustment';
  adjustByAmount?: Maybe<Scalars['Int']>;
  adjustToAmount?: Maybe<Scalars['Int']>;
  adjustmentId: Scalars['ID'];
  assetTypeId: Scalars['ID'];
  createdAt: Scalars['String'];
  locationId: Scalars['ID'];
  reason: Scalars['String'];
};

export type ManualInventoryAdjustmentInput = {
  adjustByAmount?: InputMaybe<Scalars['Int']>;
  adjustToAmount?: InputMaybe<Scalars['Int']>;
  assetTypeId: Scalars['ID'];
  locationId: Scalars['ID'];
  reason: Scalars['String'];
};

export type MarketingOptions = {
  __typename?: 'MarketingOptions';
  email?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['Boolean']>;
};

export type MarketingOptionsInput = {
  email?: InputMaybe<Scalars['Boolean']>;
  phoneNumber?: InputMaybe<Scalars['Boolean']>;
};

export type Menu = {
  __typename?: 'Menu';
  createdAt: Scalars['String'];
  externalId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  ingredients?: Maybe<Array<Maybe<Ingredient>>>;
  ings?: Maybe<Array<MenuOption>>;
  items?: Maybe<Array<MenuOption>>;
  /**
   *   ---
   *  deprecated: don't use - replaced by costPerUnit
   *  ---
   */
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['ID']>;
  mealPeriod?: Maybe<Scalars['String']>;
  menuDate: Scalars['String'];
  menuItems?: Maybe<Array<Maybe<MenuItem>>>;
  name?: Maybe<Scalars['String']>;
  source?: Maybe<MenuDataSource>;
  stationId?: Maybe<Scalars['ID']>;
  updatedAt: Scalars['String'];
};

export enum MenuDataSource {
  Jamix = 'JAMIX',
  Netmenu = 'NETMENU',
  Prima = 'PRIMA'
}

export type MenuItem = RecognizableItem & {
  __typename?: 'MenuItem';
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  cost?: Maybe<Scalars['Int']>;
  /**
   *   ---
   *  deprecated: don't use - replaced by costPerUnit
   *  ---
   */
  costCurrency?: Maybe<Currency>;
  costPerUnit?: Maybe<CostPerUom>;
  costUOM?: Maybe<Scalars['String']>;
  costUOMQty?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  metadata?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  recipe?: Maybe<Array<Maybe<RecipeItem>>>;
  source?: Maybe<MenuDataSource>;
  tags?: Maybe<Array<Scalars['String']>>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type MenuOption = {
  __typename?: 'MenuOption';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type MenuOptionInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type MissedScanStats = {
  __typename?: 'MissedScanStats';
  assetTypeId?: Maybe<Scalars['ID']>;
  isAvailable: Scalars['Boolean'];
  lastUpdated?: Maybe<Scalars['String']>;
  percentMissed?: Maybe<Scalars['Float']>;
  previousComparisonWindow?: Maybe<Scalars['String']>;
  previousPercentMissed?: Maybe<Scalars['String']>;
};

export type ModifyMenuWithIngredientInput = {
  ingredientId?: InputMaybe<Scalars['ID']>;
  menuId?: InputMaybe<Scalars['ID']>;
};

export type ModifyMenuWithMenuItemInput = {
  menuId?: InputMaybe<Scalars['ID']>;
  menuItemId?: InputMaybe<Scalars['ID']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addHumanLabel?: Maybe<Scalars['Boolean']>;
  addIngredientToMenu?: Maybe<Scalars['Boolean']>;
  addMenuItemToMenu?: Maybe<Scalars['Boolean']>;
  addObservationTags?: Maybe<Array<Maybe<ObservationTag>>>;
  adminCancelRental?: Maybe<Rental>;
  archiveLocation?: Maybe<Location>;
  archiveParentLocation?: Maybe<ParentLocation>;
  closeLoop?: Maybe<LoopKeys>;
  completeRental?: Maybe<Rental>;
  createAsset?: Maybe<Asset>;
  createAssetEvent?: Maybe<AssetEvent>;
  createAssetOperation?: Maybe<AssetOperation>;
  createAssetType?: Maybe<AssetType>;
  createCharge?: Maybe<Charge>;
  createChargeRentalStepConfig?: Maybe<ChargeRentalStepConfig>;
  createConsumer?: Maybe<Consumer>;
  createExport?: Maybe<ExportReceipt>;
  createIngredient?: Maybe<Ingredient>;
  createLocation?: Maybe<Location>;
  createLoop?: Maybe<Loop>;
  createManualInventoryAdjustment?: Maybe<ManualInventoryAdjustment>;
  createMenu?: Maybe<Menu>;
  createMenuItem?: Maybe<MenuItem>;
  createNotificationRentalStepConfig?: Maybe<NotificationRentalStepConfig>;
  createParentLocation?: Maybe<ParentLocation>;
  createPhaseChangeRentalStepConfig?: Maybe<PhaseChangeRentalStepConfig>;
  createRental?: Maybe<Rental>;
  createRentalConfig?: Maybe<RentalConfig>;
  createRentalStep?: Maybe<RentalStep>;
  createResolution?: Maybe<Resolution>;
  createScanAppGroupUser?: Maybe<ScanAppUserSettings>;
  createScanAppIndividualUser?: Maybe<ScanAppUserSettings>;
  createWebhook?: Maybe<Webhook>;
  deleteAsset?: Maybe<Asset>;
  deleteAssetEvent?: Maybe<AssetEvent>;
  deleteAssetType?: Maybe<AssetType>;
  deleteConsumer?: Maybe<Consumer>;
  deleteExport?: Maybe<DeleteExportReceipt>;
  deleteLocation?: Maybe<Location>;
  deleteRentalConfig?: Maybe<RentalConfig>;
  deleteRentalStepConfig?: Maybe<RentalStepConfig>;
  deleteResolution?: Maybe<Resolution>;
  deleteScanAppUser?: Maybe<ScanAppUserSettings>;
  deleteWebhook?: Maybe<Webhook>;
  removeIngredientFromMenu?: Maybe<Scalars['Boolean']>;
  removeMenuItemToMenu?: Maybe<Scalars['Boolean']>;
  removeObservationTags?: Maybe<Array<Maybe<ObservationTag>>>;
  updateAsset?: Maybe<Asset>;
  updateAssetEvent?: Maybe<AssetEvent>;
  updateAssetOperation?: Maybe<AssetOperation>;
  updateAssetType?: Maybe<AssetType>;
  updateChargeOutcome?: Maybe<Charge>;
  updateChargeRentalStepConfig?: Maybe<ChargeRentalStepConfig>;
  updateConsumer?: Maybe<Consumer>;
  updateIngredient?: Maybe<Scalars['Boolean']>;
  updateLocation?: Maybe<Location>;
  updateMenu?: Maybe<Scalars['Boolean']>;
  updateMenuItem?: Maybe<Scalars['Boolean']>;
  updateNotificationRentalStepConfig?: Maybe<NotificationRentalStepConfig>;
  updateObservationItemCount?: Maybe<Scalars['Boolean']>;
  updateOrganizationInstantForgivenessEnabled?: Maybe<Organization>;
  updateOrganizationReturnReceivedForgivenessEnabled?: Maybe<Organization>;
  updateParentLocation?: Maybe<ParentLocation>;
  updatePhaseChangeRentalStepConfig?: Maybe<PhaseChangeRentalStepConfig>;
  updateRentalConfig?: Maybe<RentalConfig>;
  updateRentalPhase?: Maybe<Rental>;
  updateRentalStatus?: Maybe<Rental>;
  updateResolution?: Maybe<Resolution>;
  updateScanAppGroupUserPassword?: Maybe<ScanAppUserSettings>;
  updateScanAppUserSettings?: Maybe<ScanAppUserSettings>;
  updateWebhook?: Maybe<Webhook>;
};


export type MutationAddHumanLabelArgs = {
  input?: InputMaybe<AddHumanLabelSessionInput>;
};


export type MutationAddIngredientToMenuArgs = {
  input?: InputMaybe<ModifyMenuWithIngredientInput>;
};


export type MutationAddMenuItemToMenuArgs = {
  input?: InputMaybe<ModifyMenuWithMenuItemInput>;
};


export type MutationAddObservationTagsArgs = {
  input?: InputMaybe<ObservationTagInput>;
};


export type MutationAdminCancelRentalArgs = {
  input: AdminCancelRentalInput;
};


export type MutationArchiveLocationArgs = {
  input: ArchiveLocationInput;
};


export type MutationArchiveParentLocationArgs = {
  input: ArchiveParentLocationInput;
};


export type MutationCloseLoopArgs = {
  input: CloseLoopInput;
};


export type MutationCompleteRentalArgs = {
  input: CompleteRentalInput;
};


export type MutationCreateAssetArgs = {
  input: CreateAssetInput;
};


export type MutationCreateAssetEventArgs = {
  input: CreateAssetEventInput;
};


export type MutationCreateAssetOperationArgs = {
  input: CreateAssetOperationInput;
};


export type MutationCreateAssetTypeArgs = {
  input: CreateAssetTypeInput;
};


export type MutationCreateChargeArgs = {
  input: CreateChargeInput;
};


export type MutationCreateChargeRentalStepConfigArgs = {
  input: CreateChargeRentalStepConfigInput;
};


export type MutationCreateConsumerArgs = {
  input: CreateConsumerInput;
};


export type MutationCreateExportArgs = {
  dryRun?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  fromTimestamp?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  toTimestamp?: InputMaybe<Scalars['String']>;
};


export type MutationCreateIngredientArgs = {
  input?: InputMaybe<CreateIngredientInput>;
};


export type MutationCreateLocationArgs = {
  input: CreateLocationInput;
};


export type MutationCreateLoopArgs = {
  input: CreateLoopInput;
};


export type MutationCreateManualInventoryAdjustmentArgs = {
  input: ManualInventoryAdjustmentInput;
};


export type MutationCreateMenuArgs = {
  input?: InputMaybe<CreateMenuInput>;
};


export type MutationCreateMenuItemArgs = {
  input?: InputMaybe<CreateMenuItemInput>;
};


export type MutationCreateNotificationRentalStepConfigArgs = {
  input: CreateNotificationRentalStepConfigInput;
};


export type MutationCreateParentLocationArgs = {
  input: CreateParentLocationInput;
};


export type MutationCreatePhaseChangeRentalStepConfigArgs = {
  input: CreatePhaseChangeRentalStepConfigInput;
};


export type MutationCreateRentalArgs = {
  input: CreateRentalInput;
};


export type MutationCreateRentalConfigArgs = {
  input: CreateRentalConfigInput;
};


export type MutationCreateRentalStepArgs = {
  input: CreateRentalStepInput;
};


export type MutationCreateResolutionArgs = {
  input: CreateResolutionInput;
};


export type MutationCreateScanAppGroupUserArgs = {
  input: CreateScanAppGroupUserInput;
};


export type MutationCreateScanAppIndividualUserArgs = {
  input: CreateScanAppIndividualUserInput;
};


export type MutationCreateWebhookArgs = {
  input: CreateWebhookInput;
};


export type MutationDeleteAssetArgs = {
  input: DeleteAssetInput;
};


export type MutationDeleteAssetEventArgs = {
  input: DeleteAssetEventInput;
};


export type MutationDeleteAssetTypeArgs = {
  input: DeleteAssetTypeInput;
};


export type MutationDeleteConsumerArgs = {
  input: DeleteConsumerInput;
};


export type MutationDeleteExportArgs = {
  exportId: Scalars['ID'];
};


export type MutationDeleteLocationArgs = {
  input: DeleteLocationInput;
};


export type MutationDeleteRentalConfigArgs = {
  input: DeleteRentalConfigInput;
};


export type MutationDeleteRentalStepConfigArgs = {
  input: DeleteRentalStepConfigInput;
};


export type MutationDeleteResolutionArgs = {
  input: DeleteResolutionInput;
};


export type MutationDeleteScanAppUserArgs = {
  input: DeleteScanAppUserInput;
};


export type MutationDeleteWebhookArgs = {
  input: DeleteWebhookInput;
};


export type MutationRemoveIngredientFromMenuArgs = {
  input?: InputMaybe<ModifyMenuWithIngredientInput>;
};


export type MutationRemoveMenuItemToMenuArgs = {
  input?: InputMaybe<ModifyMenuWithMenuItemInput>;
};


export type MutationRemoveObservationTagsArgs = {
  input?: InputMaybe<ObservationTagInput>;
};


export type MutationUpdateAssetArgs = {
  input: UpdateAssetInput;
};


export type MutationUpdateAssetEventArgs = {
  input: UpdateAssetEventInput;
};


export type MutationUpdateAssetOperationArgs = {
  input: UpdateAssetOperationInput;
};


export type MutationUpdateAssetTypeArgs = {
  input: UpdateAssetTypeInput;
};


export type MutationUpdateChargeOutcomeArgs = {
  input: UpdateChargeOutcomeInput;
};


export type MutationUpdateChargeRentalStepConfigArgs = {
  input: UpdateChargeRentalStepConfigInput;
};


export type MutationUpdateConsumerArgs = {
  input: UpdateConsumerInput;
};


export type MutationUpdateIngredientArgs = {
  input?: InputMaybe<UpdateIngredientInput>;
};


export type MutationUpdateLocationArgs = {
  input: UpdateLocationInput;
};


export type MutationUpdateMenuArgs = {
  input?: InputMaybe<UpdateMenuInput>;
};


export type MutationUpdateMenuItemArgs = {
  input?: InputMaybe<UpdateMenuItemInput>;
};


export type MutationUpdateNotificationRentalStepConfigArgs = {
  input: UpdateNotificationRentalStepConfigInput;
};


export type MutationUpdateObservationItemCountArgs = {
  input?: InputMaybe<ObservationItemCountInput>;
};


export type MutationUpdateOrganizationInstantForgivenessEnabledArgs = {
  input: UpdateOrganizationInstantForgivenessEnabledInput;
};


export type MutationUpdateOrganizationReturnReceivedForgivenessEnabledArgs = {
  input: UpdateOrganizationReturnReceivedForgivenessEnabledInput;
};


export type MutationUpdateParentLocationArgs = {
  input: UpdateParentLocationInput;
};


export type MutationUpdatePhaseChangeRentalStepConfigArgs = {
  input: UpdatePhaseChangeRentalStepConfigInput;
};


export type MutationUpdateRentalConfigArgs = {
  input: UpdateRentalConfigInput;
};


export type MutationUpdateRentalPhaseArgs = {
  input: UpdateRentalPhaseInput;
};


export type MutationUpdateRentalStatusArgs = {
  input: UpdateRentalStatusInput;
};


export type MutationUpdateResolutionArgs = {
  input: UpdateResolutionInput;
};


export type MutationUpdateScanAppGroupUserPasswordArgs = {
  input: UpdateScanAppGroupUserPasswordInput;
};


export type MutationUpdateScanAppUserSettingsArgs = {
  input: UpdateScanAppUserSettingsInput;
};


export type MutationUpdateWebhookArgs = {
  input: UpdateWebhookInput;
};

export type NetWeightByDay = {
  __typename?: 'NetWeightByDay';
  day: Scalars['String'];
  grams?: Maybe<Scalars['Float']>;
};

export type NotificationRentalStepConfig = RentalStepConfig & {
  __typename?: 'NotificationRentalStepConfig';
  createdAt: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  notificationData?: Maybe<Scalars['String']>;
  notificationType: RentalNotificationType;
  rentalPhase: RentalPhase;
  timing: ResolutionTiming;
  updatedAt: Scalars['String'];
};

export type Observation = {
  audio?: Maybe<AudioObservation>;
  bestLabel?: Maybe<RecognizableItem>;
  capturedBy: HardwareUnit;
  /**   The best label to associate with the Observation. */
  container?: Maybe<Container>;
  grossWeight?: Maybe<WeightObservation>;
  id: Scalars['ID'];
  images?: Maybe<Array<Maybe<ImageObservation>>>;
  labelSessions?: Maybe<Array<Maybe<ObservationLabel>>>;
  netWeight?: Maybe<WeightObservation>;
  observationType: ObservationType;
  station?: Maybe<Station>;
  statuses?: Maybe<ObservationStatuses>;
  tags?: Maybe<Array<Maybe<ObservationTag>>>;
  timestamp: Scalars['String'];
  value?: Maybe<ObservationCost>;
};

export type ObservationAggregation = NetWeightByDay;

export enum ObservationAggregationOption {
  NetWeightByDay = 'NET_WEIGHT_BY_DAY'
}

export type ObservationCost = {
  __typename?: 'ObservationCost';
  byCount?: Maybe<Scalars['Boolean']>;
  costCurrency?: Maybe<Currency>;
  count?: Maybe<Scalars['Int']>;
  /**   Add/remove tag for NEEDS_COUNT */
  countingUnit?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};

export type ObservationItemCountInput = {
  count: Scalars['Int'];
  observationId: Scalars['ID'];
};

export type ObservationLabel = {
  container?: Maybe<Container>;
  isFlagged: Scalars['Boolean'];
  labelTimestamp: Scalars['String'];
  labelType: LabelType;
  menuId?: Maybe<Scalars['ID']>;
  recognizableItemId?: Maybe<Scalars['ID']>;
  recognizableItemType?: Maybe<RecognizableItemType>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ObservationSearchResults = {
  __typename?: 'ObservationSearchResults';
  aggregations?: Maybe<Array<Maybe<ObservationAggregation>>>;
  observations?: Maybe<Array<Maybe<Observation>>>;
  totalHits: Scalars['Int'];
};

export type ObservationStatuses = {
  __typename?: 'ObservationStatuses';
  uiCanceled?: Maybe<Scalars['Boolean']>;
  /**   True if observation is canceled by user. */
  uiCompleted?: Maybe<Scalars['Boolean']>;
};

export enum ObservationTag {
  ClientHelp = 'CLIENT_HELP',
  ContainerLabelHelp = 'CONTAINER_LABEL_HELP',
  Donation = 'DONATION',
  Ignore = 'IGNORE',
  ImageHelp = 'IMAGE_HELP',
  ItemLabelHelp = 'ITEM_LABEL_HELP',
  Labeled = 'LABELED',
  MenuHelp = 'MENU_HELP',
  NeedsCount = 'NEEDS_COUNT',
  Reviewed = 'REVIEWED',
  Trimmings = 'TRIMMINGS',
  Unlabeled = 'UNLABELED',
  Verified = 'VERIFIED'
}

export type ObservationTagInput = {
  observationId: Scalars['ID'];
  tags?: InputMaybe<Array<InputMaybe<ObservationTag>>>;
};

export enum ObservationType {
  Waste = 'WASTE'
}

export type Organization = {
  __typename?: 'Organization';
  externalId?: Maybe<Scalars['ID']>;
  instantForgivenessEnabled?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<Array<Maybe<KeyValue>>>;
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  returnReceivedForgivenessEnabled?: Maybe<Scalars['Boolean']>;
  scanAppUserLimit?: Maybe<Scalars['Int']>;
  scanAppUsers?: Maybe<Scalars['Int']>;
  slug: Scalars['String'];
};

export type PaginatedHardwareUnits = {
  __typename?: 'PaginatedHardwareUnits';
  nextToken?: Maybe<Scalars['String']>;
  results?: Maybe<Array<Maybe<HardwareUnit>>>;
};

export type PaginatedMenus = {
  __typename?: 'PaginatedMenus';
  nextToken?: Maybe<Scalars['String']>;
  results?: Maybe<Array<Maybe<Menu>>>;
};

export type PaginatedParentLocations = {
  __typename?: 'PaginatedParentLocations';
  nextToken?: Maybe<Scalars['String']>;
  results?: Maybe<Array<Maybe<ParentLocation>>>;
};

export type PaginatedRentalStepConfigs = {
  __typename?: 'PaginatedRentalStepConfigs';
  nextToken?: Maybe<Scalars['String']>;
  rentalStepConfigs?: Maybe<Array<RentalStepConfig>>;
};

export type PaginatedRentals = {
  __typename?: 'PaginatedRentals';
  nextToken?: Maybe<Scalars['String']>;
  rentals?: Maybe<Array<Rental>>;
};

export type ParentLocation = {
  __typename?: 'ParentLocation';
  address?: Maybe<Address>;
  createdAt?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['ID']>;
  locationId: Scalars['ID'];
  locations?: Maybe<Array<Maybe<Location>>>;
  metadata?: Maybe<Array<Maybe<KeyValue>>>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
};

export type PhaseChangeRentalStepConfig = RentalStepConfig & {
  __typename?: 'PhaseChangeRentalStepConfig';
  createdAt: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  rentalPhase: RentalPhase;
  timing: ResolutionTiming;
  updatedAt: Scalars['String'];
};

export type ProductionQuantity = {
  __typename?: 'ProductionQuantity';
  amount?: Maybe<Scalars['Float']>;
  portions?: Maybe<Scalars['Int']>;
  units?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type ProductionStats = {
  __typename?: 'ProductionStats';
  locationId: Scalars['ID'];
  menuDate: Scalars['String'];
  menuId: Scalars['ID'];
  menuItemId: Scalars['ID'];
  menuItemName: Scalars['String'];
  observations?: Maybe<Array<Maybe<Scalars['ID']>>>;
  planned?: Maybe<ProductionQuantity>;
  post?: Maybe<ProductionQuantity>;
  prepared?: Maybe<ProductionQuantity>;
  station?: Maybe<Station>;
  updatedAt: Scalars['String'];
};

export type QrData = {
  __typename?: 'QRData';
  data: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  assetQRData?: Maybe<QrData>;
  consumerQRData?: Maybe<QrData>;
  containers?: Maybe<Array<Maybe<Container>>>;
  getAsset?: Maybe<Asset>;
  getAssetEvent?: Maybe<AssetEvent>;
  getAssetLastKnownLocation?: Maybe<AssetLocation>;
  getAssetOperation?: Maybe<AssetOperation>;
  getAssetType?: Maybe<AssetType>;
  getAssetTypeUtilization?: Maybe<AssetTypeUtilization>;
  getCharge?: Maybe<Charge>;
  getChargeByLoop?: Maybe<Charge>;
  getConsumer?: Maybe<Consumer>;
  getConsumerStats?: Maybe<ConsumerStats>;
  getCurrentInventory?: Maybe<InventoryLevel>;
  getExport?: Maybe<ExportDownload>;
  getLocation?: Maybe<Location>;
  getLoop?: Maybe<Loop>;
  getOrganization?: Maybe<Organization>;
  getParentLocation?: Maybe<ParentLocation>;
  getRental?: Maybe<Rental>;
  getRentalConfig?: Maybe<RentalConfig>;
  getRentalStep?: Maybe<RentalStep>;
  getRentalStepConfig?: Maybe<RentalStepConfig>;
  getResolution?: Maybe<Resolution>;
  getReturnRate?: Maybe<ReturnRate>;
  getScanAppUser?: Maybe<ScanAppUserSettings>;
  getStatAssetUsage?: Maybe<AssetUsageStats>;
  getStatCheckoutsReturns?: Maybe<CheckoutsReturnsStats>;
  getStatCycleRate?: Maybe<CycleRateStats>;
  getStatLoopDurations?: Maybe<LoopDurationStats>;
  getStatMissedScans?: Maybe<MissedScanStats>;
  getStatReturnRate?: Maybe<ReturnRateStats>;
  getStatReuseRate?: Maybe<ReuseRateStats>;
  getWebhook?: Maybe<Webhook>;
  hardwareUnit?: Maybe<HardwareUnit>;
  hardwareUnits?: Maybe<PaginatedHardwareUnits>;
  ingredient?: Maybe<Ingredient>;
  listAssetOperations?: Maybe<AssetOperationsPaginated>;
  listAssetTypes?: Maybe<AssetTypesPaginated>;
  listAssets?: Maybe<AssetsPaginated>;
  listConsumers?: Maybe<ConsumersPaginated>;
  listExports?: Maybe<Array<Export>>;
  listLocations?: Maybe<LocationsPaginated>;
  listNotificationRentalStepConfigs?: Maybe<PaginatedRentalStepConfigs>;
  listOrganizationRentalPhases?: Maybe<Array<RentalPhase>>;
  listParentLocations?: Maybe<PaginatedParentLocations>;
  listPhaseChangeRentalStepConfigs?: Maybe<PaginatedRentalStepConfigs>;
  listRentalConfigs?: Maybe<Array<Maybe<RentalConfig>>>;
  listRentalStepsForRental?: Maybe<Array<Maybe<RentalStep>>>;
  listRentals?: Maybe<PaginatedRentals>;
  listResolutions?: Maybe<ResolutionsPaginated>;
  listScanAppUsers?: Maybe<ScanAppUserSettingsPaginated>;
  listWebhooks?: Maybe<Array<Maybe<Webhook>>>;
  menu?: Maybe<Menu>;
  menuItem?: Maybe<MenuItem>;
  menus?: Maybe<PaginatedMenus>;
  observation?: Maybe<Observation>;
  observations?: Maybe<ObservationSearchResults>;
  queryActiveConsumerCounts?: Maybe<ActiveConsumerCountResults>;
  queryAssetEvents?: Maybe<AssetEventsPaginated>;
  queryAssetOperations?: Maybe<AssetOperationsPaginated>;
  queryHistoricalInventory?: Maybe<InventoryLevelsPaginated>;
  queryInventoryChanges?: Maybe<InventoryChangesPaginated>;
  queryLoopCounts?: Maybe<LoopCountResults>;
  queryLoopDurations?: Maybe<LoopDurationResults>;
  queryLoops?: Maybe<LoopsPaginated>;
  searchAssetEvents?: Maybe<AssetEventSearchResults>;
  searchRentals?: Maybe<RentalSearchResults>;
};


export type QueryAssetQrDataArgs = {
  assetId: Scalars['ID'];
};


export type QueryConsumerQrDataArgs = {
  consumerId?: InputMaybe<Scalars['ID']>;
  externalId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetAssetArgs = {
  assetId: Scalars['ID'];
};


export type QueryGetAssetEventArgs = {
  assetEventId: Scalars['ID'];
};


export type QueryGetAssetLastKnownLocationArgs = {
  assetId: Scalars['ID'];
};


export type QueryGetAssetOperationArgs = {
  assetOperationId: Scalars['ID'];
};


export type QueryGetAssetTypeArgs = {
  assetTypeId: Scalars['ID'];
};


export type QueryGetAssetTypeUtilizationArgs = {
  assetTypeId: Scalars['ID'];
  utilizationDate?: InputMaybe<Scalars['String']>;
};


export type QueryGetChargeArgs = {
  chargeId: Scalars['ID'];
};


export type QueryGetChargeByLoopArgs = {
  loopId: Scalars['ID'];
};


export type QueryGetConsumerArgs = {
  consumerId?: InputMaybe<Scalars['ID']>;
  externalId?: InputMaybe<Scalars['ID']>;
  reuserId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetConsumerStatsArgs = {
  consumerId: Scalars['ID'];
};


export type QueryGetCurrentInventoryArgs = {
  assetTypeId: Scalars['ID'];
  locationId: Scalars['ID'];
};


export type QueryGetExportArgs = {
  exportId: Scalars['ID'];
};


export type QueryGetLocationArgs = {
  locationId: Scalars['ID'];
};


export type QueryGetLoopArgs = {
  loopId: Scalars['ID'];
};


export type QueryGetParentLocationArgs = {
  locationId: Scalars['ID'];
};


export type QueryGetRentalArgs = {
  loopId?: InputMaybe<Scalars['ID']>;
  rentalId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetRentalConfigArgs = {
  rentalConfigId: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};


export type QueryGetRentalStepArgs = {
  id: Scalars['ID'];
};


export type QueryGetRentalStepConfigArgs = {
  id: Scalars['ID'];
};


export type QueryGetResolutionArgs = {
  resolutionId: Scalars['ID'];
};


export type QueryGetReturnRateArgs = {
  assetTypeId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetScanAppUserArgs = {
  userId: Scalars['ID'];
};


export type QueryGetStatAssetUsageArgs = {
  assetTypeId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetStatCheckoutsReturnsArgs = {
  assetTypeId?: InputMaybe<Scalars['ID']>;
  parentLocationIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  window?: InputMaybe<StatWindow>;
};


export type QueryGetStatCycleRateArgs = {
  assetTypeId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetStatLoopDurationsArgs = {
  assetTypeId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetStatMissedScansArgs = {
  assetTypeId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetStatReturnRateArgs = {
  assetTypeId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetStatReuseRateArgs = {
  assetTypeId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetWebhookArgs = {
  webhookId: Scalars['ID'];
};


export type QueryHardwareUnitArgs = {
  iotThingName: Scalars['String'];
};


export type QueryHardwareUnitsArgs = {
  paginationToken?: InputMaybe<Scalars['String']>;
};


export type QueryIngredientArgs = {
  externalId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryListAssetOperationsArgs = {
  paginationToken?: InputMaybe<Scalars['String']>;
};


export type QueryListAssetTypesArgs = {
  paginationToken?: InputMaybe<Scalars['String']>;
};


export type QueryListAssetsArgs = {
  paginationToken?: InputMaybe<Scalars['String']>;
};


export type QueryListConsumersArgs = {
  paginationToken?: InputMaybe<Scalars['String']>;
};


export type QueryListLocationsArgs = {
  paginationToken?: InputMaybe<Scalars['String']>;
};


export type QueryListNotificationRentalStepConfigsArgs = {
  paginationToken?: InputMaybe<Scalars['String']>;
};


export type QueryListParentLocationsArgs = {
  paginationToken?: InputMaybe<Scalars['String']>;
};


export type QueryListPhaseChangeRentalStepConfigsArgs = {
  paginationToken?: InputMaybe<Scalars['String']>;
};


export type QueryListRentalConfigsArgs = {
  latestOnly?: InputMaybe<Scalars['Boolean']>;
};


export type QueryListRentalStepsForRentalArgs = {
  rentalId: Scalars['ID'];
  rentalStepStatus?: InputMaybe<RentalStepStatus>;
};


export type QueryListRentalsArgs = {
  consumerId: Scalars['ID'];
  paginationToken?: InputMaybe<Scalars['String']>;
  rentalStatus?: InputMaybe<RentalStatus>;
};


export type QueryListResolutionsArgs = {
  paginationToken?: InputMaybe<Scalars['String']>;
};


export type QueryListScanAppUsersArgs = {
  isActive: Scalars['Boolean'];
  paginationToken?: InputMaybe<Scalars['String']>;
};


export type QueryMenuArgs = {
  externalId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryMenuItemArgs = {
  externalId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryMenusArgs = {
  fromDate: Scalars['String'];
  locationId: Scalars['ID'];
  paginationToken?: InputMaybe<Scalars['String']>;
  stationId?: InputMaybe<Scalars['ID']>;
  toDate?: InputMaybe<Scalars['String']>;
};


export type QueryObservationArgs = {
  id: Scalars['ID'];
};


export type QueryObservationsArgs = {
  aggregationOption?: InputMaybe<ObservationAggregationOption>;
  excludeTags?: InputMaybe<Array<InputMaybe<ObservationTag>>>;
  fromTimestamp: Scalars['String'];
  includeTags?: InputMaybe<Array<InputMaybe<ObservationTag>>>;
  paginateFrom?: InputMaybe<Scalars['Int']>;
  parentLocationIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  searchQuery?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  toTimestamp: Scalars['String'];
  types?: InputMaybe<Array<InputMaybe<ObservationType>>>;
};


export type QueryQueryActiveConsumerCountsArgs = {
  fromTimestamp: Scalars['String'];
  locationId?: InputMaybe<Scalars['ID']>;
  toTimestamp: Scalars['String'];
};


export type QueryQueryAssetEventsArgs = {
  assetId: Scalars['ID'];
  fromTimestamp: Scalars['String'];
  paginationToken?: InputMaybe<Scalars['String']>;
  toTimestamp: Scalars['String'];
};


export type QueryQueryAssetOperationsArgs = {
  operationType: AssetOperationType;
  paginationToken?: InputMaybe<Scalars['String']>;
};


export type QueryQueryHistoricalInventoryArgs = {
  assetTypeId: Scalars['ID'];
  fromDate: Scalars['String'];
  locationId: Scalars['ID'];
  paginationToken?: InputMaybe<Scalars['String']>;
  timezone: Scalars['String'];
  toDate: Scalars['String'];
};


export type QueryQueryInventoryChangesArgs = {
  assetTypeId: Scalars['ID'];
  fromTimestamp: Scalars['String'];
  locationId: Scalars['ID'];
  paginationToken?: InputMaybe<Scalars['String']>;
  toTimestamp: Scalars['String'];
};


export type QueryQueryLoopCountsArgs = {
  assetTypeId?: InputMaybe<Scalars['ID']>;
  consumerId?: InputMaybe<Scalars['ID']>;
  fromTimestamp: Scalars['String'];
  locationId?: InputMaybe<Scalars['ID']>;
  timeGranularity: TimeGranularity;
  timezone: Scalars['String'];
  toTimestamp: Scalars['String'];
};


export type QueryQueryLoopDurationsArgs = {
  assetTypeId?: InputMaybe<Scalars['ID']>;
  fromCohortMonth: Scalars['String'];
  fromLoopDurationDays: Scalars['Int'];
  toCohortMonth: Scalars['String'];
  toLoopDurationDays: Scalars['Int'];
};


export type QueryQueryLoopsArgs = {
  consumerId?: InputMaybe<Scalars['ID']>;
  fromTimestamp?: InputMaybe<Scalars['String']>;
  loopStatus?: InputMaybe<LoopStatus>;
  paginationToken?: InputMaybe<Scalars['String']>;
  toTimestamp?: InputMaybe<Scalars['String']>;
};


export type QuerySearchAssetEventsArgs = {
  calendarInterval: Scalars['String'];
  fromTimestamp: Scalars['String'];
  locationIds?: InputMaybe<Array<Scalars['String']>>;
  loopStatuses?: InputMaybe<Array<LoopStatus>>;
  operationTypes?: InputMaybe<Array<Scalars['String']>>;
  paginateFrom?: InputMaybe<Scalars['Int']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  timezone: Scalars['String'];
  toTimestamp: Scalars['String'];
};


export type QuerySearchRentalsArgs = {
  assetTypeIds?: InputMaybe<Array<Scalars['String']>>;
  consumerId?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<SearchRentalsFilter>;
  fromTimestamp: Scalars['String'];
  paginateFrom?: InputMaybe<Scalars['Int']>;
  rentalPhases?: InputMaybe<Array<RentalPhase>>;
  rentalStatuses?: InputMaybe<Array<RentalStatus>>;
  searchQuery?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  toTimestamp: Scalars['String'];
  withCharges?: InputMaybe<Scalars['Boolean']>;
};

export type RecipeItem = {
  __typename?: 'RecipeItem';
  ingredientId: Scalars['ID'];
  ingredientName?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['String']>;
  perQty?: Maybe<Scalars['Float']>;
  perUOM?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['Float']>;
  qtyUOM?: Maybe<Scalars['String']>;
};

export type RecipeItemInput = {
  ingredientId: Scalars['ID'];
  ingredientName?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['String']>;
  perQty?: InputMaybe<Scalars['Float']>;
  perUOM?: InputMaybe<Scalars['String']>;
  qty?: InputMaybe<Scalars['Float']>;
  qtyUOM?: InputMaybe<Scalars['String']>;
};

export type RecognizableItem = {
  cost?: Maybe<Scalars['Int']>;
  /**
   *   ---
   *  deprecated: don't use - replaced by costPerUnit
   *  ---
   */
  costCurrency?: Maybe<Currency>;
  costPerUnit?: Maybe<CostPerUom>;
  costUOM?: Maybe<Scalars['String']>;
  costUOMQty?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  source?: Maybe<MenuDataSource>;
  tags?: Maybe<Array<Scalars['String']>>;
  updatedAt?: Maybe<Scalars['String']>;
};

export enum RecognizableItemType {
  Ingredient = 'INGREDIENT',
  MenuItem = 'MENU_ITEM',
  Unknown = 'UNKNOWN'
}

export type Rental = {
  __typename?: 'Rental';
  consumerId: Scalars['ID'];
  createdAt: Scalars['String'];
  forgivenessDetails?: Maybe<ForgivenessDetails>;
  lastPhase: RentalPhase;
  loopId: Scalars['ID'];
  metadata?: Maybe<Array<Maybe<KeyValue>>>;
  receivedAssetId?: Maybe<Scalars['ID']>;
  receivedAssetTypeName?: Maybe<Scalars['String']>;
  rentalConfigId: Scalars['ID'];
  rentalConfigVersion: Scalars['String'];
  rentalId: Scalars['ID'];
  rentalStepIds?: Maybe<Array<Scalars['ID']>>;
  rentedAssetId: Scalars['ID'];
  rentedAssetTypeName: Scalars['String'];
  resolvingLoop?: Maybe<Loop>;
  resolvingLoopId?: Maybe<Scalars['ID']>;
  status: RentalStatus;
  updatedAt: Scalars['String'];
};

export type RentalConfig = {
  __typename?: 'RentalConfig';
  createdAt: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  likeAssetTypeIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  name: Scalars['String'];
  rentalConfigId: Scalars['ID'];
  rentalStepConfigIds?: Maybe<Array<Scalars['ID']>>;
  resolutionOption: ResolutionOption;
  updatedAt: Scalars['String'];
  version: Scalars['String'];
};

export enum RentalNotificationType {
  Sms = 'SMS'
}

export enum RentalPhase {
  AboutToExpire = 'ABOUT_TO_EXPIRE',
  AlmostDue = 'ALMOST_DUE',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  FailedPending = 'FAILED_PENDING',
  Fined = 'FINED',
  FinedAutomatic = 'FINED_AUTOMATIC',
  FinedManual = 'FINED_MANUAL',
  FineError = 'FINE_ERROR',
  FinePending = 'FINE_PENDING',
  Initiated = 'INITIATED',
  Late = 'LATE',
  LatePending = 'LATE_PENDING',
  RefundRequested = 'REFUND_REQUESTED'
}

export type RentalSearchDocument = {
  __typename?: 'RentalSearchDocument';
  charge?: Maybe<RentalSearchDocumentCharge>;
  consumer: Consumer;
  createdAt: Scalars['String'];
  dueAt?: Maybe<Scalars['String']>;
  forgivenessDetails?: Maybe<ForgivenessDetails>;
  lastPhase: RentalPhase;
  loopId: Scalars['ID'];
  metadata?: Maybe<KeyValue>;
  organizationId: Scalars['ID'];
  receivedAsset?: Maybe<Asset>;
  rentalConfig: RentalConfig;
  rentalId: Scalars['ID'];
  rentalStepIds?: Maybe<Array<Scalars['ID']>>;
  rentedAsset?: Maybe<Asset>;
  resolvingLoop?: Maybe<RentalSearchDocumentLoop>;
  resolvingLoopId?: Maybe<Scalars['ID']>;
  status: RentalStatus;
  updatedAt: Scalars['String'];
};

export type RentalSearchDocumentCharge = {
  __typename?: 'RentalSearchDocumentCharge';
  chargeAmount?: Maybe<Scalars['Int']>;
  chargeId?: Maybe<Scalars['ID']>;
  chargeInfo?: Maybe<Scalars['String']>;
  chargeStatus?: Maybe<ChargeStatus>;
  consumerId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['ID']>;
  failedReason?: Maybe<ChargeOutcomeError>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type RentalSearchDocumentLoop = {
  __typename?: 'RentalSearchDocumentLoop';
  closedAt?: Maybe<Scalars['String']>;
  loopId: Scalars['ID'];
};

export type RentalSearchResults = {
  __typename?: 'RentalSearchResults';
  hitAggregations?: Maybe<Array<SearchHitTotals>>;
  rentals?: Maybe<Array<RentalSearchDocument>>;
  totalHits: Scalars['Int'];
};

export enum RentalStatus {
  Canceled = 'CANCELED',
  CanceledWithRefund = 'CANCELED_WITH_REFUND',
  Completed = 'COMPLETED',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  Forgiven = 'FORGIVEN',
  InProgress = 'IN_PROGRESS'
}

export type RentalStep = {
  __typename?: 'RentalStep';
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  rentalId: Scalars['ID'];
  rentalPhase: RentalPhase;
  rentalStepConfigId: Scalars['ID'];
  rentalStepConfigType?: Maybe<Scalars['String']>;
  status: RentalStepStatus;
  statusUpdatedAt: Scalars['String'];
  triggerAt: Scalars['String'];
};

export type RentalStepConfig = {
  createdAt: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  rentalPhase: RentalPhase;
  timing: ResolutionTiming;
  updatedAt: Scalars['String'];
};

export enum RentalStepStatus {
  CheckFailed = 'CHECK_FAILED',
  NotificationSent = 'NOTIFICATION_SENT',
  Pending = 'PENDING',
  Skipped = 'SKIPPED'
}

export type Resolution = {
  __typename?: 'Resolution';
  description?: Maybe<Scalars['String']>;
  label?: Maybe<ResolutionLabel>;
  metadata?: Maybe<Array<Maybe<KeyValue>>>;
  name?: Maybe<Scalars['String']>;
  resolutionId: Scalars['ID'];
  type: ResolutionOptions;
  useAgain?: Maybe<Scalars['Boolean']>;
};

export type ResolutionInput = {
  resolution?: InputMaybe<CreateResolutionInput>;
  resolutionId?: InputMaybe<Scalars['ID']>;
};

export enum ResolutionLabel {
  Charged = 'charged',
  Due = 'due',
  Fined = 'fined',
  Notified = 'notified',
  Overdue = 'overdue'
}

export enum ResolutionOption {
  LikeAssetType = 'LIKE_ASSET_TYPE',
  SameAsset = 'SAME_ASSET',
  SameAssetType = 'SAME_ASSET_TYPE'
}

export type ResolutionOptions = {
  __typename?: 'ResolutionOptions';
  likeAssetTypeResolution?: Maybe<LikeAssetTypeResolution>;
  sameAssetResolution?: Maybe<SameAssetResolution>;
  sameAssetTypeResolution?: Maybe<SameAssetTypeResolution>;
};

export type ResolutionOptionsInput = {
  likeAssetTypeResolution?: InputMaybe<LikeAssetTypeResolutionInput>;
  sameAssetResolution?: InputMaybe<SameAssetResolutionInput>;
  sameAssetTypeResolution?: InputMaybe<SameAssetTypeResolutionInput>;
};

export enum ResolutionStatus {
  Failed = 'failed',
  Pending = 'pending',
  Succeeded = 'succeeded'
}

export type ResolutionTiming = {
  __typename?: 'ResolutionTiming';
  duration?: Maybe<Scalars['Int']>;
  roundToTime?: Maybe<Scalars['String']>;
  roundToTimezone?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['String']>;
};

export type ResolutionTimingInput = {
  duration?: InputMaybe<Scalars['Int']>;
  roundToTime?: InputMaybe<Scalars['String']>;
  roundToTimezone?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['String']>;
};

export type ResolutionsPaginated = {
  __typename?: 'ResolutionsPaginated';
  nextToken?: Maybe<Scalars['String']>;
  results?: Maybe<Array<Maybe<Resolution>>>;
};

export type ReturnRate = {
  __typename?: 'ReturnRate';
  allTimeLoopsClosed?: Maybe<Scalars['Int']>;
  allTimeLoopsOpened?: Maybe<Scalars['Int']>;
  allTimeReturnRate?: Maybe<Scalars['Float']>;
  d30LoopsClosed?: Maybe<Scalars['Int']>;
  d30LoopsOpened?: Maybe<Scalars['Int']>;
  d30ReturnRate?: Maybe<Scalars['Float']>;
  d60LoopsClosed?: Maybe<Scalars['Int']>;
  d60LoopsOpened?: Maybe<Scalars['Int']>;
  d60ReturnRate?: Maybe<Scalars['Float']>;
  d90LoopsClosed?: Maybe<Scalars['Int']>;
  d90LoopsOpened?: Maybe<Scalars['Int']>;
  d90ReturnRate?: Maybe<Scalars['Float']>;
  lastUpdated?: Maybe<Scalars['String']>;
};

export type ReturnRateStat = {
  __typename?: 'ReturnRateStat';
  loopsClosed?: Maybe<Scalars['Int']>;
  loopsOpened?: Maybe<Scalars['Int']>;
  returnRate?: Maybe<Scalars['Float']>;
  window: StatWindow;
};

export type ReturnRateStats = {
  __typename?: 'ReturnRateStats';
  assetTypeId?: Maybe<Scalars['ID']>;
  isAvailable: Scalars['Boolean'];
  lastUpdated?: Maybe<Scalars['String']>;
  returnRates?: Maybe<Array<Maybe<ReturnRateStat>>>;
};

export type ReuseRateBucket = {
  __typename?: 'ReuseRateBucket';
  label: Scalars['String'];
  quantity: Scalars['Int'];
  rank: Scalars['Int'];
};

export type ReuseRateStats = {
  __typename?: 'ReuseRateStats';
  assetTypeId?: Maybe<Scalars['ID']>;
  avgUsesPerAsset?: Maybe<Scalars['Float']>;
  isAvailable: Scalars['Boolean'];
  lastUpdated?: Maybe<Scalars['String']>;
  mostUsedAssetCount?: Maybe<Scalars['Int']>;
  mostUsedAssetId?: Maybe<Scalars['ID']>;
  reuseRateBuckets?: Maybe<Array<Maybe<ReuseRateBucket>>>;
};

export type SameAssetResolution = {
  __typename?: 'SameAssetResolution';
  resolutionTiming: ResolutionTiming;
};

export type SameAssetResolutionInput = {
  resolutionTiming: ResolutionTimingInput;
};

export type SameAssetTypeResolution = {
  __typename?: 'SameAssetTypeResolution';
  resolutionTiming: ResolutionTiming;
};

export type SameAssetTypeResolutionInput = {
  resolutionTiming: ResolutionTimingInput;
};

export enum ScanAppUserAccountType {
  Group = 'group',
  Individual = 'individual'
}

export type ScanAppUserSettings = {
  __typename?: 'ScanAppUserSettings';
  accountType: ScanAppUserAccountType;
  allLocationsEnabled: Scalars['Boolean'];
  canChangeResolutions: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['String']>;
  enabledLocationIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  lastActivity?: Maybe<Scalars['String']>;
  selectedLocationId?: Maybe<Scalars['ID']>;
  selectedResolutionIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  status?: Maybe<ScanAppUserStatus>;
  userId: Scalars['ID'];
  username: Scalars['String'];
};

export type ScanAppUserSettingsPaginated = {
  __typename?: 'ScanAppUserSettingsPaginated';
  nextToken?: Maybe<Scalars['String']>;
  results?: Maybe<Array<Maybe<ScanAppUserSettings>>>;
};

export enum ScanAppUserStatus {
  Active = 'active',
  Inactive = 'inactive',
  Pending = 'pending'
}

export type SearchHitAggregation = {
  docCount: Scalars['Int'];
  key: Scalars['String'];
};

export type SearchHitSubtotals = SearchHitAggregation & {
  __typename?: 'SearchHitSubtotals';
  docCount: Scalars['Int'];
  key: Scalars['String'];
};

export type SearchHitTotals = SearchHitAggregation & {
  __typename?: 'SearchHitTotals';
  docCount: Scalars['Int'];
  key: Scalars['String'];
  subtotals?: Maybe<Array<SearchHitSubtotals>>;
};

export enum SearchRentalsFilter {
  ExpiredAt = 'expiredAt',
  UpdatedAt = 'updatedAt'
}

export enum StatWindow {
  AllTime = 'ALL_TIME',
  D_7 = 'D_7',
  D_14 = 'D_14',
  D_30 = 'D_30',
  D_60 = 'D_60',
  D_90 = 'D_90',
  M_3 = 'M_3',
  M_6 = 'M_6'
}

export type Station = {
  __typename?: 'Station';
  externalId: Scalars['ID'];
  name: Scalars['String'];
};

export enum TimeGranularity {
  Day = 'day'
}

export type UpdateAssetEventInput = {
  assetEventId: Scalars['ID'];
  assetId?: InputMaybe<Scalars['ID']>;
  assetOperationId?: InputMaybe<Scalars['ID']>;
  eventTimestamp?: InputMaybe<Scalars['String']>;
  locationId?: InputMaybe<Scalars['ID']>;
  metadata?: InputMaybe<Array<InputMaybe<KeyValueInput>>>;
};

export type UpdateAssetInput = {
  assetId: Scalars['String'];
  assetType?: InputMaybe<AssetTypeInput>;
  externalId?: InputMaybe<Scalars['ID']>;
  metadata?: InputMaybe<Array<InputMaybe<KeyValueInput>>>;
  value?: InputMaybe<Scalars['Int']>;
};

export type UpdateAssetOperationInput = {
  assetOperationId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  isVisible?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Array<InputMaybe<KeyValueInput>>>;
  name?: InputMaybe<Scalars['String']>;
  operationType?: InputMaybe<AssetOperationType>;
};

export type UpdateAssetTypeInput = {
  assetTypeId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['ID']>;
  metadata?: InputMaybe<Array<InputMaybe<KeyValueInput>>>;
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Int']>;
};

export type UpdateChargeOutcomeInput = {
  chargeId: Scalars['ID'];
  chargeInfo?: InputMaybe<Scalars['String']>;
  chargeStatus: ChargeStatus;
  error?: InputMaybe<ChargeOutcomeErrorInput>;
};

export type UpdateChargeRentalStepConfigInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  rentalPhase?: InputMaybe<RentalPhase>;
  timing?: InputMaybe<ResolutionTimingInput>;
};

export type UpdateConsumerInput = {
  chargeables?: InputMaybe<Array<InputMaybe<ChargeableInput>>>;
  consumerId: Scalars['ID'];
  email?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['ID']>;
  marketingOptIn?: InputMaybe<MarketingOptionsInput>;
  metadata?: InputMaybe<Array<InputMaybe<KeyValueInput>>>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  reuserId?: InputMaybe<Scalars['String']>;
};

export type UpdateIngredientInput = {
  costPerUnit?: InputMaybe<CostPerUomInput>;
  externalId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  metadata?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  source?: InputMaybe<MenuDataSource>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UpdateLocationInput = {
  address?: InputMaybe<AddressInput>;
  externalId?: InputMaybe<Scalars['ID']>;
  locationId: Scalars['ID'];
  metadata?: InputMaybe<Array<InputMaybe<KeyValueInput>>>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateMenuInput = {
  externalId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  ings?: InputMaybe<Array<InputMaybe<MenuOptionInput>>>;
  items?: InputMaybe<Array<InputMaybe<MenuOptionInput>>>;
  locationId?: InputMaybe<Scalars['ID']>;
  menuDate?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<MenuDataSource>;
  stationId?: InputMaybe<Scalars['ID']>;
};

export type UpdateMenuItemInput = {
  costPerUnit?: InputMaybe<CostPerUomInput>;
  externalId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  metadata?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  recipe?: InputMaybe<Array<InputMaybe<RecipeItemInput>>>;
  source?: InputMaybe<MenuDataSource>;
  tags?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateNotificationRentalStepConfigInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  notificationData?: InputMaybe<Scalars['String']>;
  notificationType?: InputMaybe<RentalNotificationType>;
  rentalPhase?: InputMaybe<RentalPhase>;
  timing?: InputMaybe<ResolutionTimingInput>;
};

export type UpdateOrganizationInstantForgivenessEnabledInput = {
  instantForgivenessEnabled?: InputMaybe<Scalars['Boolean']>;
  organizationId: Scalars['ID'];
};

export type UpdateOrganizationReturnReceivedForgivenessEnabledInput = {
  organizationId: Scalars['ID'];
  returnReceivedForgivenessEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateParentLocationInput = {
  address?: InputMaybe<AddressInput>;
  externalId?: InputMaybe<Scalars['ID']>;
  locationId: Scalars['ID'];
  metadata?: InputMaybe<Array<InputMaybe<KeyValueInput>>>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdatePhaseChangeRentalStepConfigInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  rentalPhase?: InputMaybe<RentalPhase>;
  timing?: InputMaybe<ResolutionTimingInput>;
};

export type UpdateRentalConfigInput = {
  description?: InputMaybe<Scalars['String']>;
  likeAssetTypeIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Scalars['String']>;
  rentalConfigId: Scalars['ID'];
  rentalStepConfigIds?: InputMaybe<Array<Scalars['ID']>>;
  resolutionOption?: InputMaybe<ResolutionOption>;
};

export type UpdateRentalPhaseInput = {
  phase: RentalPhase;
  rentalId: Scalars['ID'];
};

export type UpdateRentalStatusInput = {
  rentalId: Scalars['ID'];
  status: RentalStatus;
};

export type UpdateResolutionInput = {
  description?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<ResolutionLabel>;
  metadata?: InputMaybe<Array<InputMaybe<KeyValueInput>>>;
  name?: InputMaybe<Scalars['String']>;
  resolutionId: Scalars['ID'];
  type: ResolutionOptionsInput;
  useAgain?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateScanAppGroupUserPasswordInput = {
  globalSignOut: Scalars['Boolean'];
  password: Scalars['String'];
  userId: Scalars['ID'];
};

export type UpdateScanAppUserSettingsInput = {
  allLocationsEnabled?: InputMaybe<Scalars['Boolean']>;
  canChangeResolutions?: InputMaybe<Scalars['Boolean']>;
  enabledLocationIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  selectedLocationId?: InputMaybe<Scalars['ID']>;
  selectedResolutionIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  userId: Scalars['ID'];
};

export type UpdateWebhookInput = {
  endpoint?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<WebhookStatus>;
  webhookId: Scalars['ID'];
};

export type WasteObservation = Observation & {
  __typename?: 'WasteObservation';
  audio?: Maybe<AudioObservation>;
  bestLabel?: Maybe<RecognizableItem>;
  capturedBy: HardwareUnit;
  container?: Maybe<Container>;
  grossWeight?: Maybe<WeightObservation>;
  id: Scalars['ID'];
  images?: Maybe<Array<Maybe<ImageObservation>>>;
  labelSessions?: Maybe<Array<Maybe<ObservationLabel>>>;
  netWeight?: Maybe<WeightObservation>;
  observationType: ObservationType;
  station?: Maybe<Station>;
  statuses?: Maybe<ObservationStatuses>;
  tags?: Maybe<Array<Maybe<ObservationTag>>>;
  timestamp: Scalars['String'];
  value?: Maybe<ObservationCost>;
};

export type Webhook = {
  __typename?: 'Webhook';
  endpoint: Scalars['String'];
  event: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  status?: Maybe<WebhookStatus>;
  updatedAt?: Maybe<Scalars['String']>;
  webhookId: Scalars['ID'];
};

export enum WebhookStatus {
  Disabled = 'disabled',
  Enabled = 'enabled'
}

export type WeightObservation = {
  __typename?: 'WeightObservation';
  units: WeightUnit;
  weight: Scalars['Float'];
};

export type WeightObservationInput = {
  units: WeightUnit;
  weight: Scalars['Float'];
};

export enum WeightUnit {
  G = 'G',
  Kg = 'KG',
  Lbs = 'LBS',
  Oz = 'OZ'
}
