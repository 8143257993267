//components
import Text from "../../../common/Text";
import LinkText from "../../../common/LinkText";
import StyledInstructionText from "../common/StyledInstructionText";

//style
import styled, { useTheme } from "../../../../styling/styled-components";

//constants
import { MANUAL_FINE_COPY } from "../../../../constants/actionItems";

//images
import { ReactComponent as FineEligibleTable } from "../../../../assets/vectors/FineEligibleTable.svg";

const InstructionBorder = styled.div`
    display: flex;
    flex-direction: column;
    align-self: stretch;
    border-radius: 4px;
    background: ${({ theme }) => theme.colors.SMOG};
    margin-right: ${({ theme }) => theme.spacing.medium}px;
`;

const HeaderWrapper = styled.div`
    padding: ${({ theme }) => theme.spacing.medium}px;
    padding-bottom: ${({ theme }) => theme.spacing.small}px;
`;

const TableDescriptionWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const DescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.medium}px;
    padding: ${({ theme }) => theme.spacing.medium}px;
`;

const TableWrapper = styled.div``;

export default function ReportInstructions({}) {
    const { colors } = useTheme();

    return (
        <>
            <InstructionBorder>
                <HeaderWrapper>
                    <StyledInstructionText>
                        {MANUAL_FINE_COPY.ELIGIBLE_RENTALS}
                    </StyledInstructionText>
                </HeaderWrapper>
                <TableWrapper>
                    <FineEligibleTable height={170} width={440} />
                </TableWrapper>
                <TableDescriptionWrapper>
                    <Text size="small" type="body" color={colors.EXHAUST}>
                        {MANUAL_FINE_COPY.TABLE_DESCRIPTION}
                    </Text>
                </TableDescriptionWrapper>
                <DescriptionWrapper>
                    <StyledInstructionText>
                        {MANUAL_FINE_COPY.FINED_RENTALS}
                    </StyledInstructionText>
                    <StyledInstructionText>
                        {MANUAL_FINE_COPY.RENTALS_AVAILABILITY}
                    </StyledInstructionText>
                    <StyledInstructionText>
                        {MANUAL_FINE_COPY.QUESTIONS}
                        <LinkText
                            type="new_link"
                            size="small"
                            color={colors.EXHAUST}
                            href={"https://www.topanga.io/reusepass-academy"}
                        >
                            {"ReusePass Academy"}
                        </LinkText>
                    </StyledInstructionText>
                </DescriptionWrapper>
            </InstructionBorder>
        </>
    );
}
