import { format, parseISO } from "date-fns";

export const ACTIVE_CONSUMERS = {
    HEADER: "Active consumers",
    SUBHEADER:
        "Active consumers is defined by the consumers that checked-out or returned a container in the last 30 days. This data is not affected when the asset type filter is changed. ",
    CHART_HEADER: "Change in active consumers over time",
    TODAYS_CONSUMERS: "Today's active consumers",
    NET_CHANGE: (days: string) => `Net change for the ${days} `,
    DATE_INCREASE: "Day of most rapid net increase",
};

export const DASHBOARD_OVERVIEW = {
    WELCOME_MESSAGE: (slug: string) =>
        `Hey, ${slug}. Here's a look at how your reuse program is doing.`,
    WELCOME_DEFAULT:
        "Welcome back. Here’s a look at how your reuse program is doing.",
    CHECK_DATE_MESSAGE:
        "Check each module for the date range selected; if there is no indicated date range, assume the data represents all-time.",
    LOW_DATA: "There is not enough data. Check back soon.",
    LAST_UPDATED_TODAY: (date: string) =>
        date ? `Last updated today at ${date}` : "",
    LAST_UPDATED_YESTERDAY: (date: string) =>
        date ? `Last updated yesterday at ${date}` : "",
    LAST_UPDATED: (date: string) => (date ? date : ""),
};

export const RETURN_RATE_SUMMARY = {
    HEADER: (asset: string) =>
        asset ? `Return rate (${asset})` : "Return rate",
    SUBHEADER:
        "Return rate shows the % of assets that were checked out and have been returned, assuming 3-days have elapsed since checkout. This statistic is based on loops.",
};

export const CHECKOUTS_RETURN_SUMMARY = {
    HEADER: (asset: string) =>
        asset ? `Check-outs and returns (${asset})` : "Check-outs and returns",
    SUBHEADER:
        "View trends of check-out and return volume over time. Use the location filter to understand volume across locations.",
    CHECKOUT_LABEL: "Avg. check-outs (loops opened)",
    LOW_DATA: "Average of — / day (not enough data)",
    RETURN_LABEL: "Avg. returns (loops closed)",
    TOTAL_CHECKOUT_LABEL: (days: string) =>
        `Total check-outs for the last ${days}`,
    TOTAL_RETURNS_LABEL: (days: string) => `Total returns for the last ${days}`,
    AVERAGE_COUNT: (days: string) => `Average of ${days} / day`,
    CHART_TITLE: (days: string) =>
        `Total checkout-outs and returns for the last ${days}`,
    MINI_CHART_TITLE: (days: string) =>
        `Average volume by day of the week for the last ${days}`,
    ALL_LOCATIONS_NUM: (num: number) => `All locations (${num}) `,
    ALL_LOCATIONS: `All locations`,
    SELECTED_NUM_LOCATIONS: (num: number) => `${num} locations`,
    APPLY_SELECTION: "Apply selection",
    ERROR_NO_LOCATION: "You must select at least 1 location",
    NUM_LOCATIONS: (num: number) => `${num} locations selected`,
    Y_AXIS: "Average number of assets",
    OPEN_LOOPS_LABEL: "Check-outs (loops opened)",
    CLOSED_LOOPS_LABEL: "Returns (loops closed)",
    PERCENTAGE_LABEL: (date: string) => `from previous ${date}`,
};

export const MISSED_SCAN_SUMMARY = {
    SUBHEADER: "Missed scan-in rate",
    SCAN_STAT: (percentage: number) => `${percentage}%`,
    DESCRIPTION:
        "The missed scan-in rate is the percentage of assets over the last 30 days that weren't scanned in before being scanned out again - i.e. the container was returned, but the loop wasn't properly closed. A 0-8% range is ideal.",
    PREVIOUS_STAT: (days: string, percentage: number) =>
        `Compared to ${percentage}% ${days} ago`,
    IMPROVEMENT_DETAILS:
        "If your missed scan-in rate is higher than usual, we recommend reviewing your scan-in compliance and cycle rate to identify program optimizations.",
    ATTENTION_DETAILS:
        "If your missed scan-in rate is high, we recommend reviewing your scan-in compliance and cycle rate to identify program optimizations.",
};

export const ASSET_FILTERS_SUMMARY = {
    ALL_ASSETS: "All asset types",
    APPLY_SELECTION: "Apply selection",
};

export const REUSE_RATE_SUMMARY = {
    HEADER: (asset: string) => (asset ? `Reuse rate (${asset})` : "Reuse rate"),
    DESCRIPTION:
        "View the number of uses (loops closed) for assets that have at least one closed loop.",
    AVERAGE_USES: "Average uses/asset",
    MOST_USED: "Most used asset",
    X_AXIS: "Uses (loops closed) per asset",
    Y_AXIS: "Quantity of assets",
};

export const ASSET_USAGE_SUMMARY = {
    HEADER: (asset: string) =>
        asset ? `Available assets (${asset})` : "Available assets",
    DESCRIPTION:
        "Asset usage is informed by rental activity, or the data captured through the ScanApp and RFID readers.",
    SUB_DESCRIPTION:
        "The total number of assets is based on the quantity of QR and/or RFID tags that have been programmed for your organization.",
    ADDITIONAL_INSIGHTS_DESCPRIPTION:
        "Additional insights on assets and inventory can be found on the pro-tier monthly program report.",
    IN_CIRCULATION: "In circulation",
    BACK_STOCK: "Back-stock",
    TOTAL_DETAILS: "Available Assets",
    ACTIVE: "Active",
    NEVER_USED: "Never used",
    IN_CIRCULATION_DETAILS:
        "Checked-out (loop opened) at least once, not retired or lost",
    BACK_STOCK_DETAILS:
        "Unused QR/RFID tags (affixed and/or not affixed to an asset)",
};

export const DAYS_TO_RETURN_SUMMARY = {
    HEADER: (asset: string) =>
        asset ? `Days to return (${asset})` : "Days to return",
    SUBHEADER:
        "Days to return maps the amount of time (in days) it takes for assets to be returned (loop closed).",
    X_AXIS: "Days to return",
    Y_AXIS: "Percent of assets returned",
};

export const LOST_ASSETS_SUMMARY = {
    HEADER: (asset: string) =>
        asset ? `Lost assets (${asset})` : "Lost assets",
    SUBHEADER:
        "Assets are considered lost if they were checked-out (loop opened) 30+ days ago and have yet to be returned. The more volume (loops opened) you have, the more assets will likely be lost. Loss is normal, even with a high return rate.",
    LEARN_MORE: "Learn more at ",
    TOTAL: "Total lost",
    PERCENTAGE_LOST: (total: number, percentage: number) =>
        percentage >= 0 && percentage < 1
            ? `Out of ${total} total assets that entered circualtion (<1% loss)`
            : `Out of ${total} total assets that entered circualtion (${percentage}% loss)`,
};

export const RETIRED_ASSETS_SUMMARY = {
    HEADER: (asset: string) =>
        asset ? `Retired assets (${asset})` : "Retired assets",
    SUBHEADER:
        "Assets are retired through marking them as “Retired” with the ScanApp. Once this occurs, no further check-outs or other asset events will be tracked. Look for assets sitting broken at your location(s) and mark them as “Retired” using the ScanApp; this increases accuracy of your inventory counts.",
    TOTAL: "Total retired",
};

export type AssetDetails = {
    headerLabel: string;
    percentageDetails: string;
    assetTypeData: { label: string; bucketCount: number };
};
