//react
import { useEffect, useState, useRef } from "react";

//components
import Text from "./Text";
import DropdownLabel from "./dropdown/DropdownLabel";
import DropdownOption from "./dropdown/DropdownOption";
import Displayable from "./Displayable";
import FlyoutForm from "./dropdown/FlyoutForm";
import { ClipLoader } from "react-spinners";

//styling
import styled, { useTheme } from "../../styling/styled-components";
//images
import { ReactComponent as BlueVerticalKebab } from "../../assets/vectors/BlueVerticalKebab.svg";
import { ReactComponent as WhiteVerticalKebab } from "../../assets/vectors/WhiteVerticalKebab.svg";

//props
import { FilterOption } from "./Filter";

//utils
import useClickOutside from "../../utils/useClickOutside";

const DropdownText = styled(Text)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: ${({ theme }) => theme.spacing.tiny}px;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
`;

const ButtonWrapper = styled.div`
    position: relative;
`;

const IconWrapper = styled.div`
    position: relative;
    top: ${({ theme }) => -theme.spacing.tiny}px;
    padding-left: ${({ theme }) => theme.spacing.tiny}px;
`;

const DropdownWrapper = styled.div`
    padding-top: ${({ theme }) => theme.spacing.medium}px;
    padding-bottom: ${({ theme }) => theme.spacing.medium}px;
`;

type Props = {
    options: FilterOption[];
    buttonLabel?: string;
    onClickOpenOverlay: () => null;
};

export default function ActionsDropdown({
    options,
    buttonLabel,
    onClickOpenOverlay,
}: Props) {
    const { colors } = useTheme();
    const [popUpOptions, setPopUpOptions] = useState([...options]);
    const [isOpen, setIsOpen] = useState(false);
    let textColor = isOpen ? colors.BLANK_SLATE : colors.WAVE_STORM;
    const inLoadingState = options?.some((obj) => obj.status == "disabled");

    //refs click outside
    const dropdownRef = useRef("date-picker-dropdown-button");
    const flyoutRef = useRef("date-picker-flyout");

    const handleToggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    useClickOutside(
        flyoutRef,
        () => {
            handleToggleDropdown();
        },
        [dropdownRef]
    );

    useEffect(() => {
        if (isOpen) {
            setPopUpOptions(options);
        }
    }, [isOpen]);

    const renderDropdownOptions = () => {
        return (
            <Displayable displayPrimaryComponent={isOpen}>
                <div ref={flyoutRef}>
                    <FlyoutForm alignRight={true} borderRadius={"4px"}>
                        <DropdownWrapper>
                            {popUpOptions.map((option, idx) => {
                                return (
                                    <DropdownOption
                                        onClick={() => onClickOpenOverlay(idx)}
                                        key={option.label}
                                        label={option.label}
                                        status={option.status}
                                    />
                                );
                            })}
                        </DropdownWrapper>
                    </FlyoutForm>
                </div>
            </Displayable>
        );
    };

    const BlueIcon = () => {
        return (
            <IconWrapper>
                <BlueVerticalKebab />
            </IconWrapper>
        );
    };

    const DropdownLabelText = () => {
        return (
            <DropdownText type="label" size="small" color={textColor}>
                <Displayable displayPrimaryComponent={inLoadingState}>
                    <ClipLoader size={12} color={textColor} />
                </Displayable>
                {buttonLabel}
                <Displayable
                    displayPrimaryComponent={isOpen}
                    SecondaryComponent={BlueIcon}
                >
                    <IconWrapper>
                        <WhiteVerticalKebab />
                    </IconWrapper>
                </Displayable>
            </DropdownText>
        );
    };

    return (
        <>
            <ButtonWrapper onClick={handleToggleDropdown} ref={dropdownRef}>
                <DropdownLabel isOpen={isOpen} label={<DropdownLabelText />} />
                {renderDropdownOptions()}
            </ButtonWrapper>
        </>
    );
}
