export const SPACING_ALIAS = {
    TINY: "tiny",
    XSMALL: "xsmall",
    SMALL: "small",
    MEDIUM: "medium",
    LARGE: "large",
    XLARGE: "xlarge",
    XMLARGE: "xmlarge",
    XXLARGE: "xxlarge",
    HUGE: "huge",
    YUGE: "yuge",
};

export const SPACING = {
    [SPACING_ALIAS.TINY]: 3,
    [SPACING_ALIAS.XSMALL]: 6,
    [SPACING_ALIAS.SMALL]: 12,
    [SPACING_ALIAS.MEDIUM]: 18,
    [SPACING_ALIAS.LARGE]: 24,
    [SPACING_ALIAS.XLARGE]: 30,
    [SPACING_ALIAS.XMLARGE]: 36,
    [SPACING_ALIAS.XXLARGE]: 48,
    [SPACING_ALIAS.HUGE]: 60,
    [SPACING_ALIAS.YUGE]: 120,
};
