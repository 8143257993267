//react
import { useState, useEffect } from "react";

//components
import Text from "../../common/Text";
import ParentLocationDetails from "./ParentLocationDetails";
import HighlightHover from "../../common/HighlightHover";
import Overlay from "../../common/overlay/Overlay";
import { StyledLineDivider } from "../common/StyledLineDivider";

//style
import styled, { useTheme } from "../../../styling/styled-components";

//api
import * as queries from "../../../graphql/queries";
import { useQuery } from "../../../graphql/hooks/query";

//images
import { ReactComponent as RightArrow } from "../../../assets/vectors/RightArrow.svg";

const HeaderDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    flex: 1 0 0;
    width: 444px;
    padding-bottom: ${({ theme }) => theme.spacing.large}px;
`;

const HeaderText = styled(Text)`
    padding-left: ${({ theme }) => theme.spacing.xxlarge}px;
    width: 100%;
`;

const ParentLocationDiv = styled.div`
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.small}px;
    align-self: stretch;
    padding-bottom: ${({ theme }) => theme.spacing.small}px;
    cursor: pointer;
`;

const ParentLocationTextDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const WrapperDiv = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const ArrowHoverDiv = styled.div`
    display: flex;
    justify-content: flex-end;
    width: ${({ theme }) => theme.spacing.xmlarge}px;
    position: relative;
    top: ${({ theme }) => -theme.spacing.medium}px;
`;
const ArrowDiv = styled.div`
    margin-top: auto;
    padding-right: ${({ theme }) => theme.spacing.xmlarge}px;
`;

const ScanningLocationText = styled(Text)`
    padding-top: ${({ theme }) => theme.spacing.small}px;
    padding-left: ${({ theme }) => theme.spacing.xxlarge}px;
    align-self: stretch;
`;

type Props = {
    handleCloseParentOverlay: () => null;
    parentLocationId: String;
    onClickOpenScanning: () => null;
    onClickOpenAddScan: () => null;
    toggleArchiveModal: () => null;
    overlayOpen: boolean;
};

export default function ParentLocationOverlay({
    handleCloseParentOverlay,
    parentLocationId,
    onClickOpenScanning,
    onClickOpenEditParent,
    onClickOpenAddScan,
    toggleArchiveModal,
    overlayOpen,
}: Props) {
    const { colors } = useTheme();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [
        getParentLocation,
        getParentLocationData,
        getParentLocationLoading,
        getParentLocationErrors,
    ] = useQuery(queries.getParentLocation);

    useEffect(() => {
        getParentLocation({ locationId: parentLocationId });
        if (getParentLocationData) {
            setDataLoaded(true);
        }
    }, [parentLocationId, overlayOpen]);

    useEffect(() => {
        if (getParentLocationData) {
            setDataLoaded(true);
        }
    }, [getParentLocationData]);

    const returnFormattedAddress = () => {
        if (getParentLocationData.address) {
            const addressDetails = [
                getParentLocationData.address.line1,
                getParentLocationData.address.line2,
                getParentLocationData.address.line3,
            ].filter(Boolean);
            const formattedAddress = addressDetails.join(", ");
            if (!formattedAddress) {
                return "No address added";
            } else {
                return formattedAddress;
            }
        } else {
            return "No address added";
        }
    };

    return (
        <>
            {dataLoaded && (
                <>
                    <Overlay
                        overlayOpen={overlayOpen}
                        onClickCloseOverlay={handleCloseParentOverlay}
                        onClickedOutside={handleCloseParentOverlay}
                    >
                        <HeaderDiv>
                            <HeaderText
                                type="header"
                                size="large"
                                color={colors.DEEP_BLUE_SEA}
                            >
                                {getParentLocationData.name}
                            </HeaderText>
                        </HeaderDiv>
                        <StyledLineDivider />
                        <ParentLocationDiv onClick={onClickOpenEditParent}>
                            <ParentLocationTextDiv>
                                <HeaderText
                                    type="body"
                                    size="medium"
                                    color={colors.EXHAUST}
                                >
                                    Parent Location Details
                                </HeaderText>
                                <HeaderText
                                    type="label"
                                    size="medium"
                                    color={colors.DEEP_BLUE_SEA}
                                >
                                    {getParentLocationData.name}
                                </HeaderText>
                                <WrapperDiv>
                                    <HeaderText
                                        type="label"
                                        size="medium"
                                        color={colors.DEEP_BLUE_SEA}
                                    >
                                        {returnFormattedAddress()}
                                    </HeaderText>
                                    <ArrowHoverDiv>
                                        <ArrowDiv>
                                            <HighlightHover>
                                                <RightArrow></RightArrow>
                                            </HighlightHover>
                                        </ArrowDiv>
                                    </ArrowHoverDiv>
                                </WrapperDiv>
                            </ParentLocationTextDiv>
                        </ParentLocationDiv>
                        <StyledLineDivider />
                        <ScanningLocationText
                            type="label"
                            size="small"
                            color={colors.ASH}
                        >
                            SCANNING LOCATIONS (
                            {getParentLocationData.locations.length})
                        </ScanningLocationText>
                        <ParentLocationDetails
                            onClickOpenScanning={onClickOpenScanning}
                            getParentLocationData={getParentLocationData}
                            onClickOpenAddScan={onClickOpenAddScan}
                            toggleArchiveModal={toggleArchiveModal}
                        ></ParentLocationDetails>
                    </Overlay>
                </>
            )}
        </>
    );
}
