export const getEligibleRentalCount = /* GraphQL */ `
    query SearchRentals(
        $fromTimestamp: String!
        $toTimestamp: String!
        $filter: SearchRentalsFilter
        $withCharges: Boolean
        $rentalPhases: [RentalPhase!]
        $rentalStatuses: [RentalStatus!]
    ) {
        searchRentals(
            fromTimestamp: $fromTimestamp
            toTimestamp: $toTimestamp
            filter: $filter
            withCharges: $withCharges
            rentalPhases: $rentalPhases
            rentalStatuses: $rentalStatuses
        ) {
            totalHits
        }
    }
`;
