//react
import { useState, useEffect, useContext } from "react";

//context
import GlobalContext from "../../../../contexts/GlobalContext";

//components
import ManualFineDescriptions from "./ManualFineDescriptions";
import ConfirmationBlock from "./ConfirmationBlock";
import NumberEligibleRentals from "./NumberEligibleRentals";
import AccordionLabel from "../../../common/accordion/AccordionLabel";
import ReportInstructions from "./ReportInstructions";
import OverlayButton from "../../../common/overlay/OverlayButton";
import DividerWrapper from "../../../common/overlay/DividerWrapper";
import Displayable from "../../../common/Displayable";
import UpdateSubmittedRentals from "./UpdateSubmittedRentals";
import ManualFineError from "./ManualFineError";
import NoEligibleRentals from "./NoEligibleRentals";
import Errorable from "../../../common/Errorable";
import Loadable from "../../../common/Loadable";
import { ClipLoader } from "react-spinners";
import Text from "../../../common/Text";

//style
import styled, { useTheme } from "../../../../styling/styled-components";

//constants
import { MANUAL_FINE_COPY } from "../../../../constants/actionItems";

//apis
import useGraphClient from "../../../../hooks/useGraphClient";
import * as mutations from "../../../../graphql/mutations";
import { getCountOfEligibleManualFiningRentals } from "../../../../utils/activity/getCountOfEligibleManualFiningRentals";
import * as queries from "../../../../graphql/queries";

//utils
import { getLatestExportTimestamp } from "../../../../utils/activity/getLatestExportTimestamp";

const OverlayContextWrapper = styled.div`
    padding-left: ${({ theme }) => theme.spacing.xmlarge}px;
`;

const LoadingWrapper = styled.div`
    padding-top: 50%;
    padding-left: 50%;
`;

const LoadingLabel = styled(Text)`
    padding-left: 40%;
`;

export default function GenerateReportDetails({}) {
    const { colors } = useTheme();
    const context = useContext(GlobalContext);
    const { username } = context.globalSelections;
    const [fromTimestamp, setFromTimestamp] = useState(
        "2024-10-21T00:00:00.000000Z"
    );
    const [loading, setLoading] = useState(false);
    const [screenLoading, setScreenLoading] = useState(true);

    //accept terms
    const [termsAccept, setTermsAccepted] = useState(false);
    const buttonStatus = termsAccept ? "default" : "disabled";
    const [submissionConfirmed, setSubmissionConfirmed] = useState(false);

    //export data
    const [newExportDetails, setNewExportDetails] = useState();
    const [eligibleRentalsCount, setEligibleRentalsCount] = useState();
    const [newExport, setNewExport] = useState({});
    const [errorCreatingExport, setErrorCreatingExport] = useState(false);
    const [listAllExports, setListAllExports] = useState();
    const [showNoEligibleRentalsScreen, setShowNoEligibleRentalsScreen] =
        useState(false);
    const [clickedCreateExport, setClickCreateExport] = useState(false);

    const [exportInterval, setExportInterval] = useState();

    const hasPendingStatus = listAllExports?.some(
        (obj) => obj.status == "PENDING"
    );
    const graphClient = useGraphClient();

    useEffect(() => {
        getAllExports();
    }, []);

    useEffect(() => {
        if (Object.keys(newExport).length > 0 && listAllExports?.length > 0) {
            let findNewExportTimeout = setTimeout(() => {
                findNewExportDetailsAndConfirmSubmission(listAllExports);
            }, 1000);
            return () => clearTimeout(findNewExportTimeout);
        }
    }, [listAllExports]);

    useEffect(() => {
        if (fromTimestamp && !clickedCreateExport) {
            getCountOfEligibleManualFiningRentals(
                fromTimestamp,
                graphClient
            ).then((result) => {
                setEligibleRentalsCount(result);
            });
            let screenLoadingTimeout = setTimeout(() => {
                setScreenLoading(false);
            }, 2000);
            return () => clearTimeout(screenLoadingTimeout);
        }
    }, [fromTimestamp]);

    const createNewExport = async (): null => {
        try {
            const results = await graphClient.graphql({
                query: mutations.createExport,
                variables: {
                    email: username,
                    fromTimestamp: fromTimestamp,
                },
            });
            const data = results?.data?.createExport;
            setNewExport(data);
        } catch (err) {
            setErrorCreatingExport(true);
            console.error("Error creating new export", err);
        }
    };

    const getAllExports = async (): null => {
        try {
            const results = await graphClient.graphql({
                query: queries.listExports,
            });
            const data = results?.data?.listExports;
            setListAllExports(data);
            if (data) {
                setLatestExportTimestamp(data);
            }
        } catch (err) {
            console.error("Error getting export list", err);
        }
    };

    const setLatestExportTimestamp = (data) => {
        const latestExportTimestamp = getLatestExportTimestamp(data);
        setFromTimestamp(latestExportTimestamp || fromTimestamp);
    };

    const findNewExportDetailsAndConfirmSubmission = (exportList) => {
        exportList?.map((item, index) => {
            if (item?.exportId == newExport?.exportId) {
                setNewExportDetails(item);
                setLoading(false);
                setSubmissionConfirmed(true);
                let intervalCallListExports;
                if (item.status == "COMPLETE") {
                    intervalCallListExports = setInterval(
                        () => console.log("set completed card"),
                        12000
                    );
                    setExportInterval(intervalCallListExports);
                    clearInterval(exportInterval);
                    return;
                }
                if (item.status == "PENDING") {
                    intervalCallListExports = setInterval(getAllExports, 12000);
                    setExportInterval(intervalCallListExports);
                    return;
                }
            }
            return;
        });
    };

    const handleCheckBoxClicked = () => {
        setTermsAccepted(!termsAccept);
    };

    const confirmRentalSubmissionAndGetList = () => {
        createNewExport();
        setLoading(true);
        setClickCreateExport(true);
        let getAllExportsTimeout = setTimeout(() => {
            getAllExports();
        }, 1000);
        return () => clearTimeout(getAllExportsTimeout);
    };

    const accordionText = {
        default: "Show instructions",
        open: "Hide instructions",
    };

    const DownloadNewReport = () => {
        return <UpdateSubmittedRentals newExportDetails={newExportDetails} />;
    };

    const LoadingButton = () => {
        return (
            <OverlayButton
                buttonStatus={"disabled"}
                buttonText={<ClipLoader size={20} color={colors.WAVE_STORM} />}
            />
        );
    };

    const Spinner = () => {
        return (
            <>
                <LoadingWrapper>
                    <ClipLoader size={30} color={colors.WAVE_STORM} />
                </LoadingWrapper>
                <LoadingLabel size="small" type="label" color={colors.ONYX}>
                    {MANUAL_FINE_COPY.LOADING}
                </LoadingLabel>
            </>
        );
    };

    const handleNavigation = () => {
        if (!hasPendingStatus && !errorCreatingExport) {
            setShowNoEligibleRentalsScreen(true);
        } else {
            window.location.reload();
        }
    };

    const ManualFineErrorScreen = () => {
        return <ManualFineError handleNavigation={handleNavigation} />;
    };

    return (
        <Errorable
            error={errorCreatingExport && !showNoEligibleRentalsScreen}
            ErrorComponent={ManualFineErrorScreen}
        >
            <Loadable dataLoaded={!screenLoading} LoadingComponent={Spinner}>
                <Displayable
                    displayPrimaryComponent={
                        eligibleRentalsCount > 0 && !showNoEligibleRentalsScreen
                    }
                    SecondaryComponent={NoEligibleRentals}
                >
                    <OverlayContextWrapper>
                        <Displayable
                            displayPrimaryComponent={!submissionConfirmed}
                            SecondaryComponent={DownloadNewReport}
                        >
                            <ManualFineDescriptions />
                            <AccordionLabel accordionText={accordionText}>
                                <ReportInstructions />
                            </AccordionLabel>
                            <DividerWrapper />
                            <NumberEligibleRentals
                                eligibleRentalsCount={eligibleRentalsCount}
                                mostRecentTimestamp={fromTimestamp}
                            />
                            <DividerWrapper />
                            <ConfirmationBlock
                                onToggleCheckbox={handleCheckBoxClicked}
                            />
                            <Loadable
                                dataLoaded={!loading}
                                LoadingComponent={LoadingButton}
                            >
                                <OverlayButton
                                    buttonStatus={buttonStatus}
                                    buttonText={
                                        MANUAL_FINE_COPY.CONFIRM_BUTTON_TEXT
                                    }
                                    onClick={confirmRentalSubmissionAndGetList}
                                />
                            </Loadable>
                        </Displayable>
                    </OverlayContextWrapper>
                </Displayable>
            </Loadable>
        </Errorable>
    );
}
