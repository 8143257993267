//components
import Text from "../../../common/Text";
import NumberOfEligibleRentals from "./NumberEligibleRentals";
import StyledInstructionText from "../common/StyledInstructionText";
import DividerWrapper from "../../../common/overlay/DividerWrapper";
import NoEligibleReasons from "./NotEligibleReasons";
import SmallLinkText from "../common/SmallLinkText";
import OverlayContentWrapper from "../common/OverlayContentWrapper";

//style
import styled, { useTheme } from "../../../../styling/styled-components";

//constants
import { MANUAL_FINE_COPY } from "../../../../constants/actionItems";

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    padding-bottom: ${({ theme }) => theme.spacing.medium}px;
`;

const SubheaderWrapper = styled.div`
    padding-bottom: ${({ theme }) => theme.spacing.medium}px;
`;

const QuestionWrapper = styled.div`
    padding-top: ${({ theme }) => theme.spacing.small}px;
`;

export default function NoEligibleRentals({}) {
    const { colors } = useTheme();

    const HaveQuestions = () => {
        return (
            <QuestionWrapper>
                <StyledInstructionText>
                    {MANUAL_FINE_COPY.QUESTIONS}
                    <SmallLinkText
                        textCopy={"ReusePass Academy"}
                        href={"https://www.topanga.io/reusepass-academy"}
                    />
                </StyledInstructionText>
            </QuestionWrapper>
        );
    };

    return (
        <OverlayContentWrapper>
            <HeaderWrapper>
                <Text size="large" type="header" color={colors.DEEP_BLUE_SEA}>
                    {MANUAL_FINE_COPY.NOT_ELIGIBLE_RENTALS_HEADER}
                </Text>
                <SubheaderWrapper>
                    <StyledInstructionText>
                        {MANUAL_FINE_COPY.NOT_ELIGIBLE_REASONS}
                    </StyledInstructionText>
                </SubheaderWrapper>
                <NoEligibleReasons />
                <HaveQuestions />
            </HeaderWrapper>
            <DividerWrapper />
            <NumberOfEligibleRentals eligibleRentalCount={0} />
            <DividerWrapper />
        </OverlayContentWrapper>
    );
}
