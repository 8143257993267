export const FAILED_CARD = {
    HEADER: "Rental failed",
    CARD_DETAILS:
        "The consumer failed to return the asset before the rental period expired and may be subject to a penalty.",
};

export const FINED_ELIGIBLE_STRINGS = {
    HEADER: "This rental is eligible for a fine.",
    DESCRIPTION: `To mark this rental as fined and update the current phase from "Expired" to "Fined (manual)," go to the Actions menu and click "Generate fine report."`,
};

export const FINED_MANUAL_STRINGS = {
    HEADER: "This rental was manually marked as fined. To learn more, note the date this rental was marked as “Fined (manual)” and go to ",
    DESCRIPTION:
        "Updating rentals to the “Fined (manual)” phase in the dashboard does not automatically issue fines.",
    CARD_LABEL_MANUAL: "Fined (manual)",
};

export const FINED_AUTOMATIC_STRINGS = {
    HEADER: "A fine was successfully issued via a third-party platform.",
    DESCRIPTION:
        "If you would like to refund this fine, click the link above to cancel the rental. Then follow the steps to request a refund for the consumer.",
    CARD_LABEL_AUTOMATIC: "Fined (automatic)",
    REFUND_SUCCESS: "This rental was successfully refunded",
};

export const FINE_ERROR_STRINGS = {
    HEADER: "Fine error",
    FAILED_TEXT: "Failed to execute fine",
    FAILED_REASON: (reason: string) =>
        `An automatic fine was attempted, but failed due to ${reason}.`,
};

export const FORGIVEN_CARD_STRINGS = {
    HEADER: "Rental forgiven",
    INSTANT_FORGIVENESS:
        "This rental was automatically forgiven because Instant Forgiveness was turned on.",
    RETURN_RECEIVED_FORGIVENESS:
        "This rental was automatically forgiven because a return was received while Return Received Forgiveness was turned on.",
    POLICY_LINK: "View current policy",
};

export const EXPIRED_CARD_STRINGS = {
    HEADER: "Expired",
    DESCRIPTION: `The asset has not been received and the rental period has expired.`,
};
