//components
import Button from "../Button";
import Text from "../Text";

//style
import styled, { useTheme } from "../../../styling/styled-components";

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: ${({ theme }) => theme.spacing.xxlarge}px;
`;

type Props = {
    buttonStatus: string;
    buttonText: string;
    onClick: () => void;
};

export default function OverlayButton({
    buttonStatus,
    buttonText,
    onClick,
}: Props) {
    const { colors } = useTheme();

    return (
        <ButtonWrapper>
            <Button
                onClick={onClick}
                status={buttonStatus}
                label={
                    <Text type="label" size="large" color={colors.BLANK_SLATE}>
                        {buttonText}
                    </Text>
                }
            ></Button>
        </ButtonWrapper>
    );
}
