//components
import StyledInstructionText from "../common/StyledInstructionText";

//style
import styled from "../../../../styling/styled-components";

//constants
import { VIEW_REPORTS_COPY } from "../../../../constants/actionItems";

const NoReportsWrapper = styled.div`
    display: flex;
    padding: ${({ theme }) => theme.spacing.medium}px
        ${({ theme }) => theme.spacing.large}px;
    flex-direction: column;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    align-self: stretch;
    border-radius: 4px;
    background: ${({ theme }) => theme.colors.SMOG};
    margin-top: ${({ theme }) => theme.spacing.medium}px;
`;

export default function NoReportsAvailable({}) {
    return (
        <NoReportsWrapper>
            <StyledInstructionText>
                {VIEW_REPORTS_COPY.NO_REPORTS}
            </StyledInstructionText>
        </NoReportsWrapper>
    );
}
