//components
import Text from "../../../common/Text";
import { ClipLoader } from "react-spinners";
import DownloadOption from "./DownloadOption";
import Loadable from "../../../common/Loadable";

//style
import styled, { useTheme } from "../../../../styling/styled-components";

//constants
import { VIEW_REPORTS_COPY } from "../../../../constants/actionItems";
import { Export } from "../../../../API";

//apis
import useGraphClient from "../../../../hooks/useGraphClient";
import * as queries from "../../../../graphql/queries";

//react
import { useEffect, useState } from "react";
import { ReactNode } from "react";

//images
import { ReactComponent as SMSMessageWavestorm } from "../../../../assets/vectors/SMSMessageWavestorm.svg";

const CardWrapper = styled.div`
    display: flex;
    padding: ${({ theme }) => theme.spacing.medium}px
        ${({ theme }) => theme.spacing.large}px
        ${({ theme }) => theme.spacing.large}px
        ${({ theme }) => theme.spacing.large}px;
    flex-direction: column;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.MARINE_LAYER};
`;

const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    align-self: stretch;
    justify-content: space-between;
    padding-bottom: ${({ theme }) => theme.spacing.tiny}px;
`;

const ProgressWrapper = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing.small}px;
`;

type Props = {
    key: string;
    exportDetails: Export;
    completeState: Boolean;
};
export default function ReportCard({
    key,
    exportDetails,
    completeState,
}: Props) {
    const { colors } = useTheme();
    const graphClient = useGraphClient();
    const [exportData, setExportData] = useState({});

    useEffect(() => {
        getExport();
    }, [exportDetails?.exportId]);

    const getExport = async (): null => {
        try {
            const results = await graphClient.graphql({
                query: queries.getExport,
                variables: {
                    exportId: exportDetails?.exportId,
                },
            });
            const data = results?.data?.getExport;
            setExportData(data);
        } catch (err) {
            console.error("Error getting export list", err);
        }
    };

    const CardDetailsText = ({ children }: ReactNode) => {
        return (
            <Text size="medium" type="body" color={colors.ROCK_BOTTOM}>
                {children}
            </Text>
        );
    };

    const PendingExport = () => {
        return (
            <ProgressWrapper>
                <ClipLoader size={20} color={colors.WAVE_STORM} />
                <Text size="medium" type="header" color={colors.ROCK_BOTTOM}>
                    {`Updating ${exportDetails?.initialRentalsCount} rentals...`}
                </Text>
            </ProgressWrapper>
        );
    };

    const CompleteExport = () => {
        return (
            <Loadable
                dataLoaded={completeState}
                LoadingComponent={PendingExport}
            >
                <ProgressWrapper>
                    <SMSMessageWavestorm />
                    <Text
                        size="medium"
                        type="header"
                        color={colors.ROCK_BOTTOM}
                    >
                        {`${exportDetails?.finalRentalsCount} rentals fined`}
                    </Text>
                </ProgressWrapper>
            </Loadable>
        );
    };

    return (
        <CardWrapper key={key}>
            <HeaderWrapper>
                <Loadable
                    dataLoaded={
                        exportDetails?.status == "COMPLETE" && !completeState
                    }
                    LoadingComponent={CompleteExport}
                >
                    <Text
                        size="medium"
                        type="header"
                        color={colors.ROCK_BOTTOM}
                    >
                        {`${exportDetails?.finalRentalsCount} rentals fined`}
                    </Text>
                </Loadable>
                <DownloadOption
                    expirationDate={exportData?.expiration}
                    status={exportDetails?.status}
                    downloadUrl={exportData?.url}
                />
            </HeaderWrapper>
            <div>
                <CardDetailsText>
                    {exportDetails
                        ? VIEW_REPORTS_COPY.CREATED_AT(exportDetails?.createdAt)
                        : ""}
                </CardDetailsText>
                <CardDetailsText>{`By ${exportDetails?.email}`}</CardDetailsText>
            </div>
            <CardDetailsText>
                {exportDetails
                    ? VIEW_REPORTS_COPY.DATE_RANGE(
                          exportDetails?.fromTimestamp,
                          exportDetails?.toTimestamp
                      )
                    : "-"}
            </CardDetailsText>
        </CardWrapper>
    );
}
