type Props = {
    overlay: String;
    prevState: Object;
    updatedOverlayStates: Object;
};

export const toggleOverlay = (overlay, prevState): Props => {
    const updatedOverlayStates = {};

    for (const key in prevState) {
        updatedOverlayStates[key] = key === overlay ? !prevState[key] : false;
    }
    return updatedOverlayStates;
};
