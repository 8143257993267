//components
import Text from "../../../common/Text";
import StyledInstructionText from "../common/StyledInstructionText";
import ReportCard from "../downloadReport/ReportCard";

//style
import styled, { useTheme } from "../../../../styling/styled-components";

//constants
import { MANUAL_FINE_COPY } from "../../../../constants/actionItems";
import { Export } from "../../../../API";

//react
import { useEffect, useState } from "react";

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    padding-bottom: ${({ theme }) => theme.spacing.medium}px;
`;

const DetailsWrapper = styled.div`
    padding-top: ${({ theme }) => theme.spacing.small}px;
`;

const ReportCardWrapper = styled.div`
    padding-top: ${({ theme }) => theme.spacing.large}px;
    padding-bottom: ${({ theme }) => theme.spacing.large}px;
`;

export default function UpdateSubmittedRentals({ newExportDetails }: Export) {
    const { colors } = useTheme();
    const [completeState, setCompleteState] = useState(false);

    useEffect(() => {
        if (newExportDetails.status == "COMPLETE") {
            setCompleteState(true);
        }
    }, [newExportDetails]);

    return (
        <HeaderWrapper>
            <Text size="large" type="header" color={colors.DEEP_BLUE_SEA}>
                {MANUAL_FINE_COPY.SUBMISSION_CONFIRMED}
            </Text>
            <StyledInstructionText>
                {MANUAL_FINE_COPY.PENDING_UPDATES}
            </StyledInstructionText>
            <DetailsWrapper>
                <StyledInstructionText>
                    {MANUAL_FINE_COPY.DOWNLOAD_DETAILS}
                </StyledInstructionText>
            </DetailsWrapper>
            <ReportCardWrapper>
                <ReportCard
                    key={newExportDetails?.exportId}
                    exportDetails={newExportDetails}
                    completeState={completeState}
                />
            </ReportCardWrapper>
            <Text size="medium" type="body" color={colors.EXHAUST}>
                {MANUAL_FINE_COPY.RENTAL_NUMBER_DISCLAIMER}
            </Text>
        </HeaderWrapper>
    );
}
