//components
import Overlay from "../../common/overlay/Overlay";
import ReportHeader from "./downloadReport/ReportHeader";
import NoReportsAvailable from "./downloadReport/NoReportsAvailable";
import OverlayContentWrapper from "./common/OverlayContentWrapper";
import ReportCard from "./downloadReport/ReportCard";
import Displayable from "../../common/Displayable";

//constants
import { OverlayProps } from "../../../constants/overlay";

//apis
import useGraphClient from "../../../hooks/useGraphClient";
import * as queries from "../../../graphql/queries";

//react
import { useEffect, useState } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import queryString from "query-string";

//style
import styled from "../../../styling/styled-components";

const CardWrapper = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    flex-direction: column;
`;

export default function ViewReportOverlay({
    onClickCloseOverlay,
}: OverlayProps) {
    const [exportListData, setExportListData] = useState([]);
    let [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const queryParams = queryString.parse(location.search);

    const graphClient = useGraphClient();

    const closeOverlayAndClearParams = () => {
        onClickCloseOverlay();
        searchParams.delete("viewReports");
        setSearchParams(searchParams);
    };

    useEffect(() => {
        getExportList();
    }, []);

    const getExportList = async (): null => {
        try {
            const results = await graphClient.graphql({
                query: queries.listExports,
            });
            const data = results?.data?.listExports;
            setExportListData(data);
        } catch (err) {
            console.error("Error getting export list", err);
        }
    };

    return (
        <Overlay
            overlayOpen={queryParams.viewReports == "1"}
            onClickedOutside={closeOverlayAndClearParams}
            onClickCloseOverlay={closeOverlayAndClearParams}
            scroll={"scroll"}
        >
            <OverlayContentWrapper>
                <ReportHeader count={exportListData?.length} />
                <Displayable
                    displayPrimaryComponent={exportListData?.length > 0}
                    SecondaryComponent={NoReportsAvailable}
                >
                    <CardWrapper>
                        {exportListData?.map((details, idx) => {
                            return (
                                <ReportCard
                                    key={details?.exportId}
                                    exportDetails={details}
                                />
                            );
                        })}
                    </CardWrapper>
                </Displayable>
            </OverlayContentWrapper>
        </Overlay>
    );
}
