//react
import React, {
    useState,
    forwardRef,
    useImperativeHandle,
    useRef,
} from "react";
import { useSearchParams, useLocation } from "react-router-dom";

//components
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import ClipLoader from "react-spinners/ClipLoader";
import Text from "../common/Text";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

//images
import { ReactComponent as Search } from "../../assets/vectors/Search.svg";
import { ReactComponent as Close } from "../../assets/vectors/Close.svg";
import { ReactComponent as FilterDownCaret } from "../../assets/vectors/FilterDownCaret.svg";

//styles
import styled from "styled-components/macro";
import { useTheme } from "../../styling/styled-components";

//utils
import { prettifyUID } from "../../utils/helpers";
import queryString from "query-string";
import useClickOutside from "../../utils/useClickOutside";

const Container = styled.div`
    display: flex;
    width: 280px;
`;

const StyledPopper = styled(Popper)`
    z-index: 1000;
`;

const TagPill = styled.span`
    background-color: ${({ theme }) => theme.colors.MARINE_LAYER};
    padding: 0 ${({ theme }) => theme.spacing.xsmall}px;
    border-radius: 4px;
    margin-right: ${({ theme }) => theme.spacing.small}px;
    display: flex;
    width: fit-content;
    cursor: pointer;
`;

const TagAdornment = styled(Text)`
    padding-left: ${({ theme }) => theme.spacing.xsmall}px;
`;

const PopoverContent = styled.div`
    padding: ${({ theme }) => theme.spacing.small}px
        ${({ theme }) => theme.spacing.xlarge}px;
    @media only screen and (max-width: 990px) {
        padding: ${({ theme }) => theme.spacing.small}px 20px;
        margin-right: 20px;
    }
`;

const StyledTextField = styled(TextField)`
    flex: 1;
    .MuiInputBase-root {
        font-family: poppins;
        background: white;
        height: 36px;
        font-size: 11px;
        line-height: 16px;
        font-weight: 500;
        letter-spacing: 0.01em;
        &:hover {
            background-color: ${({ theme }) => theme.colors.SEAGLASS};
        }
        &:focus-within {
            background-color: ${({ theme }) => theme.colors.SEAGLASS};
            .MuiOutlinedInput-notchedOutline {
                border: 1px solid ${({ theme }) => theme.colors.PLASTIC_BOTTLE};
            }
        }
    }
    .MuiOutlinedInput-notchedOutline {
        border: none;
    }
`;

const TagRow = styled.div`
    display: -webkit-box;
    margin-bottom: ${({ theme }) => theme.spacing.small}px;
`;

const SearchLoader = styled.div`
    margin-right: 4px;
`;

const TagText = styled(Text)``;

const SearchText = styled(Text)`
    padding: ${({ theme }) => theme.spacing.xsmall}px 0px;
`;

const ExpandText = styled(Text)`
    cursor: pointer;
`;

export type SearchTag = {
    value: string;
    label: string;
};

type Props = {
    onChange: (term: string) => void;
    loading?: boolean;
    value: string;
    clearSearchTerm: () => void;
    searchTags: SearchTag[];
    showTags?: boolean;
    tagRows: number[];
    className?: string;
    separatefortablet?: string;
    consumerDetail?: string;
    assetId?: string;
};

const EventSearch = (
    {
        onChange,
        loading,
        value,
        clearSearchTerm,
        searchTags,
        tagRows,
        separatefortablet = "false",
        showTags = true,
        consumerDetail,
        assetId,
        className,
    }: Props,
    ref: HTMLDivElement
) => {
    const [searchTerm, setSearchTerm] = useState(value || "");
    const [consumerDetailProp, setConsumerDetailProp] = useState(
        consumerDetail || ""
    );
    const [updateConsumerProp, setUpdateConsumerProp] = useState("");
    const [searchTag, setSearchTag] = useState<SearchTag | null>(null);
    const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);
    const [expandedPopover, setExpandedPopover] = useState(false);
    const textFieldRef = React.useRef();
    const { colors } = useTheme();
    const open = Boolean(anchorEl);
    const [selectedText, setSelectedText] = useState("");
    let [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const queryParams = queryString.parse(location.search);
    const propValue = queryParams.prop;
    const closeButtonRef = useRef(null);
    const popUpRef = useRef(null);

    const handleSearchTerm = () => {
        if (!searchTag || searchTag.label !== "PhoneNumber") {
            return searchTerm;
        } else {
            return modifyString(searchTerm);
        }
    };

    function modifyString(str: string): string {
        /**
         * The search string can have multiple search values in it,
         * and other parts of the search string we do not want to strip the
         * phone number from. This match makes sure we only modify the first phone
         * number we see when the phone number tag is used.
         **/
        const match = str.match(/^[\d\s()\-+]*/);

        if (match) {
            // Remove spaces, dashes and parenthesis from the matched sequence
            const cleaned = match[0].replace(/[^\d]/g, "");

            // Return the modified string with the cleaned sequence and the rest of the original string
            return "*" + cleaned + "* " + str.slice(match[0].length);
        } else {
            // If there is no match, return the original string
            return str;
        }
    }

    const handleSearch = () => {
        onChange(
            searchTag
                ? searchTag.value + ":" + handleSearchTerm()
                : searchTerm || consumerDetailProp || updateConsumerProp
        );
        handleBlur();
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === "Enter") {
            handleSearch();
        } else if (
            event.key === "Backspace" &&
            (!searchTerm || searchTerm.length == 1)
        ) {
            cancelSearch();
        } else if (event.key === "Backspace" && selectedText == searchTerm) {
            cancelSearch();
        }
    };

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        setAnchorEl(event.target.parentNode);
    };

    const handleBlur = () => {
        setAnchorEl(null);
        setExpandedPopover(false);
        textFieldRef.current?.blur();
    };

    const handleDeleteProp = () => {
        if (propValue) {
            searchParams.delete("prop");
            setSearchParams(searchParams);
        }
    };

    useClickOutside(closeButtonRef, () => {
        handleDeleteProp();
    });

    useClickOutside(popUpRef, () => {
        setAnchorEl(null);
        setExpandedPopover(false);
    });

    const cancelSearch = () => {
        clearSearchTerm();
        setSearchTag(null);
        setSearchTerm("");
        setConsumerDetailProp("");
        setUpdateConsumerProp("");
        onChange(null);
    };

    const handleStartAdornmentClick = (
        event: React.MouseEvent<HTMLElement>
    ) => {
        textFieldRef.current?.focus();
    };

    const handlePopoverClick = (event: React.MouseEvent<HTMLElement>) => {
        textFieldRef.current?.focus();
    };

    const renderTag = (tag) => (
        <TagPill
            key={tag.value}
            onClick={() => {
                setSearchTag(tag);
                textFieldRef.current?.focus();
            }}
        >
            <TagText type="body" size="tiny" color={colors.BLACK}>
                {tag.label}:
            </TagText>
        </TagPill>
    );

    function renderTags(tags: SearchTag[]) {
        const firstTwo = tags.slice(0, 2);
        const nextThree = tags.slice(2, 5);
        const rest = tags.slice(5);

        const rows = [];
        let currentIndex = 0;

        for (let i = 0; i < tagRows.length; i++) {
            const children = [];

            for (let j = 0; j < tagRows[i]; j++) {
                children.push(tags[currentIndex]);
                currentIndex++;
            }
            rows.push(children);
        }
        const restOfTags =
            currentIndex + 1 >= tags.length ? [] : tags.slice(currentIndex);

        return (
            <div>
                {rows.map((row, index) => (
                    <TagRow key={`${index}.renderTag`}>
                        {row.map((tag) => renderTag(tag))}
                    </TagRow>
                ))}
                {expandedPopover ? (
                    restOfTags.map((tag, index) => (
                        <TagRow key={`${tag}-${index}`}>
                            {renderTag(tag)}
                        </TagRow>
                    ))
                ) : (
                    <TagRow
                        key={currentIndex}
                        onClick={() => setExpandedPopover(true)}
                    >
                        <ExpandText
                            type="body"
                            size="tiny"
                            color={colors.WAVE_STORM}
                        >
                            Show all queries <FilterDownCaret />
                        </ExpandText>
                    </TagRow>
                )}
            </div>
        );
    }

    const popoverContent = () => {
        if (searchTerm.length || !showTags) {
            return (
                <PopoverContent>
                    <SearchText type="body" size="tiny" color={colors.ASH}>
                        Tap "Enter" to submit your search
                    </SearchText>
                </PopoverContent>
            );
        } else if (searchTag) {
            return (
                <PopoverContent>
                    <SearchText type="body" size="tiny" color={colors.ASH}>
                        Enter a {searchTag.label}
                    </SearchText>
                </PopoverContent>
            );
        } else {
            return (
                <PopoverContent>
                    <SearchText type="body" size="tiny" color={colors.ASH}>
                        Or, use a query to narrow your search:
                    </SearchText>
                    {renderTags(searchTags)}
                </PopoverContent>
            );
        }
    };

    const placeholderContent = () => {
        if (consumerDetailProp || updateConsumerProp) {
            return "";
        } else if (!searchTag) {
            return "Start typing to search all fields";
        } else if (searchTag.label === "PhoneNumber") {
            return "x(xxx)-xxx-xxxx";
        } else {
            return "";
        }
    };

    useImperativeHandle(ref, () => ({
        setSearchTag,
        setSearchTerm,
        setConsumerDetailProp,
        setUpdateConsumerProp,
    }));

    return (
        <Container ref={closeButtonRef} className={className}>
            <StyledTextField
                placeholder={placeholderContent()}
                InputProps={{
                    startAdornment: (
                        <InputAdornment
                            onClick={handleStartAdornmentClick}
                            position="start"
                        >
                            <Search />
                            {consumerDetailProp && assetId ? (
                                <TagAdornment
                                    type="label"
                                    size="small"
                                    color={colors.BLACK}
                                >
                                    {"AssetID" +
                                        ": " +
                                        prettifyUID(consumerDetailProp)}
                                </TagAdornment>
                            ) : null}
                            {consumerDetailProp &&
                            !updateConsumerProp &&
                            !assetId ? (
                                <TagAdornment
                                    type="label"
                                    size="small"
                                    color={colors.BLACK}
                                >
                                    {"AssetID" +
                                        ": " +
                                        prettifyUID(consumerDetailProp)}
                                </TagAdornment>
                            ) : null}
                            {!updateConsumerProp ? null : (
                                <TagAdornment
                                    type="label"
                                    size="small"
                                    color={colors.BLACK}
                                >
                                    {"ConsumerID" +
                                        ": " +
                                        prettifyUID(updateConsumerProp)}
                                </TagAdornment>
                            )}
                            {searchTag ? (
                                <TagAdornment
                                    type="label"
                                    size="small"
                                    color={colors.BLACK}
                                >
                                    {searchTag.label + ":"}
                                </TagAdornment>
                            ) : null}
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            {loading && (
                                <SearchLoader>
                                    <ClipLoader
                                        color={colors.WAVE_STORM}
                                        size={20}
                                        loading={loading}
                                    />
                                </SearchLoader>
                            )}
                            {searchTag ||
                            searchTerm ||
                            consumerDetailProp ||
                            updateConsumerProp ||
                            assetId ? (
                                <Close
                                    style={{ cursor: "pointer" }}
                                    onClick={() => cancelSearch()}
                                />
                            ) : null}
                        </InputAdornment>
                    ),
                }}
                variant="outlined"
                autoComplete={"off"}
                value={searchTerm}
                onChange={(event) => setSearchTerm(event.target.value)}
                onKeyDown={handleKeyDown}
                onFocus={handleFocus}
                inputRef={textFieldRef}
                styledwidth={"75vw"}
                separatefortablet={separatefortablet}
                onMouseUp={() =>
                    setSelectedText(window.getSelection().toString())
                }
                className={className}
            />
            <StyledPopper
                open={open}
                anchorEl={anchorEl}
                placement="bottom-start"
                className={className}
            >
                <Paper onClick={handlePopoverClick} ref={popUpRef}>
                    {popoverContent()}
                </Paper>
            </StyledPopper>
        </Container>
    );
};

export default forwardRef(EventSearch);
