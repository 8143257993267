//react
import React, { useState, useEffect } from "react";

//styles
import styled, { css, useTheme } from "../../styling/styled-components";

//components
import Loadable from "../common/Loadable";
import Footer from "./common/Footer";
import CheckoutReturnStats from "./checkoutreturns/CheckoutReturnStats";
import DatePicker from "../common/DatePicker";
import Text from "../common/Text";
import LocationFilter from "./checkoutreturns/LocationFilter";
import Errorable from "../common/Errorable";
import ErrorBlock from "./common/ErrorBlock";
import ChartSpinner from "./common/ChartSpinner";
import LoopActivity from "../common/charts/LoopActivity";
import MiniBarChart from "./checkoutreturns/MiniBarChart";

//apis
import { useQuery } from "../../graphql/hooks/query";
import * as queries from "../../graphql/queries";

//constants
import { CHECKOUTS_RETURN_SUMMARY } from "../../constants/dashboard";
import { STAT_WINDOW_ITEMS, DATE_RANGE_ITEMS } from "./checkoutreturns/options";
import { AssetType } from "./common/Types";

//utils
import { addDays } from "date-fns";
import { checkStatWindowValue } from "../../utils/chart/checkStatWindowValue";
import { checkLastUpdatedDate } from "../../utils/chart/checkLastUpdatedDate";

export type Props = {
    asset: AssetType;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const Body = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: ${({ theme }) => theme.spacing.small}px;
    ${({ theme: { isMobile, isTablet } }) =>
        (isMobile || isTablet) &&
        css`
            flex-direction: column-reverse;
        `}
`;

const Chart = styled.div`
    padding-right: ${({ theme }) => theme.spacing.medium}px;
    width: 100%;
    ${({ theme: { isMobile, isTablet } }) =>
        (isMobile || isTablet) &&
        css`
            flex: 1;
            flex-basis: auto;
            width: 100%;
            min-height: 250px;
        `}
`;

const StatsDiv = styled.div`
    padding-top: ${({ theme }) => theme.spacing.xxlarge}px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    ${({ theme: { isMobile, isTablet } }) =>
        (isMobile || isTablet) &&
        css`
            flex-direction: column;
            display: flex;
            padding-top: ${({ theme }) => theme.spacing.xmlarge}px;
            margin-left: ${({ theme }) => -theme.spacing.small}px;
        `}
`;

const Wrapper = styled.div`
    padding-left: ${({ theme }) => theme.spacing.large}px;
`;

const ChartTitle = styled(Text)`
    padding-left: ${({ theme }) => theme.spacing.small}px;
`;

const LineChartWrapper = styled.div`
    height: 400px;
    cursor: pointer;
`;

const FilterWrapper = styled.div`
    display: inline-flex;
    align-items: flex-start;
`;

const DropdownDiv = styled.div`
    display: inline-flex;
    align-items: flex-start;
    margin-left: auto;
    gap: ${({ theme }) => theme.spacing.small}px;
`;

const HeaderText = styled(Text)`
    padding-top: ${({ theme }) => theme.spacing.large}px;
    padding-left: ${({ theme }) => theme.spacing.small}px;
`;

const SubheaderText = styled(Text)`
    padding-left: ${({ theme }) => theme.spacing.small}px;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing.small}px;
`;

const KeyContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
`;

const KeyCodeCircle = styled.div`
    ${({ theme }) =>
        css`
            width: 12px;
            height: 12px;
            background-color: ${(props) => props.backgroundColor};
            border-radius: 50%;
            align-items: center;
            margin-top: ${({ theme }) => theme.spacing.tiny}px;
        `};
`;

const KeyWrapper = styled.div`
    display: flex;
    margin-left: auto;
    gap: ${({ theme }) => theme.spacing.small}px;
    ${({ theme: { isMobile, isTablet } }) =>
        (isMobile || isTablet) &&
        css`
            flex-direction: column;
            gap: ${({ theme }) => theme.spacing.tiny}px;
        `}
`;

export default function CheckoutReturnsSummary({ asset }: Props) {
    const [labels, setLabels] = useState([]);
    const [datasets, setDatasets] = useState([]);
    const lowEmptyDataSet = [0, 0, 0, 0, 0, 0, 160];
    const { colors, spacing } = useTheme();
    const assetLabel = asset.name == "All asset types" ? "" : asset.name;
    const [loopStats, setLoopStats] = useState({});
    const [filterOptions, setFilterOptions] = useState({});

    //date filter
    const [dateWindow, setDateWindow] = useState(STAT_WINDOW_ITEMS[0].label);

    const today = new Date();
    const defaultDaysBack = STAT_WINDOW_ITEMS[0].value;
    const defaultRange = addDays(today, defaultDaysBack);
    const [dateRange, setDateRange] = useState([
        today,
        defaultRange,
        defaultDaysBack,
    ]);

    //location filter
    const [parentLocationIds, setParentLocationIds] = useState(null);

    const [
        queryStatCheckoutsReturns,
        queryStatCheckoutsReturnsData,
        queryStatCheckoutsReturnsLoading,
        queryStatCheckoutsReturnsErrors,
    ] = useQuery(queries.getStatCheckoutsReturns);

    const handleChangeDate = (isRelative, newDaysBack, range) => {
        const daysAgo = addDays(today, newDaysBack);
        const selectedDate = STAT_WINDOW_ITEMS.find(
            (date) => date.value == newDaysBack
        );

        if (selectedDate) {
            setDateWindow(selectedDate.label);
            setDateRange([today, daysAgo, newDaysBack]);
        }
    };

    const handleChangeLocation = (ids) => {
        if (ids) {
            const filterIds = ids.filter((value) => value !== null);
            setParentLocationIds(filterIds);
        } else {
            setParentLocationIds(ids);
        }
    };

    const handleLoopPercentageStats = (calculations) => {
        setLoopStats(calculations);
    };

    useEffect(() => {
        loadData();
    }, [dateWindow, parentLocationIds, asset]);

    useEffect(() => {
        updateChartData();
    }, [queryStatCheckoutsReturnsData]);

    const loadData = () => {
        queryStatCheckoutsReturns({
            window: dateWindow,
            assetTypeId: asset.assetTypeId,
            parentLocationIds: parentLocationIds,
        });
        setFilterOptions({
            window: dateRange,
            assetTypeId: asset.assetTypeId,
            parentLocationIds: parentLocationIds,
        });
    };

    const updateChartData = () => {
        if (
            queryStatCheckoutsReturnsData &&
            queryStatCheckoutsReturnsData.isAvailable
        ) {
            const parsedLoopCountData = queryStatCheckoutsReturnsData?.avgByDay;

            const labelsArray = parsedLoopCountData.map((result) => {
                return result.dayOfWeek.substring(0, 3);
            });

            const avgCheckoutsOpened = parsedLoopCountData.map((result) => {
                return result.avgLoopsOpened;
            });

            const avgReturnsClosed = parsedLoopCountData.map((result) => {
                return result.avgLoopsClosed;
            });
            setLabels(labelsArray);
            setDatasets([
                {
                    label: CHECKOUTS_RETURN_SUMMARY.CHECKOUT_LABEL,
                    data: avgCheckoutsOpened,
                    backgroundColor: colors.SUMMER_STORM,
                    borderRadius: 4,
                    maxBarThickness: spacing.medium,
                    barPercentage: 0.8,
                    categoryPercentage: 0.5,
                    hoverBackgroundColor: colors.HOVER_SUMMER_STORM,
                },
                {
                    label: CHECKOUTS_RETURN_SUMMARY.RETURN_LABEL,
                    data: avgReturnsClosed,
                    backgroundColor: colors.DEEP_BLUE_SEA,
                    borderRadius: 4,
                    maxBarThickness: spacing.medium,
                    barPercentage: 0.8,
                    categoryPercentage: 0.5,
                    hoverBackgroundColor: colors.HOVER_DEEP_BLUE_SEA,
                },
            ]);
        } else {
            setLabels([]);
            setDatasets([
                {
                    label: CHECKOUTS_RETURN_SUMMARY.CHECKOUT_LABEL,
                    data: lowEmptyDataSet,
                    backgroundColor: colors.SUMMER_STORM,
                },
                {
                    label: CHECKOUTS_RETURN_SUMMARY.RETURN_LABEL,
                    data: lowEmptyDataSet,
                    backgroundColor: colors.DEEP_BLUE_SEA,
                },
            ]);
        }
    };

    let lastUpdated = queryStatCheckoutsReturnsData?.lastUpdated;

    const StyledErrorComponent = () => {
        return (
            <Wrapper>
                <ErrorBlock />
            </Wrapper>
        );
    };

    const ChartColorKey = ({ color, label }) => {
        return (
            <KeyContainer>
                <KeyCodeCircle backgroundColor={color} />
                <Text type="label" size="medium" color={colors.BLACK}>
                    {label}
                </Text>
            </KeyContainer>
        );
    };

    const ChartHeader = () => {
        return (
            <TitleContainer>
                <ChartTitle type="label" size="medium" color={colors.BLACK}>
                    {CHECKOUTS_RETURN_SUMMARY.CHART_TITLE(
                        checkStatWindowValue(
                            queryStatCheckoutsReturnsData?.statWindow,
                            queryStatCheckoutsReturnsData?.statWindow[0]
                        )
                    )}
                </ChartTitle>
                <KeyWrapper>
                    <ChartColorKey
                        color={colors.MOUNTAIN_HAZE}
                        label={CHECKOUTS_RETURN_SUMMARY.OPEN_LOOPS_LABEL}
                    />
                    <ChartColorKey
                        color={colors.DEEP_BLUE_SEA}
                        label={CHECKOUTS_RETURN_SUMMARY.CLOSED_LOOPS_LABEL}
                    />
                </KeyWrapper>
            </TitleContainer>
        );
    };

    //keep charts rendered in the main return function to avoid infinite loops
    return (
        <Container>
            <FilterWrapper>
                <HeaderText type="header" size="medium" color={colors.BLACK}>
                    {CHECKOUTS_RETURN_SUMMARY.HEADER(assetLabel)}
                </HeaderText>
                <DropdownDiv>
                    <LocationFilter
                        handleChangeLocation={handleChangeLocation}
                    />
                    <DatePicker
                        processDateChange={handleChangeDate}
                        initDaysBack={defaultDaysBack}
                        dateRangeItems={DATE_RANGE_ITEMS}
                    />
                </DropdownDiv>
            </FilterWrapper>
            <SubheaderText type="body" size="medium" color={colors.EXHAUST}>
                {CHECKOUTS_RETURN_SUMMARY.SUBHEADER}
            </SubheaderText>
            <Errorable
                error={queryStatCheckoutsReturnsErrors}
                ErrorComponent={StyledErrorComponent}
            >
                <Body>
                    <Chart>
                        <ChartHeader />
                        <Loadable
                            dataLoaded={queryStatCheckoutsReturnsData}
                            LoadingComponent={ChartSpinner}
                        >
                            <LineChartWrapper>
                                <LoopActivity
                                    dateRange={dateRange}
                                    filterOptions={filterOptions}
                                    handleLoopPercentageStats={(calculation) =>
                                        handleLoopPercentageStats(calculation)
                                    }
                                />
                            </LineChartWrapper>
                        </Loadable>
                        <StatsDiv>
                            <CheckoutReturnStats
                                data={queryStatCheckoutsReturnsData}
                                loopStats={loopStats}
                            />
                            <MiniBarChart
                                chartTitle={CHECKOUTS_RETURN_SUMMARY.MINI_CHART_TITLE(
                                    checkStatWindowValue(
                                        queryStatCheckoutsReturnsData?.statWindow,
                                        queryStatCheckoutsReturnsData
                                            ?.statWindow[0]
                                    )
                                )}
                                labels={labels}
                                datasets={datasets}
                            />
                        </StatsDiv>
                    </Chart>
                </Body>
            </Errorable>
            <Wrapper>
                <Footer
                    loading={queryStatCheckoutsReturnsLoading}
                    lastUpdated={checkLastUpdatedDate(lastUpdated)}
                />
            </Wrapper>
        </Container>
    );
}
