//style
import { useTheme } from "../../../styling/styled-components";

//components
import Card from "../../common/card/Card";
import Text from "../../common/Text";
import CardHeader from "./common/CardHeader";
import FinedWrapper from "../../common/card/FineWrapper";
import SmallLinkText from "../../activity/actions/common/SmallLinkText";

//utils
import { colorChange } from "./utils/helpers";
import { checkRentalStatus } from "../../../utils/rentalOverlay/checkRentalStatus";

//constants
import { FINED_MANUAL_STRINGS } from "../../../constants/rentalOverlay/rentalCards";

export default function FinedManualAutomaticCard({ rentalConfigStep }) {
    const { colors } = useTheme();
    const openReportsPage = () => {
        window.location.href =
            "/dashboard/activity/rentals/" + `?viewReports=1`;
    };

    return (
        <Card
            backgroundColor={colorChange(
                checkRentalStatus(rentalConfigStep),
                colors
            )}
            borderColor={"1px solid var(--greys-ash, #1745cf)"}
        >
            <CardHeader
                header={FINED_MANUAL_STRINGS.CARD_LABEL_MANUAL}
                date={rentalConfigStep?.statusUpdatedAt}
                colorHeader={colors.ROCK_BOTTOM}
                colorDate={colors.ASH}
            />
            <FinedWrapper>
                <Text type="body" size="medium" color={colors.ROCK_BOTTOM}>
                    {FINED_MANUAL_STRINGS.HEADER}
                    <SmallLinkText
                        onClick={openReportsPage}
                        textCopy="Reports"
                    />
                    .
                </Text>

                <Text type="body" size="tiny" color={colors.ASH}>
                    {FINED_MANUAL_STRINGS.DESCRIPTION}
                </Text>
            </FinedWrapper>
        </Card>
    );
}
