import {
    Maybe,
    MachineObservationLabel,
    Observation,
    ObservationLabel,
} from "../../../API";

export default function useObservationLabelSessions(
    observation: Observation
): [
    ObservationLabel | undefined,
    Maybe<MachineObservationLabel>,
    string | undefined
] {
    let lastLabel;
    let lastMachineLabel;
    let lastLabeledAt;

    if (
        observation &&
        Array.isArray(observation.labelSessions) &&
        observation.labelSessions.length > 0
    ) {
        lastLabel = observation.labelSessions[0];
        lastLabeledAt = lastLabel?.labelTimestamp || null;

        lastMachineLabel = observation.labelSessions.find(
            (session) => session.labelType === "MACHINE"
        );
    }

    return [lastLabel, lastMachineLabel, lastLabeledAt];
}
