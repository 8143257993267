//components
import Text from "../../../common/Text";
import StyledInstructionText from "../common/StyledInstructionText";

//style
import styled, { useTheme } from "../../../../styling/styled-components";

//constants
import { VIEW_REPORTS_COPY } from "../../../../constants/actionItems";

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    padding-bottom: ${({ theme }) => theme.spacing.medium}px;
`;

type Props = { count: Number };

export default function ReportHeader({ count }: Props) {
    const { colors } = useTheme();

    return (
        <HeaderWrapper>
            <Text size="large" type="header" color={colors.DEEP_BLUE_SEA}>
                {`Reports (${count})`}
            </Text>
            <StyledInstructionText>
                {VIEW_REPORTS_COPY.HISTORY_REPORTS}
            </StyledInstructionText>
        </HeaderWrapper>
    );
}
