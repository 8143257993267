//style
import styled, { useTheme } from "../../../styling/styled-components";

//components
import DividerWrapper from "../../common/overlay/DividerWrapper";

const Wrapper = styled.div`
    padding-left: ${({ theme }) => theme.spacing.xxlarge}px;
    padding-top: ${({ theme }) => theme.spacing.tiny}px;
`;

export function StyledLineDivider() {
    return (
        <Wrapper>
            <DividerWrapper />
        </Wrapper>
    );
}
