import { AssetOperationType, RentalPhase } from "../API";

export const ASSET_OPERATION_LABELS = {
    [`${AssetOperationType.Fill}`]: "Filled",
    [`${AssetOperationType.CreateLoop}`]: "Loop opened",
    [`${AssetOperationType.CloseLoop}`]: "Loop closed",
    [`${AssetOperationType.Retire}`]: "Retired",
    [`${AssetOperationType.Clean}`]: "Washed",
    [`${AssetOperationType.Stock}`]: "Restocked",
};

export const ASSET_EVENT_LIST = {
    DATE: "Date",
    EVENT: "Event",
    STATUS: "Status",
    ASSET_ID: "Asset ID",
    LOCATION: "Location",
    CONSUMER: "Consumer",
};

export const RENTAL_LIST = {
    LAST_UPDATED: "Last updated",
    RENTAL_ID: "Rental ID",
    ASSET_RENTED: "Asset type rented",
    CONSUMER: "Consumer",
    LAST_PHASE: "Current phase",
    STATUS: "Rental status",
};

export const phaseStatusChargingReleaseDate = new Date(
    "2024-08-07T00:00:00.000Z"
);
