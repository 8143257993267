//components
import StyledInstructionText from "../common/StyledInstructionText";
import SmallLinkText from "../common/SmallLinkText";

//style
import styled from "../../../../styling/styled-components";

//constants
import { MANUAL_FINE_COPY } from "../../../../constants/actionItems";

//utils
import { handleLinkForgivenessPageNavigation } from "../../../../utils/settings/handleLinkForgivenessPageNavigation";

const ReasonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.tiny}px;
`;

export default function NotEligibleReasons({}) {
    const openReportsPage = () => {
        window.location.href =
            "/dashboard/activity/rentals/" + `?viewReports=1`;
    };

    return (
        <>
            <ReasonsWrapper>
                <StyledInstructionText>
                    {MANUAL_FINE_COPY.NOT_ELIGIBLE_REASON_1}
                    <SmallLinkText
                        onClick={openReportsPage}
                        textCopy="Reports"
                    />
                    {MANUAL_FINE_COPY.NOT_ELIGIBLE_REASON_2}
                </StyledInstructionText>
                <StyledInstructionText>
                    {MANUAL_FINE_COPY.NOT_ELIGIBLE_REASON_3}
                </StyledInstructionText>
                <StyledInstructionText>
                    {MANUAL_FINE_COPY.NOT_ELIGIBLE_REASON_4}
                    <SmallLinkText
                        onClick={handleLinkForgivenessPageNavigation}
                        textCopy="rental forgiveness policy"
                    />
                    {MANUAL_FINE_COPY.NOT_ELIGIBLE_REASON_5}
                </StyledInstructionText>
                <StyledInstructionText>
                    {MANUAL_FINE_COPY.NOT_ELIGIBLE_REASON_6}
                </StyledInstructionText>
            </ReasonsWrapper>
        </>
    );
}
