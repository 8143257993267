import { ReactElement } from "react";
import styled, { css } from "../../../styling/styled-components";

const StyledFlyoutForm = styled.form`
    border-radius: ${(props) => props.borderRadius};
    background-color: ${({ theme }) => theme.colors.BLANK_SLATE};
    box-shadow: 0px 4px 10px rgba(22, 35, 80, 0.27);
    display: flex;
    position: absolute;
    width: auto;
    z-index: 20000;
    ${({ alignRight }) =>
        !alignRight
            ? css``
            : css`
                  right: 0px;
              `};
`;

const ChildrenWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

type Props = {
    onSubmit: () => void;
    alignRight: boolean;
    borderRadius: string;
    children: ReactElement;
};

export default function FlyoutForm({
    onSubmit,
    alignRight = false,
    borderRadius = "0px",
    children,
}: Props) {
    return (
        <StyledFlyoutForm
            onSubmit={onSubmit}
            alignRight={alignRight}
            borderRadius={borderRadius}
        >
            <ChildrenWrapper>{children}</ChildrenWrapper>
        </StyledFlyoutForm>
    );
}
