//components
import Overlay from "../../common/overlay/Overlay";
import GenerateReportDetails from "./generateReport/GenerateReportDetails";

//constants
import { OverlayProps } from "../../../constants/overlay";

//react
import { useSearchParams, useLocation } from "react-router-dom";
import queryString from "query-string";

export default function ManualFineOverlay({
    onClickCloseOverlay,
}: OverlayProps) {
    let [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const queryParams = queryString.parse(location.search);

    const closeOverlayAndRemoveSearch = () => {
        onClickCloseOverlay();
        searchParams.delete("generateReport");
        setSearchParams(searchParams);
    };

    return (
        <Overlay
            overlayOpen={queryParams.generateReport == "1"}
            onClickedOutside={closeOverlayAndRemoveSearch}
            onClickCloseOverlay={closeOverlayAndRemoveSearch}
            scroll={"scroll"}
        >
            <GenerateReportDetails />
        </Overlay>
    );
}
