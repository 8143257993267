//components
import Text from "../../../common/Text";
import SmallLinkText from "../common/SmallLinkText";
import OverlayContentWrapper from "../common/OverlayContentWrapper";
import { ReactComponent as Warning } from "../../../../assets/vectors/Warning.svg";
import StyledInstructionText from "../common/StyledInstructionText";

//style
import styled, { useTheme } from "../../../../styling/styled-components";

//constants
import { MANUAL_FINE_COPY } from "../../../../constants/actionItems";

const ErrorWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    padding-top: 60%;
`;

const IconWrapper = styled.div`
    padding-bottom: ${({ theme }) => theme.spacing.small}px;
`;

export default function ManualFineError({ handleNavigation }) {
    const { colors } = useTheme();

    return (
        <OverlayContentWrapper>
            <ErrorWrapper>
                <IconWrapper>
                    <Warning />
                </IconWrapper>
                <Text size="large" type="header" color={colors.DEEP_BLUE_SEA}>
                    {MANUAL_FINE_COPY.ERROR}
                </Text>
                <StyledInstructionText>
                    <SmallLinkText
                        onClick={handleNavigation}
                        textCopy={MANUAL_FINE_COPY.REFRESH}
                    />
                    {MANUAL_FINE_COPY.TRY_AGAIN}
                </StyledInstructionText>
            </ErrorWrapper>
        </OverlayContentWrapper>
    );
}
