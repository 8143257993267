//style
import styled, { useTheme } from "../../styling/styled-components";

//components
import Text from "../common/Text";
import { ButtonDiv } from "./common/ButtonDiv";
import { NextButton } from "./common/NextButton";
import { toastSuccess, toastError } from "../../utils/toasts";
import { StyledLineDivider } from "./common/StyledLineDivider";

const ParentLocationDiv = styled.div`
    display: flex;
    padding-bottom: ${({ theme }) => theme.spacing.medium}px;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.small}px;
    align-self: stretch;
`;

const TextDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: ${({ theme }) => theme.spacing.xxlarge}px;
`;

const ParentDetails = styled(Text)`
    padding-left: ${({ theme }) => theme.spacing.tiny}px;
`;

export default function AddLocationStepThree({
    onClick,
    addressDetails,
    scanningLocations,
}) {
    const { colors, spacing } = useTheme();

    const saveLocation = () => {
        onClick();
        toastSuccess("The location was successfully added");
        setTimeout(() => {
            window.location.reload();
        }, 500);
    };

    const returnFormattedAddress = () => {
        if (addressDetails) {
            const address = [
                addressDetails.streetAddress,
                addressDetails.streetAddress2,
                addressDetails.state,
                addressDetails.postalCode,
            ].filter(Boolean);
            const formattedAddress = address.join(", ");
            if (!formattedAddress) {
                return "No address added";
            } else {
                return formattedAddress;
            }
        }
    };

    return (
        <>
            <div
                style={{
                    position: "relative",
                    height: "60%",
                    overflowY: "scroll",
                }}
            >
                <StyledLineDivider />
                <ParentLocationDiv>
                    <TextDiv>
                        <ParentDetails
                            type="body"
                            size="medium"
                            color={colors.EXHAUST}
                        >
                            {"Parent Location Details"}
                        </ParentDetails>
                        <Text
                            type="label"
                            size="medium"
                            color={colors.DEEP_BLUE_SEA}
                            style={{ paddingLeft: spacing.tiny }}
                        >
                            {addressDetails.parentName}
                        </Text>
                        <Text
                            type="label"
                            size="medium"
                            color={colors.DEEP_BLUE_SEA}
                            style={{ paddingLeft: spacing.tiny }}
                        >
                            {addressDetails.streetAddress
                                ? returnFormattedAddress()
                                : ""}
                        </Text>
                    </TextDiv>
                </ParentLocationDiv>
                <StyledLineDivider />
                <ParentLocationDiv>
                    <TextDiv>
                        <ParentDetails
                            type="body"
                            size="medium"
                            color={colors.EXHAUST}
                        >
                            {"Scanning Location Details"}
                        </ParentDetails>
                        <Text
                            type="label"
                            size="medium"
                            color={colors.DEEP_BLUE_SEA}
                            style={{ paddingLeft: spacing.tiny }}
                        >
                            {`${scanningLocations.length} scanning locations`}
                        </Text>
                    </TextDiv>
                </ParentLocationDiv>
                <StyledLineDivider />
            </div>
            <ButtonDiv height={"true"}>
                <NextButton
                    type="primary"
                    onClick={saveLocation}
                    label={
                        <Text
                            type="label"
                            size="large"
                            color={colors.BLANK_SLATE}
                        >
                            {"Save location"}
                        </Text>
                    }
                ></NextButton>
            </ButtonDiv>
        </>
    );
}
