//components
import Text from "../../../common/Text";
import Displayable from "../../../common/Displayable";
import Button from "../../../common/Button";
import { ClipLoader } from "react-spinners";

//style
import styled, { css, useTheme } from "../../../../styling/styled-components";

//constants
import { VIEW_REPORTS_COPY } from "../../../../constants/actionItems";

//images
import { ReactComponent as Download } from "../../../../assets/vectors/Download.svg";
import { ReactComponent as DisableDownload } from "../../../../assets/vectors/DisableDownload.svg";

//utils
import isBefore from "date-fns/isBefore";
import parseISO from "date-fns/parseISO";

//react
import { useState } from "react";

const DownloadWrapper = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
`;

const DownloadText = styled(Text)`
    padding-left: ${({ theme }) => theme.spacing.tiny}px;
    &:hover {
        color: ${({ theme }) => theme.colors.PLASTIC_BOTTLE};
        text-decoration-color: ${({ theme }) => theme.colors.PLASTIC_BOTTLE};
    }
`;

const LoadingWrapper = styled.div`
    padding-top: ${({ theme }) => theme.spacing.tiny}px;
`;

const DownloadButton = styled(Button)`
    ${({ theme }) =>
        css`
            background-color: ${theme.colors.BLANK_SLATE};
            border: 1px solid ${theme.colors.BLANK_SLATE};
            &:hover {
                ${(props) =>
                    props.status == "disabled"
                        ? `background-color: ${theme.colors.BLANK_SLATE};`
                        : `background-color: ${theme.colors.SEAGLASS}`};
            }
        `};
    box-shadow: 0px 0px;
    min-width: 0;
    display: flex;
    padding: ${({ theme }) => theme.spacing.xsmall}px
        ${({ theme }) => theme.spacing.small}px;
    justify-content: flex-end;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.tiny}px;
`;

type Props = {
    expirationDate: string;
    status: string;
    downloadUrl: string;
};

export default function DownloadOption({
    expirationDate,
    status,
    downloadUrl,
}: Props) {
    const { colors } = useTheme();
    const [downloading, setDownloading] = useState(false);

    //To protect PII, disable the download functionality for all reports 90 days after they are created.
    const todayDate = new Date();
    const parsedExpiredDate = parseISO(expirationDate);
    const downloadExpired = isBefore(parsedExpiredDate, todayDate);

    const disableDownload = status == "PENDING" || downloadExpired;
    const downloadColor = disableDownload
        ? colors.MARINE_LAYER
        : colors.WAVE_STORM;

    const downloadCSV = () => {
        setDownloading(true);
        setTimeout(() => {
            setDownloading(false);
            window.open(downloadUrl, "_blank");
        }, 500);
    };

    const DownloadingSpinner = () => {
        return (
            <>
                <ClipLoader size={20} color={colors.WAVE_STORM} />
                <LoadingWrapper>
                    <Text
                        size="small"
                        type="label"
                        color={colors.DEEP_BLUE_SEA}
                    >
                        {VIEW_REPORTS_COPY.LOADING}
                    </Text>
                </LoadingWrapper>
            </>
        );
    };

    return (
        <DownloadWrapper>
            <Displayable
                displayPrimaryComponent={!downloading}
                SecondaryComponent={DownloadingSpinner}
            >
                <DownloadButton
                    status={disableDownload ? "disabled" : "default"}
                    onClick={downloadCSV}
                >
                    <Displayable
                        displayPrimaryComponent={!disableDownload}
                        SecondaryComponent={DisableDownload}
                    >
                        <Download />
                    </Displayable>
                    <DownloadText
                        size="small"
                        type="label"
                        color={downloadColor}
                    >
                        {VIEW_REPORTS_COPY.DOWNLOAD_REPORT}
                    </DownloadText>
                </DownloadButton>
            </Displayable>
        </DownloadWrapper>
    );
}
